import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceGenerationStrategy,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query
 * to get the list strategies for publish.
 */
export const useListStrategiesForPublish = () => {
  const params = useParams()
  const { organisationId } = params || {}

  const {
    error: queryError,
    isError,
    ...restQuery
  } = useQuery({
    enabled: !!organisationId,
    queryKey: [QueryKeysEnum.AI_BLUEPRINTS, organisationId],
    queryFn: async () => {
      invariant(organisationId, ERROR_MESSAGES.organisationId)

      const url = GenerationStrategyAPI.ListGenerationStrategiesForPublish
      return serviceGenerationStrategy.get(url, {
        params: {
          organisationId,
        },
      })
    },
    select: (response) => response.data,
  })

  const error = getQueryMutationError(isError, queryError as ErrorResponseType)

  return { ...restQuery, error, isError }
}
