import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { queryDevelopment } from '~/services/Development'
import type { DomainByPlatformId } from '~/services/Development.types'
import { STATEMENT_PARSING_INFO_TYPE } from '~/services/Process.types'
import {
  commandsByAggregate,
  queriesByAggregate,
  reactionsByAggregate,
} from '~/utils/transform'

type UseMarkedAsFeature = {
  /**
   * The aggregate data:
   * used to check if the current statement
   * is part of an aggregate, to check if it is
   * marked as feature.
   * This prop is necessary, in case the "marked as feature"
   * must be updated on the fly.
   */
  aggregateData?: DomainByPlatformId
  /**
   * Flag that indicates if a statement was marked as feature,
   * this alone doesn't indicates the statement is a feature,
   * more checks are necessary against aggregate data.
   */
  isFeature?: boolean
  /** The platform ID. */
  platformId: GUID
  /**
   * The ID of the statement to be checked
   * if it was marked as feature.
   */
  statementId?: GUID
  /**
   * The type of the statement:
   * command, reaction or query.
   */
  statementType?: string
}

/**
 * This hook contains utilities that will
 * check if a statement is marked as feature.
 */
export const useMarkedAsFeature = (props: UseMarkedAsFeature) => {
  const { aggregateData, isFeature, platformId, statementId, statementType } =
    props

  // React Query.
  const { refetch } = useQuery({
    ...queryDevelopment(platformId),
    enabled: false,
  })

  const { selectedCommand, selectedQuery, selectedReaction } = useMemo(
    () =>
      getSelectedCommandReactionQuery(
        statementId,
        statementType,
        aggregateData,
      ),
    [statementId, statementType, aggregateData],
  )

  const markedAsFeature = useMemo(() => {
    return (
      isFeature && (!!selectedCommand || !!selectedReaction || !!selectedQuery)
    )
  }, [isFeature, selectedCommand, selectedQuery, selectedReaction])

  /**
   * Function that will check if a statement is marked as feature,
   * it can be called when necessary.
   */
  const checkMarkedAsFeature = async (
    params: Omit<UseMarkedAsFeature, 'aggregateData' | 'platformId'>,
  ) => {
    const {
      isFeature: isFeatureParam,
      statementId: statementIdParam,
      statementType: statementTypeParam,
    } = params

    const result = await refetch()
    const {
      selectedCommand: selectedCommandParam,
      selectedReaction: selectedReactionParam,
      selectedQuery: selectedQueryParam,
    } = getSelectedCommandReactionQuery(
      statementIdParam,
      statementTypeParam,
      result.data?.data,
    )

    return (
      isFeatureParam &&
      (!!selectedCommandParam ||
        !!selectedReactionParam ||
        !!selectedQueryParam)
    )
  }

  return {
    checkMarkedAsFeature,
    markedAsFeature,
    selectedCommand,
    selectedQuery,
    selectedReaction,
  }
}

/**
 * Provides the selected command, reaction or query:
 * Check if the provided statement is part of an
 * aggregate.
 */
const getSelectedCommandReactionQuery = (
  id?: GUID,
  type?: string,
  aggregateData?: DomainByPlatformId,
) => {
  const commands = aggregateData
    ? commandsByAggregate(aggregateData)
    : undefined

  const reactions = aggregateData
    ? reactionsByAggregate(aggregateData)
    : undefined

  const queries = aggregateData ? queriesByAggregate(aggregateData) : undefined

  const selectedCommand =
    commands && type === STATEMENT_PARSING_INFO_TYPE.Command
      ? commands?.find((command) => command.identity === id)
      : undefined

  const selectedReaction =
    reactions && type === STATEMENT_PARSING_INFO_TYPE.Reaction
      ? reactions?.find((reaction) => reaction.identity === id)
      : undefined

  const selectedQuery =
    queries && type === STATEMENT_PARSING_INFO_TYPE.Query
      ? queries?.find((query) => query.identity === id)
      : undefined

  return { selectedCommand, selectedReaction, selectedQuery }
}
