import { Box, debounce, styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  Background,
  BackgroundVariant,
  Controls,
  ReactFlow as XyReactFlow,
  useReactFlow,
  type Node,
  type Viewport,
} from '@xyflow/react'
import type { AxiosResponse } from 'axios'
import dagre from 'dagre'
import { useEffect, useRef, type MouseEvent as ReactMouseEvent } from 'react'
import {
  generatePath,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { IS_DEV, TIMER } from '~/config/constants'
import { useReactFlowStatesContext } from '~/hooks/contexts/useReactFlowStatesContext'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import GroupNode from '~/pages/business/components/ProcessInfo/StatementsDiagram/CustomNodes/GroupNode'
import type { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import type { DomainByPlatformId } from '~/services/Development.types'
import { CustomEdge } from './CustomEdge'
import { CustomSubscribeEdgePointer } from './CustomSubscribeEdgePointer'
import { EventNode } from './EventNode'
import { ServiceNode } from './ServiceNode'
import {
  parseDomainToGetServicesAndEvents,
  useGetLayoutedElements,
} from './config'

const FIT_VIEW_SETTINGS = { padding: 0.5, duration: 500 }

const dagreGraph = new dagre.graphlib.Graph({ compound: true })
dagreGraph.setDefaultEdgeLabel(() => ({}))

const NODE_TYPES = {
  eventNode: EventNode,
  groupNode: GroupNode,
  serviceNode: ServiceNode,
  typeNode: ServiceNode,
}

const EDGE_TYPES = {
  customEdge: CustomEdge,
}

export const ReactFlow = styled(XyReactFlow)({
  // Force a new stacking context.
  transform: 'translate3d(0, 0, 0)',
  transformStyle: 'preserve-3d',
  backfaceVisibility: 'hidden',
})

export function ServiceMap() {
  // Ref.
  // react flow needs a parent with a fixed height value, so we're setting the to that parent the whole available page's space by getting a div's height that fills the whole page
  const flowParentContainer = useRef<HTMLDivElement>(null)

  // React Router Dom.
  const params = useParams<DeveloperChildrenParams>()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  const initialData = useLoaderData() as {
    data: Awaited<AxiosResponse<DomainByPlatformId | null, unknown>>
  }

  const navigate = useNavigate()

  // React Query.
  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(platformId),
    initialData: initialData.data,
    refetchInterval: IS_DEV ? false : TIMER.REFRESH_DATA_FOR_SERVICE_MAP,
    refetchOnWindowFocus: true,
    select: (response) => response.data,
  })

  const parsedData = parseDomainToGetServicesAndEvents(
    dataDevelopment,
    boundedContext,
  )

  // Hooks.
  const { serviceMapViewport, setServiceMapViewport } =
    useReactFlowStatesContext()

  const { nodes, edges } = useGetLayoutedElements({
    dagreGraph,
    data: parsedData,
  })

  const { fitView } = useReactFlow()

  // Lifecycle
  useEffect(() => {
    // Use setTimeout to ensure the nodes changed before fitting the view.
    setTimeout(() => {
      fitView(FIT_VIEW_SETTINGS)
    }, 1)
  }, [boundedContext, fitView])

  // Methods.
  // Handler for viewport move.
  const handleMove = (_: MouseEvent | TouchEvent | null, data: Viewport) => {
    setServiceMapViewport?.(data)
  }
  // Debounce due to multiple calls when moving de viewport.
  const debouncedMove = debounce(handleMove, 300)

  const handleNodeClick = (
    e: ReactMouseEvent<Element, MouseEvent>,
    node: Node,
  ) => {
    e.stopPropagation()

    const { id, type } = node

    if (type === 'serviceNode' && !!id)
      return navigate(
        `/${organisationId}/${platformId}/developer/${boundedContext}?aggregate=${id}`,
      )

    if (type === 'typeNode' && !!id)
      return navigate(
        `${generatePath(DeveloperRoutesEnum.DEVELOPER_BOUNDED_CONTEXT, {
          boundedContext,
          organisationId,
          platformId,
        })}?type=${id}`,
      )
  }

  // Avoid node right mouse button click.
  const handleNodeContextMenu = (event: ReactMouseEvent) => {
    event.preventDefault()
    return false
  }

  return (
    <Box className="relative" ref={flowParentContainer} height="100%">
      <ReactFlow
        defaultViewport={serviceMapViewport}
        edges={edges}
        edgeTypes={EDGE_TYPES}
        elementsSelectable={false}
        fitView={!serviceMapViewport}
        nodes={nodes}
        nodesDraggable={false}
        nodesFocusable={false}
        nodeTypes={NODE_TYPES}
        onNodeDoubleClick={(e) => e.stopPropagation()}
        onMove={debouncedMove}
        onNodeClick={handleNodeClick}
        onNodeContextMenu={handleNodeContextMenu}
        panOnDrag={[1, 2]}
      >
        <Background color="#9FA8DA" variant={BackgroundVariant.Dots} />
        <Controls />
      </ReactFlow>

      <CustomSubscribeEdgePointer />
    </Box>
  )
}
