import { BusinessDeveloperRoutesEnum } from './BusinessDeveloperRoutesEnum'
import { MainSubRoutesEnum } from './MainRoutesEnum'

/**
 * Routes related to `business`:
 * these are the sub routes that
 * together will compose the full path.
 */
export enum BusinessSubRoutesEnum {
  /** Base business route. */
  BUSINESS = ':platformId/business',
  /** Business with process ID route. */
  BUSINESS_PROCESS = ':platformId/business/:processId',
}

/**
 * Routes related to `business`.
 * Full route path.
 */
export enum BusinessRoutesEnum {
  /** Base business route. */
  BUSINESS = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS}`,
  /** Business with process ID route. */
  BUSINESS_PROCESS = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}`,

  /** Edit command route. */
  EDIT_COMMAND = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_COMMAND}`,
  /** Commits history route from edit command. */
  EDIT_COMMAND_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_COMMAND}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Edit reaction route. */
  EDIT_REACTION = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_REACTION}`,
  /** Commits history route from edit reaction. */
  EDIT_REACTION_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_REACTION}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Read model reaction route. */
  EDIT_READ_MODEL = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_READ_MODEL}`,
  /** Commits history route from edit read model. */
  EDIT_READ_MODEL_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_READ_MODEL}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Type route. */
  EDIT_TYPE = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_TYPE}`,
  /** Commits history route from edit type. */
  EDIT_TYPE_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_TYPE}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,

  /** Query route. */
  EDIT_QUERY = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_QUERY}`,
  /** Commits history route from edit query. */
  EDIT_QUERY_COMMITS_HISTORY = `/${MainSubRoutesEnum.ORGANISATION}/${BusinessSubRoutesEnum.BUSINESS_PROCESS}/${BusinessDeveloperRoutesEnum.EDIT_QUERY}/${BusinessDeveloperRoutesEnum.COMMITS_HISTORY}`,
}
