import { CodeSimple } from '@phosphor-icons/react'
import CodePreview from '~/components/CodePreview/CodePreview'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { ButtonPreview } from './components/ButtonPreview/ButtonPreview'
import { SelectAiBlueprint } from './components/SelectAiBlueprint/SelectAiBlueprint'
import { SelectArcMap } from './components/SelectArcMap/SelectArcMap'
import { useAiBlueprintPreview } from './useAiBlueprintPreview'

/**
 * AI Blueprint Preview page.
 */
export const AiBlueprintPreview = () => {
  const {
    control,
    disabledPreviewBtn,
    handleSubmit,
    isDirty,
    onSubmitPreviewCode,
    placeholderMsg,
    previewCodeIsPending,
    previewData,
    wasEverSubmitted,
    watch,
  } = useAiBlueprintPreview()

  return (
    <div className="grid h-[calc(100vh-65px)] grid-rows-[auto_1fr] bg-white p-4">
      <header className="mb-4 flex items-center gap-2">
        <CodeSimple size={24} />

        <h1 className="m-0 text-2xl font-bold text-highlight-200">
          CODE PREVIEW
        </h1>

        <form
          className="flex items-center gap-2"
          onSubmit={handleSubmit(onSubmitPreviewCode)}
        >
          <SelectAiBlueprint control={control} watch={watch} />

          <SelectArcMap control={control} watch={watch} />

          <ButtonPreview
            disabled={disabledPreviewBtn}
            isDirty={isDirty}
            loading={previewCodeIsPending}
            wasEverSubmitted={wasEverSubmitted}
            watch={watch}
          />
        </form>
      </header>

      <CodePreview
        filesTreeData={previewData as unknown as FolderOrFileStructure[]}
        placeholderMsg={placeholderMsg as string}
      />
    </div>
  )
}
