import SaveIcon from '@mui/icons-material/Save'
import { Alert, Button, Stack, TextField, Typography } from '@mui/material'
import { ArrowCounterClockwise } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import {
  Form,
  useLoaderData,
  useLocation,
  useNavigation,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ExternalLink } from '~/components/ExternalLink'
import { FormFooterStyled } from '~/pages/developer/components/styled/FormFooter'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'

export function DeveloperGitCredentials() {
  // const errors = useActionData() as GenericObj<string> | undefined
  const [searchParams] = useSearchParams()
  const isInitialise = searchParams.toString().includes('initialise')
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'
  const location = useLocation()
  const isBusiness = location.pathname.includes('business')
  const params = useParams()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const {
    data,
    // isInitialLoading, // etc
  } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData,
    select: (data) => {
      if (data.data && params.aggregateId) {
        const boundedContext = data.data.boundedContexts.find((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === params.aggregateId,
          ),
        )

        if (boundedContext?.aggregates && boundedContext.aggregates[0]) {
          return boundedContext?.aggregates[0].gitRepositoryUrl
        }
      }

      return
    },
  })

  return (
    <>
      <Stack gap={2}>
        {isInitialise && !isBusiness ? (
          <Alert severity="info">
            Before initialising, you need to setup your git credentials.
          </Alert>
        ) : null}

        <Typography>
          Dot-star will generate infrastructure code using{' '}
          <ExternalLink href="https://www.terraform.io/">
            Terraform
          </ExternalLink>
          , as well as pipeline code for{' '}
          <ExternalLink href="https://azure.microsoft.com/en-us/products/devops">
            Azure DevOps
          </ExternalLink>
        </Typography>

        <Form method="post" id="form-aggregate-git-credentials">
          <Stack gap={2}>
            <TextField
              label="Repository url"
              variant="outlined"
              name="repositoryUrl"
              defaultValue={data}
              fullWidth
            />

            <TextField
              label="Authentication token"
              type="password"
              variant="outlined"
              name="token"
              fullWidth
            />

            <FormFooterStyled>
              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={isButtonDisabled ? null : <ArrowCounterClockwise />}
                type="reset"
                variant="outlined"
              >
                Reset
              </Button>

              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={isButtonDisabled ? null : <SaveIcon />}
                sx={{ width: 'fit-content' }}
                type="submit"
                variant="contained"
              >
                {isButtonDisabled
                  ? 'Saving...'
                  : isInitialise
                    ? 'Save'
                    : 'Confirm'}
              </Button>
            </FormFooterStyled>
          </Stack>
        </Form>
      </Stack>
    </>
  )
}
