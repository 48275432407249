import { Button } from '@mui/material'
import { FileMagnifyingGlass } from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'
import { generatePath, useParams } from 'react-router-dom'
import { ActionButtonClasses } from '~/components/buttons/ActionIconButton/ActionIconButton'
import { ConfigurationRoutesEnum } from '~/models/enums/routes/ConfigurationRoutesEnum'

/**
 * Preview button component.
 */
export const PreviewButton = () => {
  // React Router Dom.
  const params = useParams()

  const { organisationId = '', platformId = '' } = params

  const handlePreviewClick = () => {
    // Get the base URL
    const baseUrl = window.location.origin

    const path = generatePath(ConfigurationRoutesEnum.AI_BLUEPRINT_PREVIEW, {
      organisationId,
      platformId,
    })

    // Open in new tab
    window.open(`${baseUrl}${path}`, '_blank')
  }

  return (
    <Button
      className={twMerge(ActionButtonClasses, 'w-auto p-3')}
      color="secondary"
      onClick={handlePreviewClick}
      startIcon={<FileMagnifyingGlass />}
      variant="outlined"
    >
      Preview
    </Button>
  )
}
