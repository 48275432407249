import { DotsSixVertical } from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const ItemTypes = {
  ATTRIBUTE: 'attribute',
}

type DraggableAttributeProps = {
  /** The children content. */
  children: React.ReactNode
  /** The ID of the attribute. */
  id: string
  /** the index of the attribute in the list of attributes. */
  index: number
  /** Function to reorder attributes. */
  moveAttribute: (dragIndex: number, hoverIndex: number) => void
}

// The structure of the item being dragged.
type DragItem = {
  /** The current index of the dragged item. */
  currentIndex: number
  /** The ID of the dragged item. */
  id: string
  /** The original index of the dragged item. */
  originalIndex: number
  /** The type of the dragged item. */
  type: string
}

/**
 * The draggable wrapper for the `Attribute` component.
 */
export const DraggableAttribute = (props: DraggableAttributeProps) => {
  const { children, id, index, moveAttribute } = props

  // Refs.
  const ref = useRef<HTMLDivElement>(null)

  // React DnD.
  const [{ handlerId, isOver }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: any | null; isOver: boolean }
  >({
    accept: ItemTypes.ATTRIBUTE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      }
    },
    hover(item: DragItem) {
      if (!ref.current) return

      const dragIndex = item.currentIndex
      const hoverIndex = index

      // Don't replace items with themselves.
      if (dragIndex === hoverIndex) return

      // Update the current index of the dragged item.
      item.currentIndex = hoverIndex
    },
    drop(item: DragItem) {
      if (item.originalIndex !== index) moveAttribute(item.originalIndex, index)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ATTRIBUTE,
    item: () => ({ id, originalIndex: index, currentIndex: index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        // If the item was not dropped, we reset its position.
        moveAttribute(item.currentIndex, item.originalIndex)
      }
    },
  })

  // Combine drag and drop refs.
  drag(drop(ref))

  const containerClasses = twMerge(
    'relative px-0 py-1 rounded-md transition-all duration-200 cursor-move border-2',
    isDragging ? 'opacity-50 bg-gray-100 shadow-md' : 'border-transparent',
    isOver ? 'bg-blue-50 border-solid border-primary' : '',
  )

  return (
    <div className={containerClasses} data-handler-id={handlerId} ref={ref}>
      <div className="flex flex-grow items-center">
        <DotsSixVertical className="mr-2 cursor-move" size={22} weight="bold" />

        <div className="flex-1">{children}</div>
      </div>
    </div>
  )
}
