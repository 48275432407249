import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'

/**
 * Filters a tree of nodes based on a label search term:
 * - Returns the original tree if no search term is provided
 * - Preserves the structure of matching nodes and their ancestors
 * - Removes branches that don't contain any matches
 * @param dataTree The data tree.
 * @param searchTerm The search term to filter against.
 *
 * @example
 * const tree = [
 *   { id: "1", label: "Parent", children: [
 *     { id: "2", label: "Child", children: [] }
 *   ]}
 * ];
 * const filtered = filterTreeByLabel(tree, "Child");
 * // Returns the parent node with only the matching child.
 */
export const filterTreeByLabel = (
  dataTree: ArcMapTreeViewData[],
  searchTerm: string,
): ArcMapTreeViewData[] => {
  if (!searchTerm) return dataTree

  const searchLower = searchTerm.toLowerCase()

  /**
   * Filter rules:
   * 1. If a node's label matches the search, return the entire node unchanged
   * 2. If a node has matching children, return the node with only matching children
   * 3. If neither applies, return null to filter out the node
   */
  const filterNode = (node: ArcMapTreeViewData): ArcMapTreeViewData | null => {
    // If current node matches the filter, return it with all its children.
    if (node.label.toLowerCase().includes(searchLower)) {
      return { ...node }
    }

    // If node has children, recursively filter them.
    if (node.children && node.children.length > 0) {
      const filteredChildren = node.children
        .map((child) => filterNode(child))
        .filter((child): child is ArcMapTreeViewData => child !== null)

      // If any children match, return node with only matching children.
      if (filteredChildren.length > 0) {
        return {
          ...node,
          children: filteredChildren,
        }
      }
    }

    // No match found in this branch.
    return null
  }

  return dataTree
    .map((node) => filterNode(node))
    .filter((node): node is ArcMapTreeViewData => node !== null)
}
