import { Button, LinearProgress, Tooltip } from '@mui/material'
import { TreeView, type TreeViewProps } from '@mui/x-tree-view/TreeView'
import {
  ArrowsInLineVertical,
  CaretDown,
  CaretRight,
  ListChecks,
  MagicWand,
} from '@phosphor-icons/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import type { AxiosResponse } from 'axios'
import {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
} from 'react'
import { Panel } from 'react-resizable-panels'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import ActionIconButton, {
  ActionButtonClasses,
} from '~/components/buttons/ActionIconButton/ActionIconButton'
import DialogConfirm, {
  type DialogConfirmHandle,
} from '~/components/dialogs/DialogConfirm/DialogConfirm'
import { DialogMappingCondition } from '~/components/dialogs/DialogMappingCondition'
import { useGetGenerationStrategyItemForTreeView } from '~/hooks/api/codeGenerationStrategy/useGetGenerationStrategyItemForTreeView'
import { QueryKeysEnum } from '~/models/enums/api/QueryKeysEnum'
import type { CodeGenerationStrategyBaseProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyBaseProps'
import type { RenderTextSectionNodeProps } from '~/models/types/components/codeGenerationStrategy/RenderTextSectionNodeProps'
import { DialogDeleteProperty } from '~/routes/configuration/code-generation-strategies/edit/components/DialogDeleteProperty/DialogDeleteProperty'
import {
  mutationGenerateBoilerplate,
  mutationReduceFileContent,
} from '~/services/GenerationStrategy'
import type {
  FolderOrFileStructure,
  PropertyBinding,
  TextSection,
} from '~/services/GenerationStrategy.types'
import { useCodeGenerationStore } from '~/store'
import { RenderTreeItem } from '../RenderTreeItem/RenderTreeItem'
import { ValueBindingTreeItem } from '../ValueBindingTreeItem/ValueBindingTreeItem'
import { PreviewButton } from './PreviewButton/PreviewButton'

type FileDetailsProps = Pick<
  CodeGenerationStrategyBaseProps,
  'dataDomainDictionary' | 'selectedDomainDictionaryItem'
> &
  Pick<RenderTextSectionNodeProps, 'onDeletePropertyBindingClick'> & {
    /** The current opened file ID. */
    fileId?: GUID
    /** Delete dialog property `open` state. */
    isDeleteDialogPropertyOpen: boolean
    /** Callback handler for close delete dialog. */
    onCloseDeleteDialog: () => void
    /** Callback handler for property biding node select. */
    onPropertyBindingNodeSelect: NonNullable<TreeViewProps<any>['onNodeSelect']>
    /** Callback handler for property biding node toggle. */
    onPropertyBindingNodeToggle: NonNullable<TreeViewProps<any>['onNodeToggle']>
    /** The current selected property binding. */
    selectedPropertyBinding: FolderOrFileStructure | PropertyBinding
    /** The current selected property binding ID. */
    selectedPropertyBindingId: GUID
    /** Set selected property biding state. */
    setSelectedPropertyBinding: Dispatch<
      SetStateAction<FolderOrFileStructure | PropertyBinding>
    >
  }

/**
 * File details panel:
 * Renders a file content.
 */
export const FileDetailsPanel = memo((props: FileDetailsProps) => {
  const {
    dataDomainDictionary,
    fileId,
    isDeleteDialogPropertyOpen,
    onCloseDeleteDialog,
    onDeletePropertyBindingClick,
    onPropertyBindingNodeSelect,
    onPropertyBindingNodeToggle,
    selectedDomainDictionaryItem,
    selectedPropertyBinding,
    selectedPropertyBindingId,
    setSelectedPropertyBinding,
  } = props

  // Refs.
  const fileContentRef = useRef<HTMLDivElement>(null)
  const dialogConfirmReduceFileContentRef = useRef<DialogConfirmHandle>(null)
  const dialogConfirmGenerateBoilerplateRef = useRef<DialogConfirmHandle>(null)

  // States.
  const [isMappingConditionDialogOpen, setIsMappingConditionDialogOpen] =
    useState<boolean>(false)

  // Global states.
  const expandedProperties = useCodeGenerationStore(
    (state) => state.expandedProperties,
  )

  // React Query.
  const queryClient = useQueryClient()

  const { data: file, isLoading } = useGetGenerationStrategyItemForTreeView({
    fileId,
  })
  const { fileBinding, pathBinding, propertyBindings } = file || {}

  const filePathData = useMemo(
    () =>
      propertyBindings?.find(
        (item: PropertyBinding) => item.propertyName === 'FilePath',
      ) || pathBinding,
    [file],
  )

  const {
    isPending: isPendingReduceFileContent,
    mutateAsync: mutateReduceFileContent,
  } = useMutation<AxiosResponse<any, any>, Error, string>({
    mutationFn: (fileId: string) => mutationReduceFileContent(fileId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKeysEnum.GENERATION_STRATEGY_ITEM, fileId],
      }),
  })

  const {
    isPending: isPendingGenerateBoilerplate,
    mutateAsync: mutateGenerateBoilerplate,
  } = useMutation<AxiosResponse<any, any>, Error, string>({
    mutationFn: (fileId: string) => mutationGenerateBoilerplate(fileId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [QueryKeysEnum.GENERATION_STRATEGY_ITEM, fileId],
      }),
  })

  // Methods.
  const handleConfirmReduceFileContentClick = (
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation()
    dialogConfirmReduceFileContentRef.current?.handleOpenDialog()
  }

  const handleReduceFileContentClick = () => {
    if (fileId)
      mutateReduceFileContent(fileId).then(() =>
        dialogConfirmReduceFileContentRef.current?.handleCloseDialog(),
      )
  }

  const handleConfirmGenerateBoilerplateClick = (
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation()
    dialogConfirmGenerateBoilerplateRef.current?.handleOpenDialog()
  }

  const handleGenerateBoilerplateClick = () => {
    if (fileId)
      mutateGenerateBoilerplate(fileId).then(() =>
        dialogConfirmGenerateBoilerplateRef.current?.handleCloseDialog(),
      )
  }

  const handleOpenMappingConditionDialog = useCallback(
    (propertyBinding?: FolderOrFileStructure) => {
      if (propertyBinding) {
        setSelectedPropertyBinding(propertyBinding)
        setIsMappingConditionDialogOpen(true)
      }
    },
    [setSelectedPropertyBinding],
  )

  const handleCloseMappingConditionDialog = () => {
    setIsMappingConditionDialogOpen(false)
  }

  return (
    <Panel className="flex" id="2" order={2} collapsible={false}>
      <div className="grid flex-1 grid-rows-[auto_1fr] overflow-hidden bg-white px-4 shadow-md">
        {isLoading && <LinearProgress className="my-3" />}

        {!fileBinding && !isLoading && (
          <Text className="italic text-gray-500">
            Select a file to view details
          </Text>
        )}

        {!!fileBinding && (
          <>
            <div>
              <div className="mb-2 flex items-center gap-5 rounded-lg bg-slate-50 p-3">
                <div className="flex items-center gap-2">
                  <ActionIconButton
                    color="secondary"
                    Icon={ListChecks}
                    iconProps={{
                      size: 25,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleOpenMappingConditionDialog?.(file)
                    }}
                    tooltipPlacement="top"
                    tooltipTitle={`Mapping Condition: ${fileBinding.mappingCondition}`}
                  />

                  <span className="text-sm font-bold">
                    {fileBinding.mappingCondition}
                  </span>
                </div>

                <Tooltip
                  arrow
                  placement="top"
                  title="Reduce the content of the selected file"
                >
                  <Button
                    className={twMerge(ActionButtonClasses, 'w-auto p-3')}
                    color="secondary"
                    onClick={handleConfirmReduceFileContentClick}
                    startIcon={<ArrowsInLineVertical size={22} />}
                    variant="outlined"
                  >
                    <span className="truncate">Reduce File Content</span>
                  </Button>
                </Tooltip>

                <DialogConfirm
                  isLoading={isPendingReduceFileContent}
                  onConfirmClick={handleReduceFileContentClick}
                  ref={dialogConfirmReduceFileContentRef}
                />

                <Tooltip
                  arrow
                  placement="top"
                  title="Generate boilerplate for the selected file"
                >
                  <Button
                    className={twMerge(ActionButtonClasses, 'w-auto p-3')}
                    color="secondary"
                    onClick={handleConfirmGenerateBoilerplateClick}
                    startIcon={<MagicWand size={22} />}
                    variant="outlined"
                  >
                    <span className="truncate">Generate Boilerplate</span>
                  </Button>
                </Tooltip>

                <DialogConfirm
                  isLoading={isPendingGenerateBoilerplate}
                  onConfirmClick={handleGenerateBoilerplateClick}
                  ref={dialogConfirmGenerateBoilerplateRef}
                />

                <PreviewButton />
              </div>

              <Row className="mb-4 items-center justify-between bg-slate-50 p-3">
                <div className="flex-1">
                  <ValueBindingTreeItem
                    dataDomainDictionary={dataDomainDictionary}
                    fileId={fileId}
                    initialValue={filePathData?.value}
                    isContent
                    isValueAsText
                    node={filePathData as unknown as TextSection}
                  />
                </div>
              </Row>
            </div>

            <div className="overflow-auto" ref={fileContentRef}>
              <TreeView
                aria-label="file nodes tree view"
                className="w-full rounded border border-slate-200 px-2 py-3"
                defaultCollapseIcon={<CaretDown />}
                defaultExpandIcon={<CaretRight />}
                expanded={expandedProperties}
                onNodeSelect={onPropertyBindingNodeSelect}
                onNodeToggle={onPropertyBindingNodeToggle}
                selected={selectedPropertyBindingId}
                sx={{
                  '[data-node-property="Content"] > ul': {
                    borderLeft: 0,
                  },
                }}
              >
                <RenderTreeItem
                  dataDomainDictionary={dataDomainDictionary}
                  fileId={fileId}
                  node={file}
                  onClickOpenMappingConditionDialog={
                    handleOpenMappingConditionDialog
                  }
                  onDeletePropertyBindingClick={onDeletePropertyBindingClick}
                  selectedDomainDictionaryItem={selectedDomainDictionaryItem}
                />
              </TreeView>

              <DialogMappingCondition
                dataDomainDictionary={dataDomainDictionary}
                fileId={fileId}
                handleCloseDialog={handleCloseMappingConditionDialog}
                isDialogOpen={isMappingConditionDialogOpen}
                propertyBinding={fileBinding!}
                selectedDomainDictionaryItem={selectedDomainDictionaryItem}
              />

              <DialogDeleteProperty
                isDialogOpen={isDeleteDialogPropertyOpen}
                handleCloseDialog={onCloseDeleteDialog}
                propertyBinding={selectedPropertyBinding}
                fileId={fileId}
              />
            </div>
          </>
        )}
      </div>
    </Panel>
  )
})
