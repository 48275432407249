import { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

/**
 * Find a tree node within the "data" based on the
 * provided "target ID".
 * @param data The raw tree data.
 * @param targetId The target ID.
 */
export const findTreeNodeById = (
  data?: FolderOrFileStructure[],
  targetId?: GUID,
): FolderOrFileStructure | null => {
  if (!Array.isArray(data) || !targetId) return null

  return data.reduce(
    (found: FolderOrFileStructure | null, item: FolderOrFileStructure) => {
      // If we already found the item, return it.
      if (found) return found

      // If current item matches, return it.
      if (item.id === targetId) return item

      // If item has children, recursively search them.
      if (item.children) return findTreeNodeById(item.children, targetId)

      return null
    },
    null,
  )
}
