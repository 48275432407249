import { Chip, List, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Lightning, Play } from '@phosphor-icons/react'
import React from 'react'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { ListAttributes } from '~/pages/developer/components/ListAttributes'
import { MenuMore } from '~/pages/developer/components/MenuMore'
import {
  ListItemForDeveloper,
  ListItemHeader,
} from '~/pages/developer/components/styled/ListItem'
import { Aggregate } from '~/services/Development.types'

type ListReactionsProps = {
  aggregate: Aggregate
}
export function ListReactions({ aggregate }: ListReactionsProps) {
  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  return (
    <>
      {aggregate.reactions.length ? (
        <Stack mx={1}>
          <List>
            {aggregate.reactions.map((reaction, index) => (
              <ListItemForDeveloper key={index}>
                <Stack sx={{ flexGrow: 1 }} gap={1}>
                  <ListItemHeader>
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <Lightning size={20} />

                      <Stack>
                        <Typography
                          variant="caption"
                          component="p"
                          sx={{ mb: -0.5 }}
                        >
                          {reaction.subscription.from.replaceAll('.', ' > ')}
                        </Typography>

                        <Typography>
                          <strong>
                            {reaction.subscription.domainEventName}
                          </strong>
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack gap={1} direction="row" alignItems="center">
                      {isCodeGenerationAllowed && (
                        <Chip
                          label={reaction.state.name}
                          color={
                            reaction.state.name === 'Published'
                              ? 'success'
                              : 'warning'
                          }
                          size="small"
                          variant="outlined"
                        />
                      )}

                      <MenuMore
                        linkPublish={`generate-code/reaction/${reaction.identity}`}
                        linkEdit={`edit-reaction/${reaction.identity}`}
                        linkDelete={
                          reaction.state.name === 'Published'
                            ? undefined
                            : `delete-reaction/${reaction.identity}`
                        }
                        type="Reaction"
                        eventId={reaction.identity}
                      />
                    </Stack>
                  </ListItemHeader>

                  <Stack gap={1}>
                    <Stack
                      direction="row"
                      sx={{ alignItems: 'center' }}
                      gap={1}
                    >
                      <Play size={20} />

                      <Typography variant="body2">
                        {reaction.actionName}
                      </Typography>
                    </Stack>

                    <ListAttributes attributes={reaction.attributes} />
                  </Stack>

                  {reaction.domainEvents.map(
                    (domainEvent, domainEventIndex) => (
                      <React.Fragment
                        key={domainEvent.name + domainEventIndex.toString()}
                      >
                        <Stack
                          direction="row"
                          sx={{ alignItems: 'center', mt: 1 }}
                          gap={1}
                        >
                          <Lightning size={20} />

                          <Typography variant="body2">
                            {domainEvent.name}
                          </Typography>
                        </Stack>

                        <ListAttributes attributes={domainEvent.attributes} />
                      </React.Fragment>
                    ),
                  )}
                </Stack>
              </ListItemForDeveloper>
            ))}
          </List>
        </Stack>
      ) : null}
    </>
  )
}
