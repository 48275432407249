import {
  ArrowsClockwise,
  Lightning,
  MagnifyingGlass,
  PaperPlaneRight,
} from '@phosphor-icons/react'
import React from 'react'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'

type ServiceActionIconProps = {
  type: ServiceAndEventsActionTypeEnum
  color?: string
  width?: number | string
  height?: number | string
}

export const ServiceActionIcon: React.FC<ServiceActionIconProps> = ({
  type,
  color = 'inherit',
  width = 14,
  height = 14,
}) => {
  const iconStyle = {
    color,
    width,
    height,
  }

  switch (type) {
    case ServiceAndEventsActionTypeEnum.COMMAND:
      return <PaperPlaneRight style={iconStyle} />
    case ServiceAndEventsActionTypeEnum.REACTION:
      return <ArrowsClockwise />
    case ServiceAndEventsActionTypeEnum.QUERY:
      return <MagnifyingGlass />
    case ServiceAndEventsActionTypeEnum.EVENT:
      return <Lightning style={iconStyle} />
    default:
      return null
  }
}
