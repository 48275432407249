import * as monaco from 'monaco-editor'
import { useEffect, useRef } from 'react'
import { MONACO_EDITOR_CONSTRUCTION_OPTIONS } from './CodeEditor.constants'
import { normalizeLangForMonaco } from './CodeEditor.utils'

export type UseCodeEditorProps = {
  /** The file content. */
  content: string
  /** If true the code editor will be disabled. */
  disabled?: boolean
  /** The code language. */
  language?: string
  /** Callback handler for code change. */
  onChange?: (value: string) => void
}

/**
 * Holds "CodeEditor" lifecycle and utilities.
 */
export const useCodeEditor = (props: UseCodeEditorProps) => {
  const { content, disabled, language = 'javascript', onChange } = props

  // Refs.
  const editorRef = useRef<HTMLDivElement>(null)
  const editor = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const initializedRef = useRef(false)

  // Initialize editor on component mount.
  useEffect(() => {
    if (!editorRef.current || initializedRef.current) return

    // Delay editor creation to ensure DOM is ready.
    const timer = setTimeout(() => {
      initializedRef.current = true
      editor.current = monaco.editor.create(editorRef.current!, {
        ...MONACO_EDITOR_CONSTRUCTION_OPTIONS,
        value: content,
        language: normalizeLangForMonaco(language),
        readOnly: disabled, // Set initial read-only state
        domReadOnly: disabled, // Prevent DOM events when disabled
      })

      // Add change listener.
      const disposable = editor.current.onDidChangeModelContent(() => {
        if (onChange && editor.current) {
          const newValue = editor.current.getValue()
          onChange(newValue)
        }
      })

      // Force a layout update after initialization.
      setTimeout(() => {
        if (editor.current) {
          editor.current.layout()
        }
      }, 100)

      return () => {
        disposable.dispose()
        if (editor.current) {
          editor.current.dispose()
          editor.current = null
        }
        initializedRef.current = false
      }
    }, 0)

    return () => clearTimeout(timer)
  }, [])

  // Update content when prop changes.
  useEffect(() => {
    if (editor.current && editor.current.getValue() !== content) {
      const position = editor.current.getPosition()
      editor.current.setValue(content)
      if (position) {
        editor.current.setPosition(position)
      }
      // Force a layout update after content change.
      editor.current.layout()
    }
  }, [content])

  // Update language when prop changes.
  useEffect(() => {
    if (editor.current) {
      const model = editor.current.getModel()
      if (model) {
        monaco.editor.setModelLanguage(model, normalizeLangForMonaco(language))
      }
    }
  }, [language])

  // Update disabled state when prop changes.
  useEffect(() => {
    if (editor.current) {
      editor.current.updateOptions({
        readOnly: disabled,
        domReadOnly: disabled,
      })
    }
  }, [disabled])

  return {
    editorRef,
  }
}
