import { debounce } from '@mui/material'
import { useCallback, useMemo, useState, type ChangeEvent } from 'react'
import type { DomainByPlatformId } from '~/services/Development.types'
import { filterTreeByLabel } from './utils/filterTreeByLabel'
import { transformToTree } from './utils/transformToTree'

type UseFilterArcMapByNameProps = {
  /** The bounded context data. */
  boundedContextsData: DomainByPlatformId
}

/**
 * Provide utilities to filter ArcMap data
 * by their names.
 */
export const useFilterArcMapByName = (props: UseFilterArcMapByNameProps) => {
  const { boundedContextsData } = props
  const aggregateTree = transformToTree(boundedContextsData)

  // This state is to allow the controlled state for the field text field.
  const [filterInputValue, setFilterInputValue] = useState<string>('')

  // This is the debounced value, that will actually trigger the filtering.
  const [debouncedFilterInputValue, setDebouncedFilterInputValue] =
    useState<string>('')

  // Debounce for filter input.
  const debouncedSetFilterInputValue = useCallback(
    debounce((value) => {
      setDebouncedFilterInputValue(value)
    }, 300),
    [],
  )

  // Handle filter input change.
  const handleFilterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFilterInputValue(value)
    debouncedSetFilterInputValue(value)
  }

  // Clear field value.
  const handleClearFilterInputClick = () => {
    setFilterInputValue('')
    debouncedSetFilterInputValue('')
  }

  // The filtered data directory items.
  const filteredData = useMemo(
    () => filterTreeByLabel(aggregateTree, debouncedFilterInputValue),
    [aggregateTree, debouncedFilterInputValue],
  )

  return {
    filteredData,
    filterInputValue,
    handleClearFilterInputClick,
    handleFilterInputChange,
  }
}
