import { PreviewForOptions } from '~/models/enums/aiBlueprint/PreviewForOptions'
import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'

/**
 * Provides the proper value for "preview for" field
 * based on the type.
 * This field is necessary to specify which kind of entity
 * the wants to preview the file against.
 * @param type The type of the node.
 */
export const getPreviewFor = (type?: ArcMapTreeNodeType | null) => {
  switch (true) {
    case type === ArcMapTreeNodeType.AGGREGATE:
      return PreviewForOptions.AGGREGATE
    case type === ArcMapTreeNodeType.COMMAND:
      return PreviewForOptions.COMMAND
    case type === ArcMapTreeNodeType.REACTION:
      return PreviewForOptions.REACTION
    case type === ArcMapTreeNodeType.TYPE:
      return PreviewForOptions.TYPE
    case type === ArcMapTreeNodeType.QUERY:
      return PreviewForOptions.QUERY
    default:
      return null
  }
}
