import { Button, Stack } from '@mui/material'
import { Plus } from '@phosphor-icons/react'
import { Link, useParams } from 'react-router-dom'
import type { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { useDeveloperRouteParams } from '../../hooks/useDeveloperRouteParams'

/**
 * Button to add a new aggregate.
 */
export const ButtonAddAggregate = () => {
  const params = useParams<DeveloperChildrenParams>()
  const { aggregateId, boundedContext, organisationId, platformId } = params

  const { isValidAggregate, isValidBoundedContext } = useDeveloperRouteParams()

  let basePath = `/${organisationId}/${platformId}/developer`
  if (isValidBoundedContext) basePath = `${basePath}/${boundedContext}`
  if (isValidAggregate) basePath = `${basePath}/${aggregateId}`
  basePath = `${basePath}/details?isNew=true`

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        component={Link}
        color="secondary"
        fullWidth
        size="small"
        startIcon={<Plus size={15} />}
        to={basePath}
        variant="outlined"
      >
        Add
      </Button>
    </Stack>
  )
}
