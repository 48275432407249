import { Button, CircularProgress } from '@mui/material'
import { MagicWand } from '@phosphor-icons/react'
import { useNavigation } from 'react-router-dom'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { BadgeUsesLeft } from '../BadgeUsesLeft'

type ButtonAutoGenerateProps = {
  name: string
}

export function ButtonAutoGenerate({ name }: ButtonAutoGenerateProps) {
  const buttonName = `${name}AutoGenerateAttributes`

  const navigation = useNavigation()

  const isLoading = navigation.formData
    ? !!Object.fromEntries(navigation.formData)?.[buttonName]
    : false

  return (
    <Button
      color="secondary"
      disabled={isLoading || navigation.state !== 'idle'}
      endIcon={
        FEATURE_TOGGLE.UPGRADE_PLAN ? (
          <BadgeUsesLeft featureType="ATTRIBUTE_AUTO_GENERATION" />
        ) : null
      }
      name={buttonName}
      sx={{ width: 'fit-content' }}
      startIcon={isLoading ? <CircularProgress size="1rem" /> : <MagicWand />}
      type="submit"
      value="true"
      variant="outlined"
    >
      Auto-generate
    </Button>
  )
}
