import { Typography } from '@mui/material'
import React from 'react'
import { Row } from '~/components/Row'
import { Text } from '~/components/Text'
import { WizardBullet } from '~/styled/WizardBullet'

type WizardContentProps = {
  children: React.ReactNode
  intro?: React.ReactNode
  step?: string
  title?: React.ReactNode
}
export function WizardContent({
  children,
  intro = <>We need some basic information to start</>,
  step = '1',
  title = (
    <>
      Please enter your <strong>Organisation Name</strong>
    </>
  ),
}: WizardContentProps) {
  return (
    <div className="relative mb-14 mr-14 min-h-[380px] pl-10">
      <Row className="mb-4 mt-6 items-center gap-4">
        <WizardBullet step={step} height={38} />

        <Text className="text-xl">{title}</Text>
      </Row>

      <Typography variant="body1" color="text.secondary" className="p3 pb-5">
        {intro}
      </Typography>

      {children}
    </div>
  )
}
