import { Box, styled } from '@mui/material'

export const FormFooterStyled = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'sticky',
    bottom: 0,
    right: 0,
    p: 2,
    display: 'flex',
    gap: 2,
    alignItems: 'flex-end',
    justifyContent: 'right',
    bgcolor: 'background.panel',
    zIndex: 1,
    minWidth: '100%',
  }),
)
