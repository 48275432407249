import { IconButton, type IconButtonProps } from '@mui/material'
import { X } from '@phosphor-icons/react'

/**
 * Implements an `IconButton` component from MUI
 * with a `CloseIcon`.
 */
export const ButtonIconClose = (props: IconButtonProps) => (
  <IconButton {...props}>
    <X size={20} />
  </IconButton>
)
