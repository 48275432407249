import { initializeMonacoWorkers } from './CodeEditor.utils'
import { useCodeEditor, type UseCodeEditorProps } from './useCodeEditor'

initializeMonacoWorkers()

type CodeEditorProps = UseCodeEditorProps

/**
 * Code editor component:
 * based on the "monaco editor" lib.
 */
export const CodeEditor = (props: CodeEditorProps) => {
  // Hooks.
  const { editorRef } = useCodeEditor(props)

  return (
    <div className="h-full overflow-hidden shadow-sm">
      <div ref={editorRef} className="h-full min-h-[200px]" />
    </div>
  )
}
