import { styled } from '@mui/material'
import { twMerge } from '^/tailwind.config'
import { designSystemTokens } from '~/styles/designSystemTokens'

export const Form = styled('form')`
  &.is-hovered,
  &.is-hovered ~ *,
  &.is-hovered ~ pre * {
    color: ${designSystemTokens.colors.feedback.success.pure};
  }
`

/**
 * Computes the tailwind classes for the
 * `is content` span node.
 * @param isMergeMode Merge mode active.
 * @param isSelectable Node is selectable.
 * @param isSelected Node is selected.
 * @param isParentSelectable The parent (or ancestor) node is selectable.
 * @param isParentSelected The parent (or ancestor) node is selected.
 */
export const getIsContentNodeClasses = (
  isMergeMode?: boolean,
  isSelectable?: boolean,
  isSelected?: boolean,
  isParentSelectable?: boolean,
  isParentSelected?: boolean,
): string => {
  let classes = [
    'rounded border-solid border-transparent bg-transparent py-2 font-mono text-sm hover:cursor-pointer hover:border-slate-200',
  ]

  if (isMergeMode) {
    if (!isSelectable) {
      classes = classes.concat([
        'hover:cursor-default hover:border-transparent',
      ])

      if (!isParentSelectable) classes = classes.concat(['opacity-50'])

      if (isParentSelectable) classes = classes.concat(['font-bold'])
    }

    if (isSelectable) classes = classes.concat(['font-bold'])
  }

  if (isSelected) classes = classes.concat(['border-slate-300 font-bold'])

  if (isParentSelected) classes = classes.concat(['font-bold text-teal-500'])

  return twMerge(classes)
}
