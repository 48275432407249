import { useState } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { usePreviewCodeGeneration } from '~/hooks/api/codeGenerationStrategy/usePreviewCodeGeneration'
import { AiBlueprintPreviewFormEnum } from '~/models/enums/forms/AiBlueprintPreviewFormEnum'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'
import { addFileLanguages } from './utils/addFileLanguages'
import { getPreviewFor } from './utils/getPreviewFor'

/**
 * Hook to support the `AiBlueprintPreview` component.
 */
export const useAiBlueprintPreview = () => {
  // States.
  // Track if form was ever submitted (won't be reset)
  const [wasEverSubmitted, setWasEverSubmitted] = useState<boolean>(false)

  // React Hook Form.
  const { control, formState, handleSubmit, reset, watch } =
    useForm<FieldValues>({
      values: {
        [AiBlueprintPreviewFormEnum.ARC_MAP_NODE]: {},
        [AiBlueprintPreviewFormEnum.BLUEPRINTS]: [],
      },
    })

  const { isDirty } = formState

  const selectedArcMapNode = watch(
    AiBlueprintPreviewFormEnum.ARC_MAP_NODE,
  ) as SelectedArcMapNodeInfo

  const selectedBlueprints = watch(AiBlueprintPreviewFormEnum.BLUEPRINTS)

  // Hooks.
  const {
    data: previewCodeData,
    error: previewCodeError,
    isPending: previewCodeIsPending,
    mutateAsync,
  } = usePreviewCodeGeneration()

  // Constants.
  const disabledPreviewBtn =
    !selectedBlueprints ||
    !selectedBlueprints.length ||
    !selectedArcMapNode?.id ||
    !isDirty

  const previewData = addFileLanguages(
    previewCodeData?.data?.value?.directoryItems,
  )

  // Methods.
  const onSubmitPreviewCode = async (data: FieldValues) => {
    setWasEverSubmitted(true)

    const { arcMapNode, blueprints } = data
    const { aggregateId, id, type } = arcMapNode || {}

    await mutateAsync({
      aggregateId,
      generationStrategyIds: blueprints,
      previewFor: getPreviewFor(type),
      previewForId: id,
    })

    // Reset the form's dirty state while keeping the values.
    reset(data, {
      keepValues: true,
    })
  }

  // Placeholder text to display when there is no file to preview.
  const placeholderMsg =
    (!selectedArcMapNode || !previewData) && !previewCodeError
      ? 'Please selected an aggregate, command, reaction, type or query'
      : !!previewCodeError
        ? previewCodeError
        : undefined

  return {
    control,
    disabledPreviewBtn,
    handleSubmit,
    isDirty,
    onSubmitPreviewCode,
    placeholderMsg,
    previewCodeIsPending,
    previewData,
    wasEverSubmitted,
    watch,
  }
}
