// The possible colors.
const CLASS_MAP = {
  Default: 'bg-yellow-100 text-yellow-900',
  Aggregate: 'bg-teal-100 text-teal-900',
  Commands: 'bg-blue-100 text-blue-900',
  Reactions: 'bg-green-100 text-green-900',
  Queries: 'bg-purple-100 text-purple-900',
  Types: 'bg-fuchsia-100 text-fuchsia-900',

  // Other colors that can be covered.
  // 'bg-lime-100 text-lime-900'
  // 'bg-violet-100 text-violet-900'
  // 'bg-fuchsia-100 text-fuchsia-900'
  // 'bg-orange-100 text-orange-900'
  // 'bg-amber-100 text-amber-900',
  // 'bg-emerald-100 text-emerald-900'
  // 'bg-cyan-100 text-cyan-900'
  // 'bg-indigo-100 text-indigo-900'
  // 'bg-purple-100 text-purple-900'
  // 'bg-red-100 text-red-900'
  // 'bg-orange-100 text-orange-900'
}

/**
 * Returns the proper class names color,
 * based on the provided value format:
 * The values are mapped, based on CLASS_MAP.
 * @param value The value to check the index for the color.
 */
export const getColorClass = (value?: string) => {
  if (!value) return CLASS_MAP.Default

  const parts = value.split('.')

  // First, check for exact matches in our classMap.
  for (const part of parts) {
    if (CLASS_MAP[part as keyof typeof CLASS_MAP]) {
      return CLASS_MAP[part as keyof typeof CLASS_MAP]
    }
  }

  // If no exact match found, check for partial matches.
  for (const [key, className] of Object.entries(CLASS_MAP)) {
    if (parts.some((part) => part.includes(key))) {
      return className
    }
  }

  // If no match found, return the Default class.
  return CLASS_MAP.Default
}
