/** @type {import('tailwindcss').Config} */
import { extendTailwindMerge } from 'tailwind-merge'
import type { Config } from 'tailwindcss'
import plugin from 'tailwindcss/plugin'
import { designSystemTokens } from './src/styles/designSystemTokens'

const config: Config = {
  darkMode: 'class',
  corePlugins: {
    preflight: false,
  },
  important: '#app',
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      fontFamily: {
        // Set Visby CF as the default font.
        sans: designSystemTokens.typography.fontFamily[100],
        // Individual design system variations.
        'family-100': designSystemTokens.typography.fontFamily[100],
        'family-200': designSystemTokens.typography.fontFamily[200],
        'system-mac': designSystemTokens.typography.fontFamily.systemMac,
        'system-ios': designSystemTokens.typography.fontFamily.systemIos,
        'system-android':
          designSystemTokens.typography.fontFamily.systemAndroid,
        'system-windows':
          designSystemTokens.typography.fontFamily.systemWindows,
      },
      fontSize: {
        'size-50': designSystemTokens.typography.fontSize[50],
        'size-100': designSystemTokens.typography.fontSize[100].default,
        'size-100-large': designSystemTokens.typography.fontSize[100].large,
        'size-100-small': designSystemTokens.typography.fontSize[100].small,
        'size-200': designSystemTokens.typography.fontSize[200].default,
        'size-200-large': designSystemTokens.typography.fontSize[200].large,
        'size-200-small': designSystemTokens.typography.fontSize[200].small,
        'size-300': designSystemTokens.typography.fontSize[300].default,
        'size-300-large': designSystemTokens.typography.fontSize[300].large,
        'size-300-small': designSystemTokens.typography.fontSize[300].small,
        'size-400': designSystemTokens.typography.fontSize[400].default,
        'size-400-large': designSystemTokens.typography.fontSize[400].large,
        'size-400-small': designSystemTokens.typography.fontSize[400].small,
        'size-500': designSystemTokens.typography.fontSize[500].default,
        'size-500-large': designSystemTokens.typography.fontSize[500].large,
        'size-500-small': designSystemTokens.typography.fontSize[500].small,
        'size-600': designSystemTokens.typography.fontSize[600].default,
        'size-600-large': designSystemTokens.typography.fontSize[600].large,
        'size-600-small': designSystemTokens.typography.fontSize[600].small,
        'size-700': designSystemTokens.typography.fontSize[700].default,
        'size-700-large': designSystemTokens.typography.fontSize[700].large,
        'size-700-small': designSystemTokens.typography.fontSize[700].small,
        'size-800': designSystemTokens.typography.fontSize[800].default,
        'size-800-large': designSystemTokens.typography.fontSize[800].large,
        'size-800-small': designSystemTokens.typography.fontSize[800].small,
        'size-900': designSystemTokens.typography.fontSize[900].default,
        'size-900-large': designSystemTokens.typography.fontSize[900].large,
        'size-900-small': designSystemTokens.typography.fontSize[900].small,
        'size-giant': designSystemTokens.typography.fontSize.giant,
      },
      lineHeight: {
        '100': designSystemTokens.typography.lineHeight[100].default,
        '100-large': designSystemTokens.typography.lineHeight[100].large,
        '100-small': designSystemTokens.typography.lineHeight[100].small,
        '200': designSystemTokens.typography.lineHeight[200].default,
        '200-large': designSystemTokens.typography.lineHeight[200].large,
        '200-small': designSystemTokens.typography.lineHeight[200].small,
        '300': designSystemTokens.typography.lineHeight[300].default,
        '300-large': designSystemTokens.typography.lineHeight[300].large,
        '300-small': designSystemTokens.typography.lineHeight[300].small,
        '400': designSystemTokens.typography.lineHeight[400].default,
        '400-large': designSystemTokens.typography.lineHeight[400].large,
        '400-small': designSystemTokens.typography.lineHeight[400].small,
        '500': designSystemTokens.typography.lineHeight[500].default,
        '500-large': designSystemTokens.typography.lineHeight[500].large,
        '500-small': designSystemTokens.typography.lineHeight[500].small,
        '600': designSystemTokens.typography.lineHeight[600].default,
        '600-large': designSystemTokens.typography.lineHeight[600].large,
        '600-small': designSystemTokens.typography.lineHeight[600].small,
        '700': designSystemTokens.typography.lineHeight[700].default,
        '700-large': designSystemTokens.typography.lineHeight[700].large,
        '700-small': designSystemTokens.typography.lineHeight[700].small,
        '800': designSystemTokens.typography.lineHeight[800].default,
        '800-large': designSystemTokens.typography.lineHeight[800].large,
        '800-small': designSystemTokens.typography.lineHeight[800].small,
        '900': designSystemTokens.typography.lineHeight[900].default,
        '900-large': designSystemTokens.typography.lineHeight[900].large,
        '900-small': designSystemTokens.typography.lineHeight[900].small,
        display: designSystemTokens.typography.lineHeight.display,
      },
      letterSpacing: {
        100: designSystemTokens.typography.letterSpacing[100],
        200: designSystemTokens.typography.letterSpacing[200],
        300: designSystemTokens.typography.letterSpacing[300],
        400: designSystemTokens.typography.letterSpacing[400],
        500: designSystemTokens.typography.letterSpacing[500],
        600: designSystemTokens.typography.letterSpacing[600],
        700: designSystemTokens.typography.letterSpacing[700],
        800: designSystemTokens.typography.letterSpacing[800],
        900: designSystemTokens.typography.letterSpacing[900],
      },
      colors: {
        ...designSystemTokens.colors,

        font: {
          DEFAULT: designSystemTokens.colors.neutral.high[300],
          muted: '#546E7A',
          contrast: '#FFFFFF',
          lightGray: '#7E80A4',
          dark: {
            DEFAULT: '#FFFFFF',
          },
        },
        paper: {
          DEFAULT: '#FFFFFF',
          dark: {
            DEFAULT: '#272B39',
          },
        },
        background: {
          DEFAULT: '#FBFCFF',
          paper: '#FFFFFF',
          highlight: '#F4F5FF',
          bright: '#FAFAFF',
          panel: '#FFFFFF',
          dark: {
            DEFAULT: '#1E1F2C',
            paper: '#272B39',
            highlight: '#1E1F2C',
            bright: '#121212',
            panel: '#474B57',
          },
          glass: 'rgba(255, 255, 255, 0.3)',
          hover: 'rgba(24,27,101,0.1)',
          transparent: 'rgba(0, 0, 0, 0.0)',
          chip: '#E5E7FC',
          business: '#F2F5FE',
        },
        command: {
          DEFAULT: '#0a2342',
          light: '#FFFFFF',
        },
        event: {
          DEFAULT: '#ff6b6b',
          light: '#ffe6e6',
        },
        reaction: {
          DEFAULT: '#175467',
          light: '#FFFFFF',
        },
        query: {
          DEFAULT: '#9C27B0',
          light: '#f3e5f5',
        },
        raised: {
          DEFAULT: '#23AFDB',
        },
        divider: {
          light: '#DEE1F8',
        },
        node: {
          border: '#A3AAE3',
          red: '#FF5252',
          green: 'rgba(16,200,171,1)',
          yellow: '#ffee58',
        },
        success: {
          DEFAULT: '#4CAF50',
        },
      },
      boxShadow: {
        1: '3px 3px 3px 0 rgba(24,27,101,0.1)',
        2: 'rgba(24, 27, 101, 0.1) 3px 3px 3px, rgba(24, 27, 101, 0.03) -3px -3px 3px',
        3: `0px 0px 10px 1px rgba(24,27,101,0.1)`,
        4: `0px 0px 10px 1px rgba(16,200,171,1)`,
        5: `0px 0px 10px 1px #D81B60`,
        6: `0px 0px 10px 1px #ffee58`,
        appBar: '4px 0px 8px 0px rgba(0, 2, 70, 0.14)',
        menuLeft: '5px 0px 5px -2px #e5e5e5',
      },
      dropShadow: {
        1: '3px 3px 3px 0 rgba(24,27,101,0.1)',
      },
      textShadow: {
        DEFAULT:
          '-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF, 3px 3px 3px rgba(24,27,101,0.1)',
      },
      animation: {
        breathNodeBoxShadow: 'breathNodeBoxShadow 2s infinite',
        ellipsis: 'ellipsis steps(1,end) 1s infinite',
      },
      keyframes: {
        breathNodeBoxShadow: {
          '0%, 100%': {
            boxShadow: `0px 0px 10px 1px rgba(16,200,171,1)`,
          },
          '50%': {
            boxShadow: `0px 0px 80px 10px rgba(16,200,171,1)`,
          },
        },
        ellipsis: {
          '0%': { content: '""' },
          '25%': { content: '"."' },
          '50%': { content: '".."' },
          '75%': { content: '"..."' },
          '100%': { content: '""' },
        },
      },

      gridTemplateColumns: {
        contentWithSideNav: '2fr minmax(250px, auto) minmax(600px, auto) 2fr',
      },
    },
    screens: {
      '2xl': { max: '1535px' },
      xl: { max: '1279px' },
      lg: { max: '1023px' },
      md: { max: '767px' },
      sm: { max: '639px' },
    },
  },
  plugins: [
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          'text-shadow': (value) => ({
            textShadow: value,
          }),
        },
        { values: theme('textShadow') },
      )
    }),
    ({ addVariant, e }: any) => {
      addVariant('not-disabled', ({ modifySelectors, separator }: any) => {
        modifySelectors(
          ({ className }: any) =>
            `.${e(`not-disabled${separator}${className}`)}:not(:disabled)`,
        )
      })
    },
  ],
}

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': Object.keys(config?.theme?.extend?.fontSize || []).map(
        (key) => `text-${key}`,
      ),
    },
  },
})

export default config
