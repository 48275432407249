import { useMutation } from '@tanstack/react-query'
import type { FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { ERROR_MESSAGES } from '~/constants/api/codeGenerationStrategy/errorMessages'
import type { PayloadPreviewCodeGeneration } from '~/models/types/api/codeGenerationStrategy/PayloadPreviewCodeGeneration'
import type { ErrorResponseType } from '~/models/types/http/ErrorResponseType'
import {
  GenerationStrategyAPI,
  serviceDevelopmentAggregate,
} from '~/services/base'
import { getQueryMutationError } from '~/utils/api/codeGenerationStrategy/getQueryMutationError'

/**
 * Hook that implements react query `mutation`
 * to preview code generation.
 */
export const usePreviewCodeGeneration = () => {
  const { platformId } = useParams()

  const {
    error: mutationError,
    isError,
    ...restMutation
  } = useMutation({
    mutationFn: (fieldValues: FieldValues) => {
      const {
        aggregateId,
        fileIds,
        generationStrategyIds,
        previewFor,
        previewForId,
      } = fieldValues || {}

      invariant(platformId, ERROR_MESSAGES.platformId)
      invariant(aggregateId, ERROR_MESSAGES.aggregateId)

      const url = GenerationStrategyAPI.PreviewCodeGeneration

      const postData: PayloadPreviewCodeGeneration = {
        aggregateId,
        fileIds,
        generationStrategyIds,
        platformId,
        previewFor,
        previewForId,
      }

      return serviceDevelopmentAggregate.post(url, postData)
    },
  })

  const error = getQueryMutationError(
    isError,
    mutationError as ErrorResponseType,
  )

  return { ...restMutation, error, isError }
}
