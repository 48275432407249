import { twMerge } from '^/tailwind.config'
import { useRef, type RefObject } from 'react'
import { v4 as uuid } from 'uuid'
import type { ValueBindingTreeItemProps } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import { highlightFilteredValue } from '~/utils/strings/highlightFilteredValue'
import {
  ValueBindingToolbar,
  type ValueBindingToolbarProps,
} from '../ValueBindingTreeItem/ValueBindingToolbar'
import {
  HighlightedParam,
  type HighlightedParamProps,
} from './HighlightedParam'

export type ColoredParamsProps = Pick<ValueBindingTreeItemProps, 'node'> &
  Pick<
    ValueBindingToolbarProps,
    | 'onBindingContextClick'
    | 'onOpenDeleteDialogClick'
    | 'onOpenMappingConditionDialogClick'
    | 'onOpenRevertDialogClick'
  > &
  Pick<
    HighlightedParamProps,
    'filteredValue' | 'highlightClassName' | 'noTooltip' | 'params'
  > & {
    /**
     * The ref of the content anchor:
     * A component to indicate where
     * the text content actually starts.
     */
    contentAnchorRef?: RefObject<HTMLSpanElement>
    /** Forces the toolbar tip to open. */
    forceToolbarTooltipOpen?: boolean
    /** Flag to determine if this is a group node. */
    isGroupNode?: boolean
    /** Indicates if the parent component is hovered. */
    isParentHovered?: boolean
    /** The callback handler for case change. */
    onChange?: (newValue: string) => void
    /** The value input string to process and render. */
    value: string
  }

/**
 * This component takes a string value and an array of params.
 * It renders the value with special formatting for content
 * within curly braces that matches any of the params.
 * Matched content is wrapped in a Tooltip component with custom styling.
 */
export const ColoredParams = (props: ColoredParamsProps) => {
  const {
    contentAnchorRef,
    filteredValue,
    forceToolbarTooltipOpen,
    highlightClassName,
    isGroupNode,
    isParentHovered,
    node,
    noTooltip,
    onBindingContextClick,
    onChange,
    onOpenDeleteDialogClick,
    onOpenMappingConditionDialogClick,
    onOpenRevertDialogClick,
    params,
    value,
  } = props

  // Regex to match content within curly braces.
  const regex = /\{([^}]+)\}/g

  // Refs.
  const uniqueId = useRef(uuid()).current

  // Has node binding context.
  const hasBindingContext = !!node?.bindingContext

  // Case change callback handler.
  const handleCaseChange = (
    originalParam: string,
    newCase: string,
    position: number,
  ) => {
    // Find all parameter matches in the original string.
    const matches: Array<{
      param: string
      start: number
      end: number
    }> = []

    let match
    while ((match = regex.exec(value)) !== null) {
      matches.push({
        param: match[1] as string, // The parameter without curly braces.
        start: match.index,
        end: match.index + match[0].length,
      })
    }

    // Only update if there are enough matches.
    if (position < matches.length) {
      // Create the new parameter string.
      const [paramPath] = originalParam.split(':')
      const newParam = `${paramPath?.trim()}:${newCase}`

      // Build the new value string by replacing only the correct instance.
      let newValue = value.slice(0, matches[position]?.start) // Text before the parameter.
      newValue += `{${newParam}}` // New parameter.
      newValue += value.slice(matches[position]?.end) // Text after the parameter.

      onChange?.(newValue)
    }
  }

  // Find the first non-whitespace character position.
  const firstNonWhitespaceIndex = value.search(/\S/) // matches any non-whitespace character.

  // Handle cases where the string is all whitespace or empty.
  const leadingWhitespace =
    firstNonWhitespaceIndex === -1
      ? value
      : value.slice(0, firstNonWhitespaceIndex)

  const remainingContent =
    firstNonWhitespaceIndex === -1 ? '' : value.slice(firstNonWhitespaceIndex)

  // Helper function to preserve whitespace characters in HTML.
  const renderWhitespace = (text: string) => {
    return text.split('\n').map((line, i, arr) => (
      <span key={i}>
        {line.replace(/ /g, '\u00A0')}
        {i < arr.length - 1 && <br />}
      </span>
    ))
  }

  // Split the input value into parts, separating the content inside curly braces.
  const parts = remainingContent.split(regex)

  // The rendered parts: value checked against the params.
  const renderedParts = parts.map((part, index) => {
    if (index % 2 === 0) {
      // Even indices: text outside curly braces, render as-is.
      return (
        <span key={`${uniqueId}-${index}`} data-value={part}>
          {highlightFilteredValue(part, filteredValue)}
        </span>
      )
    } else {
      // Odd indices: content inside curly braces.

      // Calculate parameter position from index
      // Since index 1 is first param, 3 is second param, etc.
      // We can use Math.floor(index/2) to get the position.
      const paramPosition = Math.floor(index / 2)

      return (
        <HighlightedParam
          filteredValue={filteredValue}
          highlightClassName={highlightClassName}
          key={`${uniqueId}-${index}`}
          noTooltip={noTooltip}
          onCaseChange={(newCase) =>
            handleCaseChange(part, newCase, paramPosition)
          }
          params={params}
          stringPart={part}
        />
      )
    }
  })

  // Wrap all rendered parts in a span, with conditional styling.
  return (
    <span
      className={twMerge(
        (value === '' || value === `\r\n`) && !isGroupNode ? 'px-1' : '',
      )}
    >
      {/* Render leading whitespace with preserved formatting. */}
      {leadingWhitespace && (
        <span data-value={leadingWhitespace}>
          {renderWhitespace(leadingWhitespace)}
        </span>
      )}

      {/* Add the anchor span after all leading whitespace. */}
      <span
        className={twMerge(
          'relative h-0 w-0',
          hasBindingContext && 'align-bottom leading-[65px]',
        )}
        data-popper-anchor="true"
        ref={contentAnchorRef}
      >
        {(isParentHovered || !!node?.bindingContext) && (
          <ValueBindingToolbar
            className="absolute bottom-[100%] left-0 bg-white py-1"
            forceOpen={forceToolbarTooltipOpen}
            isFormHovered={isParentHovered}
            node={node}
            onBindingContextClick={onBindingContextClick}
            onOpenDeleteDialogClick={onOpenDeleteDialogClick}
            onOpenMappingConditionDialogClick={
              onOpenMappingConditionDialogClick
            }
            onOpenRevertDialogClick={onOpenRevertDialogClick}
          />
        )}
      </span>

      {renderedParts}
    </span>
  )
}
