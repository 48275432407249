import { produce } from 'immer'
import {
  useEffect,
  useMemo,
  useState,
  type BaseSyntheticEvent,
  type Dispatch,
  type SetStateAction,
} from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useSubmitValuePropertyBinding } from '~/hooks/api/codeGenerationStrategy/useSubmitValuePropertyBinding'
import { useUpdateTextSection } from '~/hooks/api/codeGenerationStrategy/useUpdateTextSection'
import { useAppSnackbarContext } from '~/hooks/contexts/useAppSnackbarContext'
import { useSelectedTextSectionsContext } from '~/hooks/contexts/useSelectedTextSectionsContext'
import { ValueBindingTreeItemFormEnum } from '~/models/enums/forms/ValueBindingTreeItemFormEnum'
import type { SelectedTextSectionsType } from '~/models/types/components/codeGenerationStrategy/ManageNodeSelection/SelectedTextSectionsType'
import type {
  ConfigForMenuToReplace,
  ValueBindingTreeItemProps,
} from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import type { FormHook } from '~/models/types/hooks/FormHook'
import type { QueryMutationHook } from '~/models/types/hooks/QueryMutationHook'
import type { TextSection } from '~/services/GenerationStrategy.types'

type UseValueBindingTreeItemHook = FormHook &
  QueryMutationHook & {
    /** The `onBlur` handler for the `value` field. */
    handleValueFieldBlur: () => void
    /** The config options for `menu to replace text`. */
    configForMenuToReplace?: ConfigForMenuToReplace
    /** Resets the form with the initial values. */
    resetFormToInitialValues?: () => void
    /** The set state method for `configForMenuToReplace`. */
    setConfigForMenuToReplace: Dispatch<SetStateAction<ConfigForMenuToReplace>>
  }

/**
 * Hook to support the `ValueBindingTreeItem` component.
 */
export const useValueBindingTreeItem = (
  props: ValueBindingTreeItemProps,
): UseValueBindingTreeItemHook => {
  const { initialValue, node, fileId } = props

  // Config options for `menu to replace text`.
  const [configForMenuToReplace, setConfigForMenuToReplace] =
    useState<ConfigForMenuToReplace>(null)

  // Selected text sections states.
  const {
    editableNode,
    setEditableNode,
    selectedTextSections,
    setSelectedTextSections,
  } = useSelectedTextSectionsContext()

  // Query string params.
  let { generationStrategyId } = useParams()

  // Initial values.
  const initialParameters = useMemo(
    () =>
      JSON.stringify(
        node?.parameters?.map((item: { path: string }) => item.path) ?? [],
      ),
    [node?.parameters],
  )

  const formInitialValues = {
    [ValueBindingTreeItemFormEnum.BINDING_CONTEXT]: node?.bindingContext || '',
    [ValueBindingTreeItemFormEnum.DELIMITER]: node?.delimiter?._t || '',
    [ValueBindingTreeItemFormEnum.FILE_ID]: fileId,
    [ValueBindingTreeItemFormEnum.ID]: node?.id,
    [ValueBindingTreeItemFormEnum.MAPPING_CONDITION]:
      node?.mappingCondition || '',
    [ValueBindingTreeItemFormEnum.NODE]: JSON.stringify(node),
    [ValueBindingTreeItemFormEnum.PARAMETERS]: initialParameters,
    [ValueBindingTreeItemFormEnum.SEPARATOR]: node?.separator || '',
    [ValueBindingTreeItemFormEnum.VALUE]: initialValue ?? '',
  }

  // Snackbar hook.
  const { closeAppSnackbar, openAppSnackbar } = useAppSnackbarContext()

  // Form set up.
  const {
    control,
    formState: { isDirty: isFormDirty },
    handleSubmit,
    reset: resetForm,
    setValue: setFormValue,
    watch: watchForm,
  } = useForm<FieldValues>({
    values: { ...formInitialValues },
  })

  // Form submit mutation for submit value property binding.
  const {
    error: submitValuePropertyBindingError,
    isError: isSubmitValuePropertyBindingError,
    isPending: isSubmitValuePropertyBindingPending,
    isSuccess: isSubmitValuePropertyBindingSuccess,
    mutate: mutateSubmitValuePropertyBinding,
    reset: resetSubmitValuePropertyBinding,
  } = useSubmitValuePropertyBinding({
    generationStrategyId,
  })

  // Form submit mutation for update text section.
  const {
    error: updateTextSectionError,
    isError: isUpdateTextSectionError,
    isPending: isUpdateTextSectionPending,
    isSuccess: isUpdateTextSectionSuccess,
    mutate: mutateUpdateTextSection,
    reset: resetUpdateTextSection,
  } = useUpdateTextSection()

  const error = submitValuePropertyBindingError || updateTextSectionError
  const isError = isSubmitValuePropertyBindingError || isUpdateTextSectionError
  const isPending =
    isSubmitValuePropertyBindingPending || isUpdateTextSectionPending
  const isSuccess =
    isSubmitValuePropertyBindingSuccess || isUpdateTextSectionSuccess

  // Handler for the `value` field blur.
  const handleValueFieldBlur = () => {
    // Avoid any action when the menu is opened.
    if (!!configForMenuToReplace) return

    // Make the value field a text again (not editable).
    setEditableNode?.(null)
  }

  // Lifecycle to set error snackbar props.
  useEffect(() => {
    if (error) {
      openAppSnackbar?.({
        alertProps: {
          children: error as string,
          severity: 'error',
        },
        snackbarProps: {
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          autoHideDuration: 3000,
          onClose: closeAppSnackbar,
          open: true,
        },
      })
    }
  }, [error])

  // Lifecycle to reset form and mutation states.
  useEffect(() => {
    // Reset form states mutation is error.
    if (isError) resetForm?.()

    // Reset form states when the mutation is error or success.
    if (isError || isSuccess) {
      if (node?._t === 'ValuePropertyBinding')
        resetSubmitValuePropertyBinding?.()

      if (node?._t === 'TextSection') resetUpdateTextSection?.()
    }

    // Set value field to NOT editable.
    if (editableNode && (isError || isSuccess)) handleValueFieldBlur()
  }, [editableNode, isError, isSuccess, node])

  // Handles the form submit.
  const handleFormSubmit = async (
    data: FieldValues,
    forceSubmit?: BaseSyntheticEvent | boolean,
  ) => {
    const { fileId: formFileId, node: formNode } = data

    // Avoid any action when the menu is opened.
    if (!!configForMenuToReplace) return

    // Only submits if the form is dirty.
    if (formNode && formFileId && (isFormDirty || forceSubmit)) {
      if (node?._t === 'ValuePropertyBinding')
        mutateSubmitValuePropertyBinding?.(data)

      if (node?._t === 'TextSection') {
        mutateUpdateTextSection?.(data, {
          onSuccess: () => {
            if (!!selectedTextSections) {
              // Set new state for `selectedNode`.
              const newState = produce(
                selectedTextSections,
                (draft: SelectedTextSectionsType) => {
                  ;(draft.selectedNode as TextSection).value = data.value
                },
              )
              setSelectedTextSections?.(newState)
            }
          },
        })
      }
    } else {
      handleValueFieldBlur()
    }
  }

  // Resets the form values by setting the initial values.
  const resetFormToInitialValues = () => resetForm({ ...formInitialValues })

  return {
    configForMenuToReplace,
    control,
    handleFormSubmit,
    handleSubmit,
    handleValueFieldBlur,
    isFormDirty,
    isPending,
    resetFormToInitialValues,
    setConfigForMenuToReplace,
    setFormValue,
    watchForm,
  }
}
