import { useReactFlow, type Node } from '@xyflow/react'
import { useEffect, useRef, useState, type DragEvent } from 'react'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import type { NodeStatement } from '~/services/Process.types'
import { nodeHeight, nodeWidth } from '../utils'
import { calculateNodeAbsolutePosition } from '../utils/calculateNodeAbsolutePosition'

export const NODES_TRANSITION_DURATION_ON_SELECT = 300
const NODES_TRANSITION_PADDING_ON_SELECT = 8
const NODES_TRANSITION_PADDING_ON_SELECT_WITH_EDITING_BOX = 8
const INCREASE_TOP_VALUE_ON_SELECT_WITH_EDITING = 80

type UseStatementsDiagramReactFlowProps = Pick<NodeStatement, 'isExpanded'> & {
  /** The react flow nodes array. */
  nodes?: Node[]
}

/**
 * Hook to support custom features from the `ReactFlow`
 * component implemented in the `StatementsDiagram`.
 */
export const useStatementsDiagramReactFlow = (
  props: UseStatementsDiagramReactFlowProps,
) => {
  const { isExpanded = false, nodes } = props

  // Create a ref to always have access to latest nodes.
  // This is necessary because sometimes when `handleNodeFit`
  // is called, the `nodes` props doesn't have the most updated data,
  // which requires to set a timeout (inside the timeout on the `ref`
  // can refer to the most updated value).
  const nodesRef = useRef(nodes)

  // Keep the ref updated.
  useEffect(() => {
    nodesRef.current = nodes
  }, [nodes])

  // States.
  const [isReactFlowDragging, setIsReactFlowDragging] = useState<boolean>(false)
  const [isReactFlowSpacePressed, setIsReactFlowSpacePressed] =
    useState<boolean>(false)

  // Hooks.
  const { fitBounds, setCenter } = useReactFlow()

  // Lifecycle.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        setIsReactFlowSpacePressed(true)
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        setIsReactFlowSpacePressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [setIsReactFlowSpacePressed])

  // Methods.
  const handleReactFlowDragStart = (e: DragEvent<HTMLDivElement>) => {
    setIsReactFlowDragging(true)
  }

  const handleReactFlowDragEnd = (e: DragEvent<HTMLDivElement>) => {
    setIsReactFlowDragging(false)
  }

  /**
   * Handles the react flow fit bounds to centralize the provided node.
   * @node The node to be fit to screen.
   * @zoom The zoom level when fitting to screen.
   */
  const handleNodeFit = (targetNode: Node<NodeStatement>, zoom?: number) => {
    // Add a small delay to ensure nodes array is updated.
    setTimeout(() => {
      const node = nodesRef.current?.find(
        (n) => n.id === targetNode.id,
      ) as Node<NodeStatement>

      if (!!node) {
        const { data } = node
        const { isEditingMode, isLocal } = data || {}

        const isEditingBoxAvailable =
          isEditingMode && isLocal && FEATURE_TOGGLE.BUSINESS.QUICK_EDIT

        // Get absolute position due to parent context nodes.
        const absolutePosition = calculateNodeAbsolutePosition(
          node,
          (nodesRef.current as Node<NodeStatement>[])!,
        )

        const verticalOffset = isEditingBoxAvailable
          ? INCREASE_TOP_VALUE_ON_SELECT_WITH_EDITING
          : 0

        const nodeHeightValue = nodeHeight({ isExpanded })

        const bounds = {
          x: absolutePosition.x,
          y: absolutePosition.y - verticalOffset,
          width: nodeWidth({ isExpanded }),
          height: nodeHeightValue,
        }

        // Fit bounds to ensure the node is in view.
        fitBounds(bounds, {
          duration: NODES_TRANSITION_DURATION_ON_SELECT,
          padding: isEditingBoxAvailable
            ? NODES_TRANSITION_PADDING_ON_SELECT_WITH_EDITING_BOX
            : NODES_TRANSITION_PADDING_ON_SELECT,
        })

        // Only call setCenter if a zoom level is provided.
        if (typeof zoom === 'number') {
          setTimeout(() => {
            const centerX = bounds.x + bounds.width / 2
            const centerY = absolutePosition.y + nodeHeightValue / 2

            // Use setCenter to apply the zoom.
            setCenter(centerX, centerY, {
              zoom: zoom,
              duration: NODES_TRANSITION_DURATION_ON_SELECT,
            })
          }, NODES_TRANSITION_DURATION_ON_SELECT)
        }
      }
    }, 0) // Using setTimeout with 0 delay to push to next event loop.
  }

  return {
    handleNodeFit,
    handleReactFlowDragEnd,
    handleReactFlowDragStart,
    isReactFlowDragging,
    isReactFlowSpacePressed,
  }
}
