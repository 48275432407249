import { LoadingButton, type LoadingButtonProps } from '@mui/lab'
import {
  ArrowClockwise,
  DotOutline,
  FileMagnifyingGlass,
} from '@phosphor-icons/react'
import type { UseFormReturn } from 'react-hook-form'

type ButtonPreviewProps = LoadingButtonProps &
  Pick<UseFormReturn, 'watch'> & {
    /** Indicates if the form is dirty. */
    isDirty?: boolean
    /** Indicates if the form was ever submitted (submit at least once). */
    wasEverSubmitted?: boolean
  }

/**
 * The preview button.
 */
export const ButtonPreview = (props: ButtonPreviewProps) => {
  const { disabled, isDirty, wasEverSubmitted, watch, ...rest } = props

  // Button props.
  let label = 'Preview'
  if (wasEverSubmitted) label = 'Refresh Preview'

  let color = 'secondary'
  const shouldResubmit = wasEverSubmitted && !disabled
  if (shouldResubmit) color = 'primary'

  let Icon = FileMagnifyingGlass
  if (wasEverSubmitted) Icon = ArrowClockwise

  return (
    <LoadingButton
      {...rest}
      color={color as LoadingButtonProps['color']}
      disabled={disabled}
      startIcon={<Icon size={25} />}
      type="submit"
      variant="text"
    >
      {label}
      {shouldResubmit && (
        <DotOutline
          className="absolute right-[-10px] top-[-5px]"
          size={25}
          weight="fill"
        />
      )}
    </LoadingButton>
  )
}
