import { useCallback, useEffect, useRef, useState, type RefObject } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { AddLocalChildStatementProps } from '~/models/types/components/processInfo/AddLocalChildStatementProps'
import type { StatementsDiagramHandle } from '~/pages/business/components/ProcessInfo/StatementsDiagram'
import { NodeTypesEnum } from '~/pages/business/components/ProcessInfo/StatementsDiagram/CustomNodes/types'
import { getStatementById } from '~/pages/business/components/ProcessInfo/utils'
import type { BusinessChildrenParams } from '~/routes/business/routes.types'
import {
  type ResponseDiscoveryProcess,
  type Statement,
} from '~/services/Process.types'
import { useMarkedAsFeature } from './business/useMarkedAsFeature'
import { getLinkEditCommandOrReaction } from './useLinkEditCommandOrReaction'

type UseStatementKeyboardActionsProps = {
  /** */
  deleteSelectedStatement: (toggleDeleteModal: () => void) => void
  /** */
  insertLocalStatementBetween: () => void
  /** */
  process: ResponseDiscoveryProcess
  /** */
  selectFirstChildStatementOrAdd: () => void
  /** */
  selectNextSiblingStatement: () => void
  /** */
  selectNewAndRemoveCurrentIfNeed: (props: AddLocalChildStatementProps) => void
  /** */
  selectParentStatement: () => void
  /** */
  selectPreviousSiblingStatement: () => void
  /** */
  selectedStatement: Statement | null
  /** */
  selectedStatements: Statement[]
  /** The ref of the `statement diagram` component. */
  statementDiagramRef: RefObject<StatementsDiagramHandle>
  /** */
  swapStatements: () => void
  /** */
  toggleMultiSelectEnablement: (newState: boolean) => void
}

export const useStatementsKeyboardActions = (
  props: UseStatementKeyboardActionsProps,
) => {
  const {
    deleteSelectedStatement,
    insertLocalStatementBetween,
    process,
    selectFirstChildStatementOrAdd,
    selectNextSiblingStatement,
    selectNewAndRemoveCurrentIfNeed,
    selectParentStatement,
    selectPreviousSiblingStatement,
    selectedStatement,
    selectedStatements,
    statementDiagramRef,
    swapStatements,
    toggleMultiSelectEnablement,
  } = props

  // React Router Dom.
  const params = useParams<BusinessChildrenParams>()
  const { organisationId, platformId = '' } = params

  const location = useLocation()

  const navigate = useNavigate()

  // States.
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  // Ref.
  const currentProcess = useRef(process)
  const currEditingStatement = useRef<GUID>()

  // Hooks.
  const { checkMarkedAsFeature } = useMarkedAsFeature({ platformId })

  // Constants.
  const isEditable = !!isEditingMode

  const regex = new RegExp(
    `/${organisationId}/${platformId}/business(?:/${process.identity}(?!/)|$)`,
  )
  const isStatementsView = regex.test(location.pathname)
  const canDelete =
    !selectedStatement?.isLocal && selectedStatements.length === 1

  // Methods.
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen((current) => !current)
  }

  // Handler for edit node.
  const handleEditNode = async () => {
    const { id, isFeature, parsingInfo } = selectedStatement || {}
    const { type } = parsingInfo || {}

    const markedAsFeature = await checkMarkedAsFeature({
      isFeature,
      statementId: id,
      statementType: parsingInfo?.type,
    })

    // If it is marked as feature.
    if (markedAsFeature) {
      const url = getLinkEditCommandOrReaction(type, id)

      // If there is an url navigate to it.
      if (!!url) return navigate(url)
      // Otherwise, do nothing.
      return
    }

    // If it is NOT marked as feature, select node and enable edit mode.
    // In case there is not a selected statement, select the first non group one.
    if (!selectedStatement?.id) {
      const firstNonGroupNode = statementDiagramRef?.current
        ?.getNodes()
        ?.find((node) => node.type !== NodeTypesEnum.GROUP)
      const targetStatement = getStatementById(
        process.statements,
        firstNonGroupNode?.id || '',
      )
      if (!!targetStatement?.id)
        selectNewAndRemoveCurrentIfNeed({ targetStatement })
    }

    return setIsEditingMode?.(true)
  }

  const handleEscapeKey = () => {
    // Leave editing mode in case it is active.
    if (isEditingMode) {
      setIsEditingMode(false)

      // Only deselect a node when it is a temporary node.
      const { isLocal, parsingInfo } = selectedStatement || {}
      const isTempStatement = isLocal && !parsingInfo?.success
      if (isTempStatement) selectNewAndRemoveCurrentIfNeed({})
    } else {
      selectNewAndRemoveCurrentIfNeed({})
    }
  }

  const getStatementDirectionFunction = useCallback(
    (keyCode: string) => {
      const behavior = {
        ...(!isEditable &&
          !isEditingMode &&
          !isDeleteModalOpen && {
            ['ArrowLeft']: selectParentStatement,
            ['ArrowRight']: selectFirstChildStatementOrAdd,
            ['ArrowUp']: selectPreviousSiblingStatement,
            ['ArrowDown']: selectNextSiblingStatement,
            ['Help']: insertLocalStatementBetween,
            ['Insert']: insertLocalStatementBetween,
            ['i']: insertLocalStatementBetween,
            ['Control']: () => toggleMultiSelectEnablement(true),
            ['Meta']: () => toggleMultiSelectEnablement(true),
            ['s']: swapStatements,
          }),
        ...(!isEditable &&
          !isEditingMode && {
            ['Backspace']: canDelete ? toggleDeleteModal : () => {},
            ['Delete']: canDelete ? toggleDeleteModal : () => {},
          }),
        ['Enter']: !isDeleteModalOpen
          ? () => handleEditNode()
          : () => deleteSelectedStatement(toggleDeleteModal),
        ['Escape']: handleEscapeKey,
      }

      const selectedBehavior =
        behavior[keyCode as keyof typeof behavior] || (() => {})

      return selectedBehavior()
    },
    [
      canDelete,
      deleteSelectedStatement,
      insertLocalStatementBetween,
      isDeleteModalOpen,
      isEditable,
      isEditingMode,
      selectFirstChildStatementOrAdd,
      selectNextSiblingStatement,
      selectParentStatement,
      selectPreviousSiblingStatement,
      swapStatements,
      toggleMultiSelectEnablement,
    ],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (process.isGeneratingProcess) {
        return
      }
      if (isStatementsView) {
        getStatementDirectionFunction(event.key)
      }
    },
    [
      getStatementDirectionFunction,
      isStatementsView,
      process.isGeneratingProcess,
    ],
  )

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (process.isGeneratingProcess) return

      const behavior = {
        ...(!isEditingMode &&
          !isDeleteModalOpen && {
            ['Control']: () => toggleMultiSelectEnablement(false),
            ['Meta']: () => toggleMultiSelectEnablement(false),
          }),
      }

      const selectedBehavior =
        behavior[event.key as keyof typeof behavior] || (() => {})

      return selectedBehavior()
    },
    [
      isDeleteModalOpen,
      isEditingMode,
      process.isGeneratingProcess,
      toggleMultiSelectEnablement,
    ],
  )

  // Leave edit mode when not clicking on the same node.
  const handleGlobalClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element
      const clickedElement = !!target && target.closest('[data-statement-id]')
      const clickedNodeId =
        !!clickedElement && clickedElement.getAttribute('data-statement-id')

      if (clickedNodeId !== currEditingStatement.current) {
        setIsEditingMode(false)
        currEditingStatement.current = undefined
      }
    },
    [selectedStatement, setIsEditingMode],
  )

  // Lifecycle.
  // Set current editing statement if necessary.
  useEffect(() => {
    // Initially set `currSelectedStatement` when a statement is editing.
    if (isEditingMode) currEditingStatement.current = selectedStatement?.id
  }, [isEditingMode])

  // Set event listeners.
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    if (isEditingMode) document.addEventListener('click', handleGlobalClick)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('click', handleGlobalClick)
    }
  }, [handleGlobalClick, handleKeyDown, handleKeyUp, isEditingMode])

  useEffect(() => {
    if (process.identity !== currentProcess.current.identity) {
      setIsEditingMode(false)
      currentProcess.current = process
    }
  }, [process])

  return {
    isDeleteModalOpen,
    isEditingMode,
    setIsEditingMode,
    toggleDeleteModal,
  }
}
