import { PublicClientApplication } from '@azure/msal-browser'
import { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { ActionFunctionArgs, generatePath, redirect } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { IS_DEV } from '~/config/constants'
import { BusinessRoutesEnum } from '~/models/enums/routes/BusinessRoutesEnum'
import {
  getAttributesFromForm,
  redirectToDeveloperHomeOrAggregate,
} from '~/routes/developer/utils'
import { queryDevelopment, queryKeyDevelopment } from '~/services/Development'
import { queryKeyOrganisation } from '~/services/Discovery'
import { FREE_FEATURES_USAGE } from '~/services/Discovery.types'
import {
  CUSTOM_DELAY,
  DevelopmentAggregateAPI,
  serviceDevelopmentAggregate,
  type CustomAxiosRequestConfig,
} from '~/services/base'
import { getUserEmail } from '~/utils/getUserEmail'
import { getUserId } from '~/utils/getUserId'
import { queriesByAggregate } from '~/utils/transform'

export const actionDeveloperEditQuery =
  (queryClient: QueryClient, pca: PublicClientApplication) =>
  async ({ request, params }: ActionFunctionArgs) => {
    const userId = getUserId(pca)
    const email = getUserEmail(pca)
    const { organisationId, platformId, queryId } = params

    const qDevelopment = queryDevelopment(platformId)
    const fetchDevelopment = await queryClient.ensureQueryData(qDevelopment)
    const foundQuerys = queriesByAggregate(fetchDevelopment.data)
    const aggregateId = foundQuerys.find(
      (query) => query.identity === queryId,
    )?.aggregateId

    invariant(aggregateId, 'No aggregateId found')
    invariant(platformId, 'No platformId found')
    invariant(userId, 'No userId found')

    // Get form data
    const formData = await request.formData()
    const name = formData.get('name')
    const typeId = formData.get('typeId')
    const allForm = Object.fromEntries(formData)
    const attributes = getAttributesFromForm({
      formEntries: allForm,
      kind: 'action.',
    })

    const autoGenerateButton = Object.keys(allForm).find((key) =>
      key.includes('AutoGenerateAttributes'),
    )
    if (autoGenerateButton) {
      if (aggregateId && typeof aggregateId === 'string') {
        const reqUrl = DevelopmentAggregateAPI.GenerateAttributes
        const data = {
          aggregateId: aggregateId?.toString() || '',
          entityQuery: 'Query Parameters',
          entityName: name?.toString(),
        }

        try {
          const res = await serviceDevelopmentAggregate.post(reqUrl, data)
          queryClient.setQueryData(
            queryKeyOrganisation(userId, email),
            (organisation: any) =>
              produce(organisation, (draft: any) => {
                draft.data.subscriptionPlan[
                  `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                ] =
                  organisation.data.subscriptionPlan[
                    `${FREE_FEATURES_USAGE.ATTRIBUTE_AUTO_GENERATION}Remaining`
                  ] - 1 || 0
              }),
          )
          return {
            formName: autoGenerateButton?.replace(
              '.AutoGenerateAttributes',
              '',
            ),
            attributes: res.data?.value?.attributes,
          }
        } catch (error) {
          if (IS_DEV) {
            console.log(error)
          }
          return []
        }
      }
    }

    const isCopyButtonPressed = Object.keys(allForm).find((key) =>
      key.includes('.copyButton'),
    )
    if (isCopyButtonPressed) {
      const attributes = getAttributesFromForm({
        formEntries: allForm,
        kind: isCopyButtonPressed.replace('copyButton', ''),
      })
      await navigator.clipboard.writeText(JSON.stringify(attributes))
      return null
    }

    // Builds the data to send to server
    const resultType = formData.get('resultType.type')
    const isCollectionResult = Boolean(formData.get('resultType.is_array'))
    const processId = null

    const postData = {
      aggregateId,
      processId,
      name,
      typeId,
      resultType,
      isCollectionResult,
      parameters: attributes,
      queryId,
    }

    // Setups and send to server
    const url = DevelopmentAggregateAPI.EditQuery
    const req = await serviceDevelopmentAggregate.post(url, postData, {
      customDelay: CUSTOM_DELAY,
    } as CustomAxiosRequestConfig)

    // Invalidates the cache
    queryClient.invalidateQueries({ queryKey: queryKeyDevelopment(platformId) })
    queryClient.invalidateQueries({
      queryKey: queryKeyDevelopment(platformId),
    })

    const requestUrl = new URL(request.url)
    const isBusiness = requestUrl.pathname.includes('business')

    if (isBusiness) {
      return redirect(
        generatePath(BusinessRoutesEnum.BUSINESS_PROCESS, {
          organisationId: organisationId || '',
          platformId: platformId || '',
          processId: processId || '',
        }),
      )
    }

    // redirects
    return redirectToDeveloperHomeOrAggregate(req, params)
  }
