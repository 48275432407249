import { TextField, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Form, useActionData, useLoaderData, useParams } from 'react-router-dom'
import { ButtonAutoGenerate } from '~/components/ButtonAutoGenerate'
import { Column } from '~/components/Column'
import { ResultType } from '~/components/ResultType'
import { useGetDomainReadModelTypes } from '~/hooks/api/business/useGetDomainReadModelTypes'
import { FormActionName } from '~/pages/developer/components/FormActionName/index'
import { FormAttributes } from '~/pages/developer/components/FormAttributes'
import { getAggregateTypesOptions } from '~/pages/developer/components/FormAttributes/FormAttributes.utils'
import { FormFooter } from '~/pages/developer/components/FormFooter'
import { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import type {
  Attribute,
  DomainByPlatformId,
} from '~/services/Development.types'
import { queriesByAggregate } from '~/utils/transform'
import { useAggregateActionForm } from '../hooks/useAggregateActionForm'
import { renamePropertiesToAttributes } from '../utils'

type DeveloperEditQueryProps = {
  showBoundedContextEdit: boolean
}

export function DeveloperEditQuery(props: DeveloperEditQueryProps) {
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: Attribute[]
  }
  const params = useParams<DeveloperChildrenParams & { queryId: GUID }>()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const { data } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData,
    select: (response) => response.data,
  })
  const query = queriesByAggregate(data)

  const selected = useMemo(
    () => query.find((type) => type.identity === params.queryId),
    [params.queryId, query],
  )

  const methods = useForm({
    defaultValues: {
      name: selected?.name,
      typeId: selected?.typeId,
      resultType: {
        is_array: selected?.result.isCollection,
        type: selected?.result.type,
        typeId: selected?.result.typeId,
        family: selected?.result.family,
      },
      action: {
        attributes: selected?.parameters,
      },
    },
  })

  const { data: aggregateTypes } = useGetDomainReadModelTypes({
    aggregateId: selected?.aggregateId || params.aggregateId,
  })

  const { formProps, submitButtonRef } = useAggregateActionForm()

  const options = getAggregateTypesOptions(aggregateTypes)

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === 'query' &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      methods.setValue(
        'action.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, methods])

  return (
    <>
      <Column className="gap-4 pb-12">
        <FormProvider {...methods}>
          <Form method="post" id="form-aggregate-edit-query" {...formProps}>
            <Column className="gap-4">
              {props.showBoundedContextEdit ? (
                <FormActionName
                  defaultValue={selected?.boundedContext}
                  inputLabel="Bounded Context"
                  inputName="boundedContext"
                >
                  <TextField
                    label="Aggregate"
                    variant="outlined"
                    defaultValue={selected?.aggregateName}
                    name="aggregateName"
                    required
                    fullWidth
                  />
                </FormActionName>
              ) : (
                <></>
              )}

              <FormActionName
                inputLabel="Name"
                inputName="name"
                defaultValue={selected?.name}
              >
                <input
                  type="hidden"
                  value={selected?.typeId}
                  {...methods.register('typeId')}
                />
                <FormAttributes
                  autoGenerateComponent={<ButtonAutoGenerate name="query." />}
                  inputLabel="Parameters"
                  formLabel="Parameters"
                  formName="action"
                />
                <Column className="pt-4">
                  <Typography>Return type</Typography>
                  <ResultType options={options} formName="resultType" />
                </Column>
              </FormActionName>
            </Column>

            <FormFooter submitButtonRef={submitButtonRef} />
          </Form>
        </FormProvider>
      </Column>
    </>
  )
}
