import { Button, Menu } from '@mui/material'
import { CaretDown, FileCode } from '@phosphor-icons/react'
import { useState, type MouseEvent } from 'react'
import { Controller, type UseFormReturn } from 'react-hook-form'
import { useListStrategiesForPublish } from '~/hooks/api/codeGenerationStrategy/useListStrategiesForPublish'
import { AiBlueprintPreviewFormEnum } from '~/models/enums/forms/AiBlueprintPreviewFormEnum'
import { CustomAiBlueprintTile } from '~/routes/developer/generate-code/components/CustomAiBlueprintTile/CustomAiBlueprintTile'
import type { ResponseListGenerationStrategies } from '~/services/GenerationStrategy.types'

type SelectAiBlueprintProps = Pick<UseFormReturn, 'control' | 'watch'>

/**
 * The component opens the AI Blueprint selector inside
 * a menu/select.
 */
export const SelectAiBlueprint = (props: SelectAiBlueprintProps) => {
  const { control, watch } = props

  // States.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Hooks.
  const { data: allBlueprints } = useListStrategiesForPublish()

  // Constants.
  const open = Boolean(anchorEl)

  const selectedBlueprints = watch(AiBlueprintPreviewFormEnum.BLUEPRINTS)

  let buttonLabel = 'Click to select'

  if (selectedBlueprints.length > 1)
    buttonLabel = `${selectedBlueprints.length} blueprints selected`

  if (selectedBlueprints.length === 1)
    buttonLabel = allBlueprints.find(
      (item: ResponseListGenerationStrategies) =>
        item.identity === selectedBlueprints[0],
    ).name

  // Methods.
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-controls={open ? 'aiblueprint-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="enabled:border-secondary-200"
        color="secondary"
        endIcon={<CaretDown size={12} weight="fill" />}
        id="aiblueprint-button"
        onClick={handleClick}
        startIcon={<FileCode size={24} />}
        variant="outlined"
      >
        {buttonLabel}
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="aiblueprint-menu"
        MenuListProps={{
          'aria-labelledby': 'aiblueprint-button',
          className: 'max-w-[450px] p-0',
        }}
        onClose={handleClose}
        open={open}
      >
        <Controller
          control={control}
          name={AiBlueprintPreviewFormEnum.BLUEPRINTS}
          render={({ field: { value, onChange } }) => (
            <CustomAiBlueprintTile
              blueprints={allBlueprints}
              className="border-0"
              onBlueprintChange={onChange}
              onTileClick={() => {}}
              selectedBlueprints={value}
            />
          )}
        />
      </Menu>
    </>
  )
}
