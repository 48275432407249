import {
  STATEMENT_PARSING_INFO_TYPE,
  StatementParsingInfoType,
} from '~/services/Process.types'

/**
 * Provides the url to the edit a command or reaction.
 */
export const getLinkEditCommandOrReaction = (
  type?: StatementParsingInfoType,
  id?: string,
) => {
  // In case it is a command.
  if (type === STATEMENT_PARSING_INFO_TYPE.Command) return `edit-command/${id}`

  // In case it is a reaction.
  if (type === STATEMENT_PARSING_INFO_TYPE.Reaction)
    return `edit-reaction/${id}`

  // In case it is a query.
  if (type === STATEMENT_PARSING_INFO_TYPE.Query) return `edit-query/${id}`

  return ''
}

/**
 * Hook that will provide the url to the edit a command or reaction.
 */
export const useLinkEditCommandOrReaction = (
  type?: StatementParsingInfoType,
  id?: string,
) => {
  return getLinkEditCommandOrReaction(type, id)
}
