/**
 * Enum with the form fields names
 * from `ai blueprint preview` form.
 */
export enum AiBlueprintPreviewFormEnum {
  /** The ArcMap node to preview. */
  ARC_MAP_NODE = 'arcMapNode',
  /** The selected blueprints to preview. */
  BLUEPRINTS = 'blueprints',
}
