import { SvgIconProps, Tooltip, type TooltipProps } from '@mui/material'
import { Info } from '@phosphor-icons/react'

export type InfoIconTooltipProps = Pick<TooltipProps, 'className' | 'title'> & {
  /** Props applied to the icon node. */
  iconProps?: SvgIconProps
  /** Props applied to the tooltip node. */
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>
}

/**
 * Renders and info icon with a tooltip.
 */
const InfoIconTooltip = (props: InfoIconTooltipProps) => {
  const { className, iconProps, title, tooltipProps } = props

  return (
    <Tooltip className={className} title={title} {...tooltipProps}>
      <Info {...iconProps} weight="fill" />
    </Tooltip>
  )
}

export default InfoIconTooltip
