import type {
  FileStructure,
  FolderOrFileStructure,
} from '~/services/GenerationStrategy.types'

// Map of file extensions to languages.
const EXTENSION_TO_LANGUAGE: Record<string, string> = {
  // Programming Languages.
  ts: 'typescript',
  tsx: 'typescript',
  js: 'javascript',
  jsx: 'javascript',
  py: 'python',
  java: 'java',
  rb: 'ruby',
  php: 'php',
  cs: 'csharp',
  go: 'go',
  rs: 'rust',
  swift: 'swift',
  kt: 'kotlin',

  // Markup & Style.
  html: 'html',
  htm: 'html',
  css: 'css',
  scss: 'scss',
  sass: 'sass',
  less: 'less',
  xml: 'xml',
  svg: 'svg',

  // Data Formats.
  json: 'json',
  yaml: 'yaml',
  yml: 'yaml',
  toml: 'toml',
  ini: 'ini',

  // Documentation.
  md: 'markdown',
  mdx: 'mdx',
  txt: 'plaintext',

  // Shell Scripts.
  sh: 'shell',
  bash: 'shell',
  zsh: 'shell',
  bat: 'batch',
  ps1: 'powershell',
}

/**
 * Recursively processes a file structure and adds language information
 * based on file extensions.
 * @param data Array of file/folder items to process.
 */
export const addFileLanguages = (
  data: FolderOrFileStructure[],
): FolderOrFileStructure[] =>
  data?.map((item) => {
    // Create a copy of the item to avoid mutating the original.
    const processedItem = { ...item }

    // If item has children (is a folder), process them recursively.
    if (item.children?.length) {
      processedItem.children = addFileLanguages(item.children)
    }

    // If item is a file, determine its language.
    if ((item as FileStructure).file) {
      const extension = (item as FileStructure)?.file?.filePath
        ?.split('.')
        ?.pop()
        ?.toLowerCase()

      if (extension) {
        ;(processedItem as FileStructure).file = {
          ...(item as FileStructure).file,
          language: EXTENSION_TO_LANGUAGE[extension] || 'plaintext',
        }
      }
    }

    return processedItem
  })
