import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'

/**
 * Provides an array of all folder IDs.
 * @param nodes The tree view data.
 */
export const getFolderIds = (nodes?: ArcMapTreeViewData[]): string[] => {
  if (!nodes || !nodes.length) return []

  return nodes.reduce<string[]>(
    (acc, node) => [
      ...acc,
      ...(node.children && node.children.length > 0
        ? [node.id, ...getFolderIds(node.children)]
        : []),
    ],
    [],
  )
}
