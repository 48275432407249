import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'
import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'
import { highlightFilteredValue } from '~/utils/strings/highlightFilteredValue'
import { TreeItem } from '../../TreeView.styles'
import { getNodeIcon } from './ArcMapTreeItem.utils'

type ArcMapTreeItemProps = {
  /** The filtered value to check if it has a match with the node label. */
  filterInputValue: string
  /** The current tree view node. */
  node: ArcMapTreeViewData
}

/**
 * The tree item component for the
 * ArcMap tree view.
 */
const ArcMapTreeItem = (props: ArcMapTreeItemProps) => {
  const { filterInputValue, node } = props

  const aggregateId =
    node.type === ArcMapTreeNodeType.AGGREGATE ? node.id : node.aggregateId

  return (
    <TreeItem
      data-aggregate={aggregateId}
      data-label={node.label}
      data-type={node.type}
      key={node.id}
      label={
        <div className="flex items-center gap-1">
          {getNodeIcon(node.type)}
          <span className="gap-0">
            {highlightFilteredValue(node.label, filterInputValue)}
          </span>
        </div>
      }
      nodeId={node.id}
      size="small"
    >
      {node.children?.map((child) => (
        <ArcMapTreeItem
          filterInputValue={filterInputValue}
          key={child.id}
          node={child}
        />
      ))}
    </TreeItem>
  )
}

export default ArcMapTreeItem
