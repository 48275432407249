import { TreeItem } from '~/components/treeView/TreeView.styles'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { TreeItemLabel, type TreeItemLabelProps } from './TreeItemLabel'

type RenderTreeFilePathProps = Partial<TreeItemLabelProps> & {
  /** Handler for context menu event. */
  handleContextMenu?: (event: EventFor<'li', 'onContextMenu'>) => void
}

/**
 * Renders the file path `tree items`.
 */
export const RenderTreeFilePath = (props: RenderTreeFilePathProps) => {
  const { domainDictionaryParams, filteredValue, handleContextMenu, node } =
    props
  const { children, id, name } = node || {}

  return (
    <TreeItem
      data-node-id={id}
      data-node-label={name}
      key={id}
      label={
        <TreeItemLabel
          domainDictionaryParams={domainDictionaryParams as string[]}
          filteredValue={filteredValue as string}
          node={node as FolderOrFileStructure}
        />
      }
      nodeId={id as string}
      onContextMenu={handleContextMenu}
      size="small"
    >
      {children?.map((currNode) => (
        <RenderTreeFilePath
          domainDictionaryParams={domainDictionaryParams}
          filteredValue={filteredValue}
          handleContextMenu={handleContextMenu}
          key={currNode.id}
          node={currNode}
        />
      ))}
    </TreeItem>
  )
}
