import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  ArrowsClockwise,
  LinkSimpleHorizontal,
  MagnifyingGlass,
  PaperPlaneRight,
  Plus,
} from '@phosphor-icons/react'
import type { Node, NodeProps } from '@xyflow/react'
import { useCallback, useEffect, useState, type MouseEvent } from 'react'
import { Controller, useForm, type FieldValues } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import CircleButtonWithOptions, {
  Option,
} from '~/components/CircleButtonWithOptions'
import { useAddTypeProperty } from '~/hooks/api/developer/useAddTypeProperty'
import { ServiceAndEventsActionTypeEnum } from '~/models/enums/developer/serviceMap/ServiceAndEventsActionTypeEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import type { PayloadAddTypeProperty } from '~/models/types/api/developer/PayloadAddTypeProperty'
import type { ServiceAndEventsAction } from '~/models/types/developer/serviceMap/ServiceAndEventsType'
import type { NodeStatement } from '~/services/Process.types'
import { AddTypeButtonGroup } from './AddTypeButtonGroup'

const TOOLTIP_TITLE = 'Add related entity'

type AddTypeProps = NodeProps<Node<NodeStatement<ServiceAndEventsAction[]>>> & {
  /** If true, the rendered button will be and "add icon" button. */
  iconButton?: boolean
  context?: string
}

// Validation schema.
const schema = yup
  .object({
    aggregateId: yup.string(),
    autoGenerateAttributes: yup.bool(),
    family: yup.string(),
    name: yup.string().required('Name is required'),
    relationshipType: yup.string().required('Relationship type is required'),
    typeId: yup.string(),
    onlyCreateType: yup.bool(),
  })
  .required()

/**
 * Add type button and dialog to be
 * render as part of the `ServiceNode` component.
 */
export const AddType = (props: AddTypeProps) => {
  const { data, iconButton, id: parentNodeId } = props

  // This `identity` refers to the "parent" type ID.
  const { aggregateId = '', identity = '' } = data

  // States.
  const [open, setOpen] = useState<boolean>(false)

  // React Router Dom.
  const navigate = useNavigate()

  const params = useParams()
  const { boundedContext = '', organisationId = '', platformId = '' } = params

  // React Hook Form.
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      aggregateId: aggregateId || parentNodeId,
      autoGenerateAttributes: false,
      family: 'Entity',
      name: '',
      relationshipType: 'Single',
      onlyCreateType: false,
      typeId: identity,
    },
  })

  // Hooks.
  const {
    data: addTypeData,
    mutate,
    isPending,
    isSuccess,
    reset: addTypeReset,
  } = useAddTypeProperty()

  // Methods.
  const handleAddTypeClick = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation()
    setOpen(true)
  }

  const handleDialogClose = useCallback(() => {
    setOpen(false)
    resetForm()
    addTypeReset()
  }, [addTypeReset, resetForm])

  const handleFormSubmit = (data: FieldValues) => {
    mutate(data as PayloadAddTypeProperty)
  }

  const options: Option[] = [
    {
      icon: <PaperPlaneRight size={18} />,
      text: 'Add Command',
      handler: (e) => {
        handleAddClick(ServiceAndEventsActionTypeEnum.COMMAND, e)
      },
    },
    {
      icon: <ArrowsClockwise size={18} />,
      text: 'Add Reaction',
      handler: (e) => {
        handleAddClick(ServiceAndEventsActionTypeEnum.REACTION, e)
      },
    },
    {
      icon: <MagnifyingGlass size={18} />,
      text: 'Add Query',
      handler: (e) => {
        handleAddClick(ServiceAndEventsActionTypeEnum.QUERY, e)
      },
    },
    {
      icon: <LinkSimpleHorizontal size={18} />,
      text: 'Add Related Entity',
      handler: handleAddTypeClick,
    },
  ]

  const handleAddClick = (
    urlActionType: string,
    e: MouseEvent<HTMLLIElement>,
  ) => {
    e.stopPropagation()

    const queryString = parentNodeId
      ? `?context=${encodeURIComponent(parentNodeId)}`
      : ''

    navigate(
      `${generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
        aggregateId,
        boundedContext,
        organisationId,
        platformId,
      })}/add-${urlActionType}${queryString}`,
    )
  }

  // Lifecycle.
  useEffect(() => {
    const addedTypeId = addTypeData?.data?.value?.typeId

    if (isSuccess && !!addedTypeId) {
      const autoGenerateAttributes = getValues().autoGenerateAttributes

      if (!autoGenerateAttributes) {
        handleDialogClose()
        navigate(
          `${generatePath(DeveloperRoutesEnum.EDIT_TYPE, {
            aggregateId,
            boundedContext,
            organisationId,
            platformId,
            typeId: addedTypeId,
          })}?type=${addedTypeId}`,
        )
      } else {
        handleDialogClose()
      }
    }
  }, [
    addTypeData,
    aggregateId,
    boundedContext,
    getValues,
    handleDialogClose,
    isSuccess,
    navigate,
    organisationId,
    params,
    platformId,
  ])

  return (
    <>
      {iconButton ? (
        <Tooltip arrow placement="top" title={TOOLTIP_TITLE}>
          <IconButton color="secondary" className="h-[25px] w-[25px] p-2">
            <Plus weight="bold" />
          </IconButton>
        </Tooltip>
      ) : (
        // Consider changing this button CSS position in case of need.
        <CircleButtonWithOptions
          className="absolute -right-[25px] top-[48%] z-30"
          title={TOOLTIP_TITLE}
          options={options}
        />
      )}

      <Dialog onClose={handleDialogClose} open={open}>
        <DialogTitle>Add a child</DialogTitle>

        <DialogContent>
          <form
            className="min-w-[300px]"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="mb-4">
              <h3 className="m-0 text-base">1. Choose Relationship</h3>
              <p className="m-0 mb-1 text-xs">(Single item or Collection)</p>

              <AddTypeButtonGroup
                control={control}
                disabled={isPending}
                error={errors.relationshipType?.message}
                groupClassName="grid-cols-[1fr_1fr]"
                name="relationshipType"
                options={['Single', 'Collection']}
              />
            </div>

            <div className="mb-4">
              <h3 className="m-0 text-base">2. Select Child Type</h3>
              <p className="m-0 mb-1 text-xs">
                (e.g., Entity, Value Object, DTO)
              </p>

              <AddTypeButtonGroup
                control={control}
                disabled={isPending}
                groupClassName="grid-cols-[1fr_1fr_1fr]"
                hasOtherOption
                name="family"
                options={['Entity', 'Value Object', 'DTO', 'DAO', 'ENUM']}
              />
            </div>

            <div className="mb-4">
              <h3 className="m-1 text-base">3. Enter Type Name</h3>

              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    disabled={isPending}
                    error={!!errors.name?.message}
                    helperText={errors.name?.message}
                    inputProps={{ className: 'py-3' }}
                    InputProps={{ className: 'p-0' }}
                    placeholder="Enter name..."
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name="aggregateId"
              render={({ field }) => <input type="hidden" {...field} />}
            />

            <Controller
              control={control}
              name="typeId"
              render={({ field }) => <input type="hidden" {...field} />}
            />

            <div className="flex justify-between gap-4">
              <Controller
                control={control}
                name="autoGenerateAttributes"
                render={({ field }) => (
                  <FormControlLabel
                    className="ml-1"
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        disabled={isPending}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Auto-create"
                    labelPlacement="start"
                  />
                )}
              />

              <LoadingButton
                color="secondary"
                loading={isPending}
                type="submit"
                variant="outlined"
              >
                Add related entity
              </LoadingButton>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}
