import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'
import type { SelectedArcMapNodeInfo } from '~/models/types/components/treeView/SelectedArcMapNodeInfo'

/**
 * Provides the selected node ID and type:
 * - Clears selection when clicking folder nodes - besides aggregate.
 * - Updates selection with node ID and type when clicking leaf nodes
 *      (the exception is the aggregate that can be a folder).
 * @param nodeId The selected node ID.
 * @param nodeLabel The selected node label.
 * @param nodeType The selected node type.
 * @param aggregateId The ID of the aggregate the current node belongs to.
 */
export const getNodeData = (
  nodeId: string,
  nodeLabel: string,
  nodeType: ArcMapTreeNodeType,
  aggregateId: string,
): SelectedArcMapNodeInfo => {
  // Return null data when it is one of the mapped folder nodes.
  if (
    nodeType !== ArcMapTreeNodeType.AGGREGATE &&
    nodeType !== ArcMapTreeNodeType.COMMAND &&
    nodeType !== ArcMapTreeNodeType.REACTION &&
    nodeType !== ArcMapTreeNodeType.QUERY &&
    nodeType !== ArcMapTreeNodeType.TYPE
  ) {
    return { aggregateId: null, id: null, label: null, type: null }
  }

  return {
    aggregateId,
    id: nodeId,
    label: nodeLabel,
    type: nodeType,
  }
}
