import { isEqual } from 'lodash'
import { useEffect, useMemo, useState, type SyntheticEvent } from 'react'
import { CodePreviewBaseProps } from '~/models/types/components/codePreview/CodePreviewBaseProps'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'

export type UseManageTreeViewStatesProps = CodePreviewBaseProps & {
  /** The files tree filtered data. */
  filteredData?: FolderOrFileStructure | FolderOrFileStructure[] | null
  /** Callback handler for file click. */
  onFileClick?: (fileId?: GUID) => void
}

/**
 * Manages the file tree states:
 * - Expanded
 * - Nodes click
 */
export const useManageTreeViewStates = (
  props: UseManageTreeViewStatesProps,
) => {
  const { filesTreeData, filteredData, onFileClick } = props

  // States.
  const [expanded, setExpanded] = useState<string[]>([])
  const [selectedTreeItems, setSelectedTreeItems] = useState<string[]>([])

  // Const.
  const rootNodeId =
    filesTreeData?.[0]?._t === 'folder' ? filesTreeData?.[0].id : ''

  // Indicates if the tree is expanded.
  const isTreeExpanded = useMemo(() => {
    if (
      !Array.isArray(filteredData) ||
      (filteredData as FolderOrFileStructure[]).length === 1
    )
      return !!expanded.length && !isEqual(expanded, [rootNodeId])

    return !!expanded.length
  }, [filteredData, rootNodeId, expanded])

  // Indicates if there is only one folder in the whole tree - no nested folders.
  const hasOnlyOneFolder = getFolderIds(filesTreeData).length === 1

  // Manage tree expanded state.
  useEffect(() => {
    if (filesTreeData?.length) setExpanded(getFolderIds(filesTreeData))
  }, [filesTreeData])

  const handleTreeViewNodeToggle = (_: SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleToggleFoldersClick = () => {
    setExpanded((prevState) => {
      if (prevState.length > 0 && filesTreeData.length === 1)
        [filesTreeData[0]?.id as string]

      if (prevState.length > 0 && filesTreeData.length > 1) return []

      return getFolderIds(filesTreeData)
    })
  }

  // Selected tree item.
  const handleTreeViewNodeToggleClick = (
    _: SyntheticEvent,
    nodeIds: string[],
  ) => {
    setSelectedTreeItems(nodeIds)
    onFileClick?.(nodeIds[0])
  }

  return {
    expanded,
    handleToggleFoldersClick,
    handleTreeViewNodeToggleClick,
    handleTreeViewNodeToggle,
    hasOnlyOneFolder,
    isTreeExpanded,
    selectedTreeItems,
  }
}

const getFolderIds = (data: FolderOrFileStructure[]): string[] => {
  if (!Array.isArray(data)) {
    return []
  }

  return data.reduce((folderIds: string[], item: FolderOrFileStructure) => {
    // Collect current folder ID if it's a folder
    const currentFolderIds = item._t === 'folder' ? [item.id] : []

    // Recursively collect folder IDs from children (if any)
    const childrenFolderIds = item.children ? getFolderIds(item.children) : []

    // Combine all folder IDs
    return [...folderIds, ...currentFolderIds, ...childrenFolderIds]
  }, [])
}
