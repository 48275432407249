/**
 * ArcMap tree view node type.
 */
export enum ArcMapTreeNodeType {
  BOUNDED_CONTEXT = 'BoundedContext',
  FOLDER = 'Folder',
  AGGREGATE = 'Aggregate',
  COMMAND = 'Command',
  REACTION = 'Reaction',
  TYPE = 'Type',
  QUERY = 'Query',
}
