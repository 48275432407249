import {
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { CaretDown } from '@phosphor-icons/react'
import React, { useEffect, useRef, useState, type MouseEvent } from 'react'

const CircularButton = styled(Button)(() => ({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  minWidth: 'unset',
  padding: 0,
  backgroundColor: '#23c0c0',
  color: 'white',
  fontSize: 0,
  lineHeight: 0,
  position: 'relative',
  overflow: 'hidden',
}))

const ExpandIcon = styled(CaretDown)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '60%',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}))

export interface Option {
  icon: React.ReactNode
  text: string
  handler: (e: MouseEvent<HTMLLIElement>) => void
}

interface CircleButtonWithOptionsProps extends ButtonProps {
  options: Option[]
}

const CircleButtonWithOptions: React.FC<CircleButtonWithOptionsProps> = ({
  options,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    const button = buttonRef.current
    if (!button) return

    const handleMouseEnter = () => {
      button.style.transform = 'scale(4)'
      button.style.transition = 'transform 0.3s ease-in-out'
      setIsHovered(true)
    }

    const handleMouseLeave = () => {
      button.style.transform = 'scale(1)'
      setIsHovered(false)
    }

    button.addEventListener('mouseenter', handleMouseEnter)
    button.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      button.removeEventListener('mouseenter', handleMouseEnter)
      button.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionClick = (
    e: MouseEvent<HTMLLIElement>,
    handler: (e: MouseEvent<HTMLLIElement>) => void,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    handler(e)
    handleClose()
  }

  return (
    <>
      <CircularButton
        ref={buttonRef}
        variant="contained"
        onClick={handleClick}
        {...props}
      >
        <ExpandIcon style={{ opacity: isHovered ? 1 : 0 }} />
      </CircularButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              handleOptionClick(e, option.handler)
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CircleButtonWithOptions
