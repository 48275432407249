import SaveIcon from '@mui/icons-material/Save'
import { Button, List, ListItem, Paper, Stack, TextField } from '@mui/material'
import { ArrowCounterClockwise } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Form, useLoaderData, useNavigation, useParams } from 'react-router-dom'
import { platforms } from '~/config'
import { FormFooterStyled } from '~/pages/developer/components/styled/FormFooter'
import { ServiceInfoText } from '~/pages/developer/components/styled/ServiceInfoText'
import { queryDevelopment } from '~/services/Development'
import { DomainByPlatformId } from '~/services/Development.types'

export function DeveloperConfirm() {
  const navigation = useNavigation()
  const isButtonDisabled = navigation.state !== 'idle'
  const params = useParams()
  const initialData = useLoaderData() as Awaited<
    AxiosResponse<DomainByPlatformId | null, unknown>
  >
  const {
    data,
    // isInitialLoading, // etc
  } = useQuery({
    ...queryDevelopment(params.platformId),
    initialData: initialData,
    select: (data) => {
      if (data.data && params.aggregateId) {
        const boundedContext = data.data.boundedContexts.find((context) =>
          context.aggregates.find(
            (aggregate) => aggregate.identity === params.aggregateId,
          ),
        )
        const aggregate = boundedContext?.aggregates.find(
          (aggregate) => aggregate.identity === params.aggregateId,
        )

        const result = {
          domain: boundedContext?.name,
          system: aggregate?.name,
          hosting: data?.data
            ? platforms
                .find((platform) =>
                  platform.services.hostingStrategies.find(
                    (item) => item.identity === aggregate?.host,
                  ),
                )
                ?.services.hostingStrategies.find(
                  (host) => host.identity === aggregate?.host,
                )?.name
            : undefined,
          persistence: data?.data
            ? platforms
                .find((platform) =>
                  platform.services.persistenceStrategies.find(
                    (item) => item.identity === aggregate?.persistence,
                  ),
                )
                ?.services.persistenceStrategies.find(
                  (persistence) =>
                    persistence.identity === aggregate?.persistence,
                )?.name
            : undefined,
          gitRepositoryUrl: aggregate?.gitRepositoryUrl,
        }

        return result
      }

      return
    },
  })

  return (
    <>
      <Stack gap={2}>
        <Paper sx={{ px: 2, py: 1 }}>
          <List sx={{ width: '100%', maxWidth: 360 }}>
            <ListItem>
              <ServiceInfoText
                primary="Bounded Context"
                secondary={data?.domain}
              />
            </ListItem>

            <ListItem>
              <ServiceInfoText primary="Service" secondary={data?.system} />
            </ListItem>

            <ListItem>
              <ServiceInfoText primary="Hosting" secondary={data?.hosting} />
            </ListItem>

            <ListItem>
              <ServiceInfoText
                primary="Persistence"
                secondary={data?.persistence}
              />
            </ListItem>

            <ListItem>
              <ServiceInfoText
                primary="Git URL"
                secondary={data?.gitRepositoryUrl}
              />
            </ListItem>
          </List>
        </Paper>

        <Form method="post" id="form-aggregate-confirm">
          <Stack gap={2} mt={2}>
            <TextField
              label="Git commit message"
              variant="outlined"
              name="commitMessage"
              defaultValue={`Generate infra code for service ${data?.system}`}
              fullWidth
            />

            <TextField
              label="Branch"
              variant="outlined"
              name="branch"
              fullWidth
            />

            <input
              type="hidden"
              value={params.aggregateId}
              name="aggregateId"
            />

            <FormFooterStyled>
              <Button
                color="secondary"
                disabled={isButtonDisabled}
                startIcon={isButtonDisabled ? null : <ArrowCounterClockwise />}
                type="reset"
                variant="outlined"
              >
                Reset
              </Button>

              <Button
                color="secondary"
                disabled={
                  isButtonDisabled ||
                  !data ||
                  (data && Object.values(data).filter((i) => i).length < 5)
                }
                fullWidth
                startIcon={isButtonDisabled ? null : <SaveIcon />}
                sx={{ width: 'fit-content' }}
                type="submit"
                variant="contained"
              >
                {isButtonDisabled
                  ? 'Generating infra code...'
                  : 'Generate infra code'}
              </Button>
            </FormFooterStyled>
          </Stack>
        </Form>
      </Stack>
    </>
  )
}
