import { TimesOneMobiledata } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import {
  ArrowsSplit,
  ClockCounterClockwise,
  Trash,
} from '@phosphor-icons/react'
import { twMerge } from '^/tailwind.config'
import { memo, useState, type MouseEvent } from 'react'
import type { ValueBindingTreeItemProps } from '~/models/types/components/codeGenerationStrategy/ValueBindingTreeItemProps'
import {
  TextSection,
  ValuePropertyBinding,
} from '~/services/GenerationStrategy.types'

export type ValueBindingToolbarProps = Pick<
  ValueBindingTreeItemProps,
  'node'
> & {
  /** The class name applied to the containing node. */
  className?: string
  /** Callback handler for the binding context click. */
  onBindingContextClick?: (event: MouseEvent<HTMLSpanElement>) => void
  /** Callback handler for the open delete dialog click. */
  onOpenDeleteDialogClick?: () => void
  /** Callback handler for the open mapping condition dialog click. */
  onOpenMappingConditionDialogClick?: (
    event: MouseEvent<HTMLSpanElement>,
  ) => void
  /** Callback handler for the open revert dialog click. */
  onOpenRevertDialogClick?: (hasValueChanged?: boolean | string | null) => void
  /** Whether the tooltip should be forced open. */
  forceOpen?: boolean
  /** Whether the form is hovered. */
  isFormHovered?: boolean
}

// Add type check helper
const isNodeTextSection = (
  node: ValuePropertyBinding | TextSection,
): boolean => {
  return node?._t === 'TextSection'
}

/**
 * Toolbar for the `value binding tree item` component.
 */
export const ValueBindingToolbar = memo((props: ValueBindingToolbarProps) => {
  const [isTooltipHovered, setIsTooltipHovered] = useState(false)

  const {
    className,
    forceOpen,
    isFormHovered,
    node,
    onBindingContextClick,
    onOpenDeleteDialogClick,
    onOpenMappingConditionDialogClick,
    onOpenRevertDialogClick,
  } = props

  if (!node) return null

  const hasValueChanged = node?.originalValue !== node?.value
  const isTextSection = isNodeTextSection(node)

  return (
    <div
      className={twMerge(`flex items-center gap-2`, className)}
      onMouseEnter={() => setIsTooltipHovered(true)}
      onMouseLeave={() => setIsTooltipHovered(false)}
    >
      {isTextSection && (
        <span
          className={twMerge(
            'text-weight-light inline-flex cursor-pointer items-center whitespace-nowrap rounded p-1 text-xs shadow',
            node.bindingContext
              ? 'border border-green-200 bg-green-50 text-green-700'
              : 'border border-gray-200 bg-gray-50 text-gray-500',
          )}
          onClick={onBindingContextClick}
        >
          <TimesOneMobiledata
            className={twMerge(
              'ml-0.5 mr-1 h-4 w-4 align-text-bottom',
              node.bindingContext ? 'text-green-600' : 'text-gray-400',
            )}
          />
          {node.bindingContext || ''}
        </span>
      )}

      {isTextSection && (
        <Tooltip
          arrow
          placement="top"
          title={node.mappingCondition || 'No mapping condition'}
        >
          <span
            className={twMerge(
              'text-weight-light flex cursor-pointer items-center rounded p-1 px-1 text-xs shadow',
              forceOpen && !isFormHovered && !isTooltipHovered
                ? 'invisible'
                : 'visible',
              node.mappingCondition
                ? 'border border-blue-200 bg-blue-50 text-blue-700'
                : 'border border-gray-200 bg-gray-50 text-gray-500',
            )}
            onClick={onOpenMappingConditionDialogClick}
          >
            <ArrowsSplit
              className={twMerge(
                '-rotate-90',
                node.mappingCondition ? 'text-blue-600' : 'text-gray-400',
              )}
              size={17}
            />
          </span>
        </Tooltip>
      )}

      <Tooltip
        arrow
        placement="top"
        title={
          hasValueChanged ? (
            <pre className="whitespace-pre-wrap rounded p-1 font-mono text-sm">
              {node?.originalValue || 'No original value'}
            </pre>
          ) : null
        }
      >
        <span
          className={twMerge(
            'text-weight-light flex cursor-pointer items-center rounded p-1 text-xs shadow',
            forceOpen && !isFormHovered && !isTooltipHovered
              ? 'invisible'
              : 'visible',
            hasValueChanged
              ? 'border border-amber-200 bg-amber-50 text-amber-700'
              : 'border border-gray-200 bg-gray-50 text-gray-500',
          )}
          onClick={() => onOpenRevertDialogClick?.(hasValueChanged)}
        >
          <ClockCounterClockwise
            className={twMerge(
              hasValueChanged ? 'text-amber-600' : 'text-gray-400',
            )}
            size={17}
          />
        </span>
      </Tooltip>

      {isTextSection && (
        <span
          className={twMerge(
            'text-weight-light flex cursor-pointer items-center rounded border border-gray-200 bg-gray-50 p-1 text-xs text-gray-500 shadow',
            forceOpen && !isFormHovered && !isTooltipHovered
              ? 'invisible'
              : 'visible',
          )}
          onClick={onOpenDeleteDialogClick}
        >
          <Trash className="text-secondary" size={17} />
        </span>
      )}
    </div>
  )
})
