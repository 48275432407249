import {
  Lightning,
  ListMagnifyingGlass,
  Play,
  Trash,
} from '@phosphor-icons/react'
import { useEffect, useRef, useState } from 'react'
import { useMatches } from 'react-router-dom'

export function useModalTitleByLocation() {
  const matches = useMatches()
  const [modalTitle, setModalTitle] = useState('Adding Service')
  const modalTitleIcon = useRef<JSX.Element | null>(null)

  // Changes outlet title according to route
  useEffect(() => {
    const matchPatterns = [
      { pattern: 'add-command', title: 'Command', icon: <Play size={20} /> },
      {
        pattern: 'add-reaction',
        title: 'Reaction',
        icon: <Lightning size={25} />,
      },
      {
        pattern: 'edit-command',
        title: 'Editing Command',
        icon: <Play size={20} />,
      },
      {
        pattern: 'edit-reaction',
        title: 'Editing Reaction',
        icon: <Lightning size={25} />,
      },
      { pattern: 'delete-command', title: 'Deleting Command', icon: null },
      { pattern: 'delete-reaction', title: 'Deleting Reaction', icon: null },
      { pattern: 'confirm', title: 'Initialising Service', icon: null },
      { pattern: 'publish-command', title: 'Publishing Command', icon: null },
      { pattern: 'publish-reaction', title: 'Publishing Reaction', icon: null },
      { pattern: 'edit-details', title: 'Editing Aggregate', icon: null },
      { pattern: 'edit-hosting', title: 'Editing Service Hosting', icon: null },
      {
        pattern: 'edit-persistence',
        title: 'Editing Service Persistence',
        icon: null,
      },
      {
        pattern: 'edit-git-credentials',
        title: 'Editing Service Git Credentials',
        icon: null,
      },
      { pattern: 'publish-all', title: 'Publishing All', icon: null },
      { pattern: 'add-readmodel', title: 'Add query', icon: null },
      { pattern: 'delete-readmodel', title: 'Delete query', icon: null },
      { pattern: 'edit-readmodel', title: 'Editing query', icon: null },
      {
        pattern: 'edit-code-generation-strategies',
        title: 'Edit AI Blueprints',
        icon: null,
      },
      {
        pattern: 'add-query',
        title: 'Add Query',
        icon: <ListMagnifyingGlass size={25} />,
      },
      {
        pattern: 'edit-query',
        title: 'Editing Query',
        icon: <ListMagnifyingGlass size={25} />,
      },
      {
        pattern: 'delete-query',
        title: 'Delete Query',
        icon: <Trash size={20} />,
      },
      { pattern: 'add-type', title: 'Add Type', icon: null },
      { pattern: 'edit-type', title: 'Editing Type', icon: null },
    ]
    const matchedPattern = matchPatterns.find(({ pattern }) =>
      matches.some((match) => match.pathname.includes(pattern)),
    )
    if (matchedPattern) {
      setModalTitle(matchedPattern.title)
      modalTitleIcon.current = matchedPattern.icon
    } else {
      setModalTitle('Adding Service')
      modalTitleIcon.current = null
    }
  }, [matches])

  return { modalTitle, modalTitleIcon: modalTitleIcon.current }
}
