import { alpha, styled } from '@mui/material'
import { TreeItem as MuiTreeView, treeItemClasses } from '@mui/x-tree-view'

type StyledTreeItemProps = {
  size?: 'small' | 'large'
}

export const TreeItem = styled(MuiTreeView)<StyledTreeItemProps>(
  ({ theme, size }) => ({
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 14,
      paddingLeft: 10,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.2)}`,
    },
    [`& .${treeItemClasses.content} .${treeItemClasses.label}`]: {
      fontSize: size === 'small' ? '14px' : size === 'large' ? '18px' : '16px',
    },
  }),
)
