import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Typography } from '@mui/material'
import UploadFilesOverlay, {
  type UploadFilesOverlayProps,
} from '~/components/UploadFilesOverlay/UploadFilesOverlay'
import { useUploadFilesSnackbar } from '~/components/snackbars/UploadFilesSnackbar/useUploadFilesSnackbar'
import { useUploadFiles } from '~/hooks/api/business/useUploadFiles'
import { filesValidator } from '~/utils/uploadFiles/filesValidator'
import { INCLUDE_EXTENSIONS_WITH_IMAGES_AND_DOCS } from '~/utils/uploadFiles/filesValidator.constants'

const PLACEHOLDER_TEXT = (
  <>
    <Typography variant="h3" sx={{ color: 'white', mr: 2, fontWeight: 'bold' }}>
      Drop files to upload
    </Typography>
    <CloudUploadIcon sx={{ color: 'white' }} fontSize="large" />
  </>
)

export type UploadOverlayProps = Pick<UploadFilesOverlayProps, 'children'>

/**
 * Upload files overlay from
 * business process info.
 */
export const UploadOverlay = (props: UploadOverlayProps) => {
  const { children } = props

  const { error, isPending, isProcessingFiles, isSuccess, mutate, reset } =
    useUploadFiles()

  // Snackbar.
  const { handleRejected } = useUploadFilesSnackbar({
    error,
    isPending,
    isProcessingFiles,
    isSuccess,
    reset,
  })

  return (
    <UploadFilesOverlay
      /**
       * The `dropAreaRejectText` and `dropAreaText` needs to be the same
       * because the empty `accept` param makes all file types to be
       * marked as rejected.
       */
      dropAreaActiveText={PLACEHOLDER_TEXT}
      dropAreaRejectText={PLACEHOLDER_TEXT}
      position="fixed"
      useDropzoneProps={{
        onDrop: (acceptedFiles, fileRejections) => {
          if (!!acceptedFiles?.length) mutate(acceptedFiles)

          if (!!fileRejections?.length) handleRejected(fileRejections)
        },
        validator: (files) =>
          filesValidator(files, INCLUDE_EXTENSIONS_WITH_IMAGES_AND_DOCS),
      }}
    >
      {({ getRootProps }) =>
        children({
          getRootProps,
        })
      }
    </UploadFilesOverlay>
  )
}
