import { ArcMapTreeNodeType } from '~/models/enums/components/treeView/ArcMapTreeNodeType'
import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'
import type {
  AggregateCommand,
  AggregateQuery,
  AggregateReaction,
  AggregateType,
  DomainByPlatformId,
} from '~/services/Development.types'

type AggregateResource =
  | AggregateCommand
  | AggregateQuery
  | AggregateReaction
  | AggregateType

/**
 * Transform the aggregates from the
 * bounded contexts data into a tree like data.
 * @param data The domain by platform data.
 */
export const transformToTree = ({
  boundedContexts = [],
}: DomainByPlatformId): Array<ArcMapTreeViewData> => {
  // Transform each bounded context into a tree node.
  return boundedContexts.map((boundedContext) => ({
    id: boundedContext.name,
    label: boundedContext.name,
    type: ArcMapTreeNodeType.BOUNDED_CONTEXT,
    children: (boundedContext.aggregates || []).map((aggregate) =>
      createAggregateNode(aggregate),
    ),
  }))
}

// Create an aggregate node with its resource folders
export const createAggregateNode = (aggregate: {
  identity: string
  name: string
  commands?: AggregateResource[]
  reactions?: AggregateResource[]
  queries?: AggregateResource[]
  types?: AggregateResource[]
}): ArcMapTreeViewData => {
  const folders = [
    aggregate.commands?.length &&
      createFolderNode(
        'commands',
        'Commands',
        aggregate.commands,
        ArcMapTreeNodeType.COMMAND,
        aggregate.identity,
      ),
    aggregate.reactions?.length &&
      createFolderNode(
        'reactions',
        'Reactions',
        aggregate.reactions,
        ArcMapTreeNodeType.REACTION,
        aggregate.identity,
      ),
    aggregate.queries?.length &&
      createFolderNode(
        'queries',
        'Queries',
        aggregate.queries,
        ArcMapTreeNodeType.QUERY,
        aggregate.identity,
      ),
    aggregate.types?.length &&
      createFolderNode(
        'types',
        'Types',
        aggregate.types,
        ArcMapTreeNodeType.TYPE,
        aggregate.identity,
      ),
  ].filter(Boolean) as ArcMapTreeViewData[]

  return {
    id: aggregate.identity,
    label: aggregate.name,
    type: ArcMapTreeNodeType.AGGREGATE,
    children: folders,
  }
}

// Create a resource folder node (commands, reactions, queries or types).
export const createFolderNode = (
  id: string,
  label: string,
  items: AggregateResource[] = [],
  resourceType: ArcMapTreeNodeType,
  aggregateId: string,
): ArcMapTreeViewData | null => {
  if (!items.length) return null

  return {
    id,
    label,
    type: ArcMapTreeNodeType.FOLDER,
    children: items.map((item) =>
      createResourceNode(
        item.identity || '',
        item.name || '',
        resourceType,
        aggregateId,
      ),
    ),
  }
}

// Create a resource node (command, reaction, query or type).
export const createResourceNode = (
  id: string,
  label: string,
  type: ArcMapTreeNodeType,
  aggregateId: string,
): ArcMapTreeViewData => ({
  id,
  label,
  type,
  aggregateId,
})
