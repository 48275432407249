import { CircularProgress } from '@mui/material'
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react'
import { Fragment } from 'react'
import {
  STATEMENT_PARSING_INFO_TYPE,
  type NodeStatement,
} from '~/services/Process.types'
import { nodeWidth } from '../../../utils'
import { DropAreas } from '../DropAreas/DropAreas'
import { SideMenu } from '../SideMenu/SideMenu'
import { step } from '../Step/Step.styles'
import { useStep } from '../Step/useStep'
import { Chip } from '../components/Chip'
import { Helpers } from '../components/Helpers'
import { Content } from './Content'
import { Header } from './Header'
import { useExpandedStepContent } from './useExpandedStepContent'
import { useExpandedStepFields } from './useExpandedStepFields'

export type ExpandedStepProps = NodeProps<Node<NodeStatement>>

export function ExpandedStep(props: ExpandedStepProps) {
  const { data, id } = props

  const {
    hasChildren,
    hasError: hasStatementError,
    isEditable,
    isEditingMode,
    isEditMutationError,
    isEmpty,
    isExpanded,
    isFetching,
    isGeneratingProcess,
    isLocal,
    isSelected,
    isSwapModeEnabled,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    onAddReaction,
    onDropNodeIntoDropAreas,
    parentStatementId,
    type,
  } = data || {}

  // Hooks.
  const {
    dragRef,
    handleShouldNotDrag,
    isAnyDragging,
    isCommand,
    isNodeDragging,
    isNodeLayerDragging,
    isReaction,
    isQuery,
  } = useStep({ id, isLocal, type })

  const { control, handleFormSubmit, handleSubmit, watch } =
    useExpandedStepContent({ data: props })

  const { actorFieldRef, typeFieldButtonRef } = useExpandedStepFields({
    data: props,
    watch,
  })

  // Constants.
  const hasError = hasStatementError || isEditMutationError

  const shouldShowChip =
    isEditable ||
    (!isEmpty &&
      !hasError &&
      type === STATEMENT_PARSING_INFO_TYPE.Command &&
      !isFetching)

  const shouldShowSideMenu =
    !isEmpty &&
    isSelected &&
    !isGeneratingProcess &&
    !isNodeDragging &&
    !isNodeLayerDragging

  const statePriority = ['isFetching', 'isEmpty', 'hasError']

  const status =
    statePriority.reduce((acc, state) => {
      if (!acc && data[state as keyof NodeStatement]) return state
      return acc
    }, '') || 'default'

  // Editable fields related resources.
  const Wrapper = isEditable ? 'form' : Fragment
  const wrapperProps = isEditable
    ? { onSubmit: handleSubmit(handleFormSubmit) }
    : {}

  return (
    <Wrapper {...wrapperProps}>
      {data.isSelected ? data.statementInput : <></>}

      <div
        className={`relative rounded-lg border-2 border-dashed bg-white p-4 ${
          isCommand
            ? 'border-[#2ca6a4]'
            : isReaction
              ? 'border-[#23c0c0]'
              : 'border-[#0a2342]'
        } ${isSelected ? 'shadow-lg' : ''} ${step({
          isCommand,
          isQuery,
          isEditingMode,
          isNodeLayerDragging,
          isReaction,
          isSelected,
          isSwapModeEnabled,
          status: status as 'default' | 'hasError' | 'isEmpty' | 'isFetching',
        })}`}
        data-statement-id={id}
        ref={dragRef}
        style={{
          minWidth: nodeWidth({ isExpanded }),
          maxWidth: nodeWidth({ isExpanded }),
        }}
      >
        {isFetching && (
          <div className="absolute inset-0 z-50 flex items-center justify-center rounded-lg bg-white bg-opacity-20 backdrop-blur-sm">
            <CircularProgress color="secondary" />
          </div>
        )}

        <Helpers
          hasChildren={hasChildren}
          isLocal={isLocal}
          isNodeDragging={isNodeDragging}
          isNodeLayerDragging={isNodeLayerDragging}
          isSelected={isSelected}
          onAddLocalChild={onAddLocalChild}
          onAddLocalNextSibling={onAddLocalNextSibling}
          onAddLocalParent={onAddLocalParent}
          onAddLocalPreviousSibling={onAddLocalPreviousSibling}
          parentStatementId={parentStatementId}
          statementId={id}
        />

        <DropAreas
          id={id}
          isAnyDragging={isAnyDragging}
          isLocal={isLocal}
          isNodeDragging={isNodeDragging}
          isNodeLayerDragging={isNodeLayerDragging}
          onDropNodeIntoDropAreas={onDropNodeIntoDropAreas}
        />

        {shouldShowChip && (
          <Chip
            actorFieldRef={actorFieldRef}
            control={control}
            data={props}
            onSubmit={handleSubmit(handleFormSubmit)}
            watch={watch}
          />
        )}

        <Header
          control={control}
          data={props}
          onSubmit={handleSubmit(handleFormSubmit)}
          typeFieldButtonRef={typeFieldButtonRef}
          watch={watch}
        />

        {isExpanded && !isQuery && (
          <Content
            control={control}
            data={props}
            onRaisedDomainEventMouseDown={handleShouldNotDrag}
            onAddReaction={onAddReaction}
            onSubmit={handleSubmit(handleFormSubmit)}
          />
        )}

        {shouldShowSideMenu && <SideMenu {...props} />}

        <Handle
          className="absolute left-0 top-0 h-[100%] w-[100%] transform-none border-0 opacity-0"
          isConnectableStart={false}
          position={Position.Left}
          type="target"
        />

        <Handle
          className="invisible left-[0.5px]"
          id={id}
          position={Position.Left}
          type="target"
        />

        <Handle
          className="invisible right-[2px]"
          id={id}
          position={Position.Right}
          type="source"
        />
      </div>
    </Wrapper>
  )
}
