import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { filterNode } from './filterNode'

/**
 * Filters the data directory items against the provided name.
 * @param data The file/folder data.
 * @param name The name to filter the data.
 */
export const filterDataDirectoryItemsByName = (
  data: FolderOrFileStructure | FolderOrFileStructure[],
  name: string = '',
): FolderOrFileStructure | FolderOrFileStructure[] | null => {
  if (!name) return data

  // Base case: if the node doesn't exist, return null.
  if (!data) return Array.isArray(data) ? [] : null

  // Handle both single item and array cases.
  if (Array.isArray(data)) {
    return data
      .map((item) => filterNode(item, name))
      .filter(Boolean) as FolderOrFileStructure[]
  }

  return filterNode(data, name)
}
