/**
 * Gets the worker module.
 * @param moduleUrl The module to be imported.
 */
const getWorkerModule = (moduleUrl: string) =>
  new Worker(new URL(moduleUrl, import.meta.url), {
    type: 'module',
  })

/**
 * Initialize the import module:
 * imports the proper monaco worker.
 */
export const initializeMonacoWorkers = () => {
  if (typeof window !== 'undefined' && window.MonacoEnvironment === undefined) {
    window.MonacoEnvironment = {
      getWorker(_, label) {
        switch (label) {
          case 'json':
            return getWorkerModule('/monaco-editor/json.worker.js')
          case 'css':
          case 'scss':
          case 'less':
            return getWorkerModule('/monaco-editor/css.worker.js')
          case 'html':
          case 'handlebars':
          case 'razor':
            return getWorkerModule('/monaco-editor/html.worker.js')
          case 'typescript':
          case 'javascript':
            return getWorkerModule('/monaco-editor/ts.worker.js')
          default:
            return getWorkerModule('/monaco-editor/editor.worker.js')
        }
      },
    }
  }
}

/**
 * Map file extensions to Monaco language IDs.
 * @param lang The code language.
 */
export const normalizeLangForMonaco = (lang: string): string => {
  switch (lang.toLowerCase()) {
    case 'typescript':
    case 'tsx':
      return 'typescript'
    case 'javascript':
    case 'jsx':
      return 'javascript'
    case 'json':
      return 'json'
    case 'css':
      return 'css'
    case 'html':
      return 'html'
    case 'csharp':
    case 'cs':
      return 'csharp'
    case 'python':
    case 'py':
      return 'python'
    default:
      return lang.toLowerCase()
  }
}
