import type { ArcMapTreeViewData } from '~/models/types/components/treeView/ArcMapTreeViewData'

/**
 * Finds the path of parent IDs for a given target ID in a tree structure.
 * @param data The ArcMap data.
 * @param targetId The ID of the node to find the path for.
 */
export const getParentNodePath = (
  data: ArcMapTreeViewData[],
  targetId: GUID,
) => {
  const search = (
    node: ArcMapTreeViewData,
    path: string[] = [],
  ): string[] | null => {
    // Check if current node is the target one.
    if (node.id === targetId) return path

    // If the node has children, search them.
    if (node.children) {
      for (const child of node.children) {
        // Create a new path including the current node's ID.
        const newPath = [...path, node.id]
        const result = search(child, newPath)
        if (result) return result
      }
    }

    return null
  }

  // Search through each top-level node.
  for (const node of data) {
    const result = search(node)
    if (result) return result
  }

  return null
}
