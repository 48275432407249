import { Panel } from 'react-resizable-panels'
import type { FileDetails } from '~/services/GenerationStrategy.types'
import { CodeEditor } from '../CodeEditor/CodeEditor'

export type FileDetailsPanelProps = {
  /** The file content. */
  file?: FileDetails
  /**
   * A placeholder message to be displayed
   * when is no code to preview, eg:
   * no file selected, something else missing to preview etc.
   */
  placeholderMsg?: string | JSX.Element
}

/**
 * The file details panel from
 * AI Blueprint Preview page.
 */
export const FileDetailsPanel = (props: FileDetailsPanelProps) => {
  const { file, placeholderMsg = <p>Select a file to preview.</p> } = props
  const { content, language } = file || {}

  return (
    <Panel className="flex" order={2}>
      <div className="flex-grow overflow-hidden bg-white px-4 shadow-md">
        {!content ? (
          <div className="flex h-full items-center justify-center">
            {placeholderMsg}
          </div>
        ) : (
          <CodeEditor content={content} disabled language={language} />
        )}
      </div>
    </Panel>
  )
}
