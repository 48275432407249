import { TreeView } from '@mui/x-tree-view'
import { CaretDown, CaretRight } from '@phosphor-icons/react'
import { Panel } from 'react-resizable-panels'
import TreeViewToolbar from '~/components/treeView/TreeViewToolbar/TreeViewToolbar'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { RenderTreeFilePath } from '../../../routes/configuration/code-generation-strategies/edit/components/RenderTreeFilePath/RenderTreeFilePath'
import { useFilterFileFolderByName } from '../../../routes/configuration/code-generation-strategies/hooks/useFilterFileFolderByName'
import {
  useManageTreeViewStates,
  type UseManageTreeViewStatesProps,
} from './useManageTreeViewStates'

export type FilesTreePanelProps = UseManageTreeViewStatesProps & {
  /** Any content to be rendered before the files tree. */
  preContent?: JSX.Element
}

/**
 * The files tree panel from
 * AI Blueprint Preview page.
 */
export const FilesTreePanel = (props: FilesTreePanelProps) => {
  const { filesTreeData, onFileClick, preContent } = props

  const {
    filteredData,
    filterInputValue,
    handleClearFilterInputClick,
    handleFilterInputChange,
  } = useFilterFileFolderByName({
    fileFolderData: filesTreeData as FolderOrFileStructure[],
  })

  const {
    expanded,
    handleToggleFoldersClick,
    handleTreeViewNodeToggleClick,
    handleTreeViewNodeToggle,
    hasOnlyOneFolder,
    isTreeExpanded,
    selectedTreeItems,
  } = useManageTreeViewStates({ filesTreeData, filteredData, onFileClick })

  if ((!filesTreeData || !filesTreeData.length) && !preContent) return

  return (
    <Panel className="flex flex-col" defaultSize={30} order={1}>
      {preContent}

      {!!filesTreeData && !!filesTreeData.length && (
        <div className="flex-1 overflow-auto rounded-lg bg-slate-100 p-4 shadow-md">
          <TreeViewToolbar
            filterInputValue={filterInputValue}
            isTreeExpanded={isTreeExpanded}
            noFileUpload
            noToggle={!filteredData || hasOnlyOneFolder}
            onClearFilterInputClick={handleClearFilterInputClick}
            onFilterInputChange={handleFilterInputChange}
            onToggleClick={handleToggleFoldersClick}
          />

          {!(filteredData as FolderOrFileStructure[])?.length ? (
            <p>No results found</p>
          ) : (
            <TreeView
              aria-name="repository tree view"
              className="rounded border border-slate-200 p-2"
              defaultCollapseIcon={<CaretDown />}
              defaultExpandIcon={<CaretRight />}
              expanded={expanded}
              multiSelect
              onNodeSelect={handleTreeViewNodeToggleClick}
              onNodeToggle={handleTreeViewNodeToggle}
              selected={selectedTreeItems}
            >
              {(filteredData as FolderOrFileStructure[])?.map((item) => (
                <RenderTreeFilePath
                  filteredValue={filterInputValue}
                  key={item.id}
                  node={item as FolderOrFileStructure}
                />
              ))}
            </TreeView>
          )}
        </div>
      )}
    </Panel>
  )
}
