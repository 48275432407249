import { LoadingButton } from '@mui/lab'
import { Tooltip } from '@mui/material'
import {
  ListBullets,
  RocketLaunch as RocketLaunchIcon,
} from '@phosphor-icons/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { Node, NodeProps } from '@xyflow/react'
import { twMerge } from '^/tailwind.config'
import type { AxiosResponse } from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useMarkAStepAsFeature } from '~/hooks/api/business/useMarkAStepAsFeature'
import { useMarkedAsFeature } from '~/hooks/business/useMarkedAsFeature'
import { useGetSubscriptionPlanCapabilities } from '~/hooks/useGetSubscriptionPlanCapabilities'
import { useLinkEditCommandOrReaction } from '~/hooks/useLinkEditCommandOrReaction'
import type { BusinessChildrenParams } from '~/routes/business/routes.types'
import { queryDevelopment } from '~/services/Development'
import type { DomainByPlatformId } from '~/services/Development.types'
import {
  STATEMENT_PARSING_INFO_TYPE,
  STATEMENT_STATE_NAME,
  type NodeStatement,
} from '~/services/Process.types'
import { contextButton } from '~/styles/components'
import {
  commandsByAggregate,
  queriesByAggregate,
  reactionsByAggregate,
} from '~/utils/transform'
import {
  getEditTooltipIcon,
  getEditTooltipText,
  getMarkAsFeatureIcon,
  getMarkAsFeatureTooltipText,
  iconProps,
} from './SideMenu.utils'

const DEFAULT_BUTTON_CLASS_NAMES =
  'relative inline-flex min-w-[auto] h-8 w-8 items-center justify-center text-sm'

const REFETCH_RETRIES = 10

const REFETCH_PLATFORM_TIME = 2000

type SideMenuProps = NodeProps<Node<NodeStatement>>

/**
 * Check if the statement is a feature,
 * on the existence of it as a bounded context.
 */
const checkForFeature = (
  response: AxiosResponse<DomainByPlatformId> | undefined,
  type?: string,
  id?: string,
) => {
  if (!response?.data) return false

  // Check for Commands
  if (type === STATEMENT_PARSING_INFO_TYPE.Command) {
    const commands = commandsByAggregate(response.data)
    const selectedCommand = commands?.find((command) => command.identity === id)
    if (selectedCommand) return true
  }

  // Check for Reactions
  if (type === STATEMENT_PARSING_INFO_TYPE.Reaction) {
    const reactions = reactionsByAggregate(response.data)
    const selectedReaction = reactions?.find(
      (reaction) => reaction.identity === id,
    )
    if (selectedReaction) return true
  }

  // Check for Queries.
  if (type === STATEMENT_PARSING_INFO_TYPE.Query) {
    const queries = queriesByAggregate(response.data)
    const selectedQuery = queries?.find((query) => query.identity === id)
    if (selectedQuery) return true
  }

  return false
}

/**
 * Display additional options for a map step.
 */
export function SideMenu(props: SideMenuProps) {
  const { data, id } = props

  // Constants.
  const { isEditable, isFetching, setIsEditingMode } = data

  // Refetch retry counter ref.
  const retryCountRef = useRef(0)

  // States.
  // State to indicate the command/event is processing on background.
  const [isProcessing, setIsProcessing] = useState<boolean>()
  // A state to track the actual completion of the "mark as feature" process.
  const [isMarkAsFeatureCompleted, setIsMarkAsFeatureCompleted] =
    useState<boolean>(false)
  // State to track if the "mark as feature" started.
  const [hasStartedMarkingAsFeature, setHasStartedMarkingAsFeature] =
    useState<boolean>(false)

  // React Router Dom.
  const params = useParams<BusinessChildrenParams>()

  // React Query.
  const queryClient = useQueryClient()

  // Hooks.
  const {
    error: markAStepAsFeatureError,
    isPending: isMarkAStepAsFeaturePending,
    mutate: markAStepAsFeatureMutate,
  } = useMarkAStepAsFeature()

  const linkEditCommandOrReaction = useLinkEditCommandOrReaction(data.type, id)

  const { codeGeneration: isCodeGenerationAllowed } =
    useGetSubscriptionPlanCapabilities()

  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(params.platformId),
    refetchInterval:
      hasStartedMarkingAsFeature && data.isFeature
        ? REFETCH_PLATFORM_TIME
        : false,
    enabled: hasStartedMarkingAsFeature && data.isFeature,
    retry: REFETCH_RETRIES,
  })

  // Handle "mark as feature" checking.
  useEffect(() => {
    if (!hasStartedMarkingAsFeature) return

    const featureFound = checkForFeature(dataDevelopment, data.type, id)

    if (featureFound) {
      setIsMarkAsFeatureCompleted(true)
      setIsProcessing(false)
      setHasStartedMarkingAsFeature(false)
      retryCountRef.current = 0
    } else if (retryCountRef.current >= REFETCH_RETRIES) {
      // Max retries reached: cleanup.
      setIsProcessing(false)
      setHasStartedMarkingAsFeature(false)
      setIsMarkAsFeatureCompleted(false)
      retryCountRef.current = 0
    } else {
      retryCountRef.current += 1
    }
  }, [dataDevelopment, data.type, id, hasStartedMarkingAsFeature])

  const {
    markedAsFeature: baseMarkedAsFeature,
    selectedCommand,
    selectedQuery,
    selectedReaction,
  } = useMarkedAsFeature({
    aggregateData: dataDevelopment?.data,
    isFeature: data.isFeature,
    platformId: params.platformId || '',
    statementId: id,
    statementType: data.type,
  })

  // Memoized resources.
  // Processing `mark as feature`.
  const isProcessingMarkAsFeature = useMemo(() => {
    // Only show loading if actually started the "mark as feature" process.
    if (!hasStartedMarkingAsFeature) return false

    return (
      isProcessing ||
      isMarkAStepAsFeaturePending ||
      (data.isFeature && !isMarkAsFeatureCompleted)
    )
  }, [
    hasStartedMarkingAsFeature,
    isProcessing,
    isMarkAStepAsFeaturePending,
    data.isFeature,
    isMarkAsFeatureCompleted,
  ])

  // Marked as feature should also account for existing command/reaction.
  const markedAsFeature = useMemo(
    () => baseMarkedAsFeature || isMarkAsFeatureCompleted,
    [baseMarkedAsFeature, isMarkAsFeatureCompleted],
  )

  const selectedAggregateId = useMemo(() => {
    if (selectedCommand) return selectedCommand.aggregateId

    if (selectedReaction) return selectedReaction.aggregateId

    if (selectedQuery) return selectedQuery.aggregateId

    return
  }, [selectedCommand, selectedQuery, selectedReaction])

  const selectedEvent = useMemo(() => {
    if (selectedCommand) return selectedCommand

    if (selectedReaction) return selectedReaction

    if (selectedQuery) return selectedQuery

    return
  }, [selectedCommand, selectedQuery, selectedReaction])

  const handleLinkToPublish = useMemo(
    () =>
      `${selectedAggregateId}/generate-code/${data.type?.toLocaleLowerCase()}/${selectedEvent?.identity}`,
    [data.type, selectedAggregateId, selectedEvent?.identity],
  )

  // Functions.
  function preFetchDevelopment() {
    queryClient.refetchQueries(queryDevelopment(params.platformId || ''))
  }

  // Handler for mark as feature click.
  const handleMarkAsFeatureClick = () => {
    // Allow mutation in case it is not marked as feature
    // OR, there is a mutation error - to allow the user to retry the mutation.
    if (!markedAsFeature || !!markAStepAsFeatureError) {
      markAStepAsFeatureMutate(id)
      setIsProcessing(true)
      setHasStartedMarkingAsFeature(true)
      setIsMarkAsFeatureCompleted(false)
      retryCountRef.current = 0
    }
  }

  // Reset states when unmounting or when there's an error.
  useEffect(() => {
    if (markAStepAsFeatureError) {
      setIsProcessing(false)
      setHasStartedMarkingAsFeature(false)
      setIsMarkAsFeatureCompleted(false)
      retryCountRef.current = 0
    }

    return () => {
      setIsProcessing(false)
      setHasStartedMarkingAsFeature(false)
      setIsMarkAsFeatureCompleted(false)
      retryCountRef.current = 0
    }
  }, [markAStepAsFeatureError])

  return (
    <section className="absolute -top-[70px] flex w-full">
      <div className="flex w-full flex-row justify-center gap-2">
        {FEATURE_TOGGLE.BUSINESS.APPROVE_FOR_DEVELOPMENT && !data.hasError && (
          <Tooltip
            arrow
            placement="top"
            title={getMarkAsFeatureTooltipText({
              error: markAStepAsFeatureError,
              isProcessing: isProcessingMarkAsFeature,
              markedAsFeature,
            })}
          >
            <>
              <LoadingButton
                className={twMerge(
                  contextButton,
                  DEFAULT_BUTTON_CLASS_NAMES,
                  'cursor-auto p-1 hover:bg-background-paper',
                  (!markedAsFeature || !!markAStepAsFeatureError) &&
                    'flex cursor-pointer gap-1 text-xs hover:bg-background-business',
                )}
                color="secondary"
                loading={isProcessingMarkAsFeature}
                onClick={handleMarkAsFeatureClick}
              >
                {getMarkAsFeatureIcon({
                  error: markAStepAsFeatureError,
                  isProcessing: isProcessingMarkAsFeature,
                  markedAsFeature,
                })}
              </LoadingButton>
            </>
          </Tooltip>
        )}

        {!!selectedEvent && markedAsFeature && isCodeGenerationAllowed && (
          <>
            {linkEditCommandOrReaction ? (
              <Tooltip title="Define inputs and outputs" arrow placement="top">
                <Link
                  to={linkEditCommandOrReaction}
                  className={twMerge(
                    contextButton,
                    DEFAULT_BUTTON_CLASS_NAMES,
                    'py-0',
                  )}
                  color="secondary"
                  onMouseEnter={preFetchDevelopment}
                >
                  <ListBullets {...iconProps} />
                </Link>
              </Tooltip>
            ) : null}

            {!!FEATURE_TOGGLE.BUSINESS.PUBLISH && (
              <Tooltip title="Generate code" arrow placement="top">
                <Link
                  to={handleLinkToPublish}
                  className={twMerge(
                    contextButton,
                    DEFAULT_BUTTON_CLASS_NAMES,
                    'py-0',
                  )}
                  color="secondary"
                >
                  {selectedEvent?.state?.name ===
                  STATEMENT_STATE_NAME.Unpublished ? (
                    <RocketLaunchIcon {...iconProps} />
                  ) : (
                    <RocketLaunchIcon {...iconProps} weight="fill" />
                  )}
                </Link>
              </Tooltip>
            )}
          </>
        )}

        {!markedAsFeature && (
          <Tooltip
            arrow
            placement="top"
            title={getEditTooltipText({ isEditable, isFetching })}
          >
            <LoadingButton
              className={twMerge(
                contextButton,
                DEFAULT_BUTTON_CLASS_NAMES,
                'cursor-auto p-1 hover:bg-background-paper',
                !isFetching && 'cursor-pointer hover:bg-background-business',
              )}
              color="secondary"
              loading={isFetching}
              onClick={() => setIsEditingMode?.((prevState) => !prevState)}
            >
              {getEditTooltipIcon({ isEditable })}
            </LoadingButton>
          </Tooltip>
        )}
      </div>
    </section>
  )
}
