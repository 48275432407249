import { Button, ButtonGroup, Typography, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Handle, Position } from '@xyflow/react'
import tailwindConfig, { twMerge } from '^/tailwind.config'
import { MutableRefObject, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { Link, generatePath, useParams } from 'react-router-dom'
import resolveConfig from 'tailwindcss/resolveConfig'
import { Column } from '~/components/Column'
import { ConditionalWrapper } from '~/components/ConditionalWrapper'
import { StatementEditionBoxFormEnum as FormEnum } from '~/models/enums/forms/StatementEditionBoxFormEnum'
import { DeveloperRoutesEnum } from '~/models/enums/routes/DeveloperRoutesEnum'
import type { DeveloperChildrenParams } from '~/routes/developer/routes.types'
import { queryDevelopment } from '~/services/Development'
import { queryRaisedDomainEventsByOrganisationId } from '~/services/Process'
import { STATEMENT_PARSING_INFO_TYPE } from '~/services/Process.types'
import { designSystemTokens } from '~/styles/designSystemTokens'
import {
  commandsByAggregate,
  queriesByAggregate,
  reactionsByAggregate,
} from '~/utils/transform'
import type { UpdateStatementBaseProps } from '../Step/Step.types'
import { useStep } from '../Step/useStep'
import { StepHeaderTitle } from '../StepHeaderTitle/StepHeaderTitle'
import { ControlledAutocomplete } from './ControlledAutocomplete'
import { ControlledTextField } from './ControlledTextField'

const fullConfig = resolveConfig(tailwindConfig)

type HeaderProps = UpdateStatementBaseProps & {
  /** The ref for the type button field. */
  typeFieldButtonRef?: MutableRefObject<HTMLButtonElement | null>
}

export function Header(props: HeaderProps) {
  const {
    control,
    data: { data },
    onSubmit,
    typeFieldButtonRef,
    watch,
  } = props

  const {
    action,
    aggregate,
    boundedContext,
    hasError: hasStatementError,
    isEditable,
    isEditMutationError,
    isEmpty,
    isExpanded,
    isFetching,
    isSelected,
    retryEditMutation,
    type: statementType,
  } = data || {}

  const formType = watch?.('type')

  const type = formType || statementType

  // Hooks.
  const theme = useTheme()

  const params = useParams<DeveloperChildrenParams & { commandId: GUID }>()
  const { organisationId, platformId } = params

  const { data: dataDevelopment } = useQuery({
    ...queryDevelopment(platformId),
  })

  const { data: allDomainEvents = [] } = useQuery({
    ...queryRaisedDomainEventsByOrganisationId(organisationId ?? ''),
    enabled: !!organisationId && isEditable,
  })

  const { isCommand, isReaction, isQuery } = useStep({ type })

  // Constants.
  const hasError = hasStatementError || isEditMutationError

  const headerData: {
    backgroundColor: string
    borderBottomColor: string
    titleColor: string
    titleText: string | JSX.Element
  } = {
    backgroundColor: 'transparent',
    borderBottomColor: 'rgba(0,0,0,.15)',
    titleColor: isCommand
      ? fullConfig.theme.colors.command.DEFAULT
      : isReaction
        ? fullConfig.theme.colors.reaction.DEFAULT
        : isQuery
          ? fullConfig.theme.colors.query.DEFAULT
          : designSystemTokens.colors.secondary.DEFAULT,
    titleText: isEditable ? (
      <ControlledTextField
        className="w-full"
        control={control}
        inputProps={{
          className: 'p-2 text-sm',
        }}
        label="Action"
        name={FormEnum.ACTION}
        onBlur={onSubmit}
      />
    ) : (
      <>{action || ''}</>
    ),
    ...(hasError && {
      backgroundColor: '#FFE8E8',
      borderBottomColor: '#D81B60',
      titleColor: '#D62121',
      titleText: 'Unable to parse process step',
    }),
    ...(isEmpty && {
      backgroundColor: isSelected
        ? designSystemTokens.colors.secondary.DEFAULT
        : 'transparent',
      borderBottomColor: designSystemTokens.colors.secondary.DEFAULT,
      titleColor: isSelected
        ? theme.palette.background.panel
        : designSystemTokens.colors.secondary.DEFAULT,
      titleText: '+ Create a new step',
    }),
  }

  const aggregateId = useMemo(() => {
    if (dataDevelopment?.data) {
      if (type === STATEMENT_PARSING_INFO_TYPE.Reaction) {
        const reactions = reactionsByAggregate(dataDevelopment.data)

        if (reactions) {
          return (
            reactions.find((reaction) => reaction.identity === props.data.id)
              ?.aggregateId || ''
          )
        }
      }

      if (type === STATEMENT_PARSING_INFO_TYPE.Command) {
        const commands = commandsByAggregate(dataDevelopment.data)

        if (commands) {
          return (
            commands.find((command) => command.identity === props.data.id)
              ?.aggregateId || ''
          )
        }
      }

      if (type === STATEMENT_PARSING_INFO_TYPE.Query) {
        const queries = queriesByAggregate(dataDevelopment.data)

        if (queries) {
          return (
            queries.find((query) => query.identity === props.data.id)
              ?.aggregateId || ''
          )
        }
      }
    }

    return ''
  }, [type, dataDevelopment?.data, props.data.id])

  return isEmpty ? (
    <></>
  ) : (
    <Column
      className={twMerge(
        'relative items-center justify-center border-x-0 border-b border-t-0 border-solid border-b-black/10 px-2 pb-2 pt-2',
        isCommand && 'border-b-command',
        isReaction && 'border-b-reaction',
        isQuery && 'border-none',
        isEmpty ? 'bg-secondary' : '',
        type === STATEMENT_PARSING_INFO_TYPE.Command && 'pt-4',
      )}
    >
      {isEditable && (
        <Controller
          control={control}
          name={FormEnum.TYPE}
          render={({ field }) => (
            <ButtonGroup
              aria-label="statement type"
              className="mb-3 mt-2 w-full shadow-none"
              size="small"
              variant="contained"
            >
              <Button
                className="flex-1 pb-0 pt-0"
                color="secondary"
                onClick={() => field.onChange('Command')}
                ref={typeFieldButtonRef}
                variant={field.value === 'Command' ? 'contained' : 'outlined'}
              >
                Command
              </Button>

              <Button
                className="flex-1 pb-0 pt-0"
                color="secondary"
                onClick={() => field.onChange('Query')}
                ref={typeFieldButtonRef}
                variant={field.value === 'Query' ? 'contained' : 'outlined'}
              >
                Query
              </Button>

              <Button
                className="flex-1 pb-0 pt-0"
                color="secondary"
                onClick={() => field.onChange('Reaction')}
                variant={field.value === 'Reaction' ? 'contained' : 'outlined'}
              >
                Reaction
              </Button>
            </ButtonGroup>
          )}
        />
      )}

      {isEditable && type === 'Reaction' && (
        <ControlledAutocomplete
          className="mb-2 w-full"
          control={control}
          getOptionLabel={(option) => `${option.aggregate} > ${option.name}`}
          isOptionEqualToValue={(option, currentValue) =>
            option.id === currentValue.id
          }
          inputProps={{
            className: 'p-2 text-sm',
          }}
          label={'Reacting to domain event'}
          name={FormEnum.REACTING_TO_DOMAIN_EVENT}
          onBlur={onSubmit}
          options={allDomainEvents.sort((a, b) =>
            a?.boundedContext !== b?.boundedContext
              ? a?.boundedContext?.localeCompare(b?.boundedContext)
              : a?.name?.localeCompare(b?.name),
          )}
        />
      )}

      {/*
       * Do not render only when there is no `aggregate` and `is not editable` -
       * When it is editable all the fields should be rendered.
       */}
      {!(!data.aggregate && !isEditable) && (
        <ConditionalWrapper
          condition={!!aggregateId}
          wrapper={(children) => (
            <Link
              className="no-underline"
              to={generatePath(DeveloperRoutesEnum.DEVELOPER_AGGREGATE, {
                aggregateId,
                boundedContext: boundedContext?.toLowerCase() || '',
                organisationId: organisationId || '',
                platformId: platformId || '',
              })}
            >
              {children}
            </Link>
          )}
        >
          <>
            {isEditable ? (
              <ControlledTextField
                className="mb-2 w-full"
                control={control}
                inputProps={{
                  className: 'p-2 text-sm',
                }}
                label="Bounded Context"
                name={FormEnum.BOUNDED_CONTEXT}
                onBlur={onSubmit}
              />
            ) : (
              <Typography
                sx={{
                  color: headerData.titleColor,
                }}
                className={twMerge(
                  'p-1 text-center text-sm leading-[0.7] text-secondary',
                  !!aggregateId && 'cursor-pointer hover:underline',
                  isCommand && 'text-command',
                  isReaction && 'text-reaction',
                )}
              >
                {`${boundedContext} >`}
              </Typography>
            )}

            {isEditable ? (
              <ControlledTextField
                className="mb-2 w-full"
                control={control}
                inputProps={{
                  className: 'p-2 text-sm',
                }}
                label="Aggregate"
                name={FormEnum.AGGREGATE}
                onBlur={onSubmit}
              />
            ) : (
              <Typography
                sx={{
                  color: headerData.titleColor,
                }}
                className={twMerge(
                  'p-1 text-center text-sm leading-[0.7] text-secondary',
                  !!aggregateId && 'cursor-pointer hover:underline',
                  isCommand && 'text-command',
                  isReaction && 'text-reaction',
                  isQuery && 'text-query',
                )}
              >
                {`${aggregate} >`}
              </Typography>
            )}
          </>
        </ConditionalWrapper>
      )}

      <StepHeaderTitle
        className={twMerge(
          'flex w-full items-center justify-center gap-1 pt-2 text-center leading-none',
          isEditable && 'pt-0',
        )}
        isEditable={isEditable}
        isEditMutationError={isEditMutationError}
        isExpanded={isExpanded}
        isFetching={isFetching}
        onEditMutationRetry={retryEditMutation}
        style={{
          color: headerData.titleColor,
        }}
        title={headerData.titleText}
      />

      {type === STATEMENT_PARSING_INFO_TYPE.Reaction && (
        <Handle
          position={Position.Left}
          id={`${props.data.id}r`}
          type="target"
          className={twMerge(
            'border-t-full -left-[1px] h-4 w-2 rounded-r-full border-none bg-event',
            isSelected ? '-left-[2px]' : '',
          )}
          isConnectable={false}
        />
      )}
    </Column>
  )
}
