import { useState } from 'react'
import { PanelGroup } from 'react-resizable-panels'
import type { CodePreviewBaseProps } from '~/models/types/components/codePreview/CodePreviewBaseProps'
import type {
  FileDetails,
  FileStructure,
} from '~/services/GenerationStrategy.types'
import ResizeHandle from '../ResizeHandle'
import { findTreeNodeById } from './CodePreview.utils'
import {
  FileDetailsPanel,
  type FileDetailsPanelProps,
} from './FileDetailsPanel/FileDetailsPanel'
import { FilesTreePanel } from './FilesTreePanel/FilesTreePanel'

type CodePreviewProps = CodePreviewBaseProps &
  Pick<FileDetailsPanelProps, 'placeholderMsg'> & {
    /** Any content to be rendered before the files tree. */
    filesTreePreContent?: JSX.Element
  }

/**
 * This is a code preview component:
 * it renders a panel with the code files tree
 * and another panel to show the code content.
 *
 * For this component, the code edition is disable,
 * in case of need just set as enabled.
 */
const CodePreview = (props: CodePreviewProps) => {
  const { filesTreeData, filesTreePreContent, placeholderMsg } = props

  const [selectedFile, setSelectedFile] = useState<FileDetails>()

  const handleFileClick = (fileId?: string) => {
    const file = findTreeNodeById(filesTreeData, fileId) as FileStructure
    setSelectedFile(file.file)
  }

  return (
    <PanelGroup direction="horizontal">
      <FilesTreePanel
        filesTreeData={filesTreeData}
        onFileClick={handleFileClick}
        preContent={filesTreePreContent}
      />

      <ResizeHandle />

      <FileDetailsPanel file={selectedFile} placeholderMsg={placeholderMsg} />
    </PanelGroup>
  )
}

export default CodePreview
