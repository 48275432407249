import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import { ClipboardText, Copy, Plus } from '@phosphor-icons/react'
import { produce } from 'immer'
import { useCallback, useEffect, useRef, useState, type ReactNode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useActionData, useParams } from 'react-router-dom'
import { Attribute } from '~/components/Attribute'
import { Column } from '~/components/Column'
import { Row } from '~/components/Row'
import { FEATURE_TOGGLE } from '~/config/featureToggle'
import { useGetDomainReadModelTypes } from '~/hooks/api/business/useGetDomainReadModelTypes'
import { useCopyAndPasteAttributes } from '~/hooks/useCopyAndPasteAttributes'
import { AttributesFormValues } from '~/routes/developer/edit-command'
import { renamePropertiesToAttributes } from '~/routes/developer/utils'
import type { Attribute as TAttribute } from '~/services/Development.types'
import { DraggableAttribute } from './DraggableAttribute'
import {
  getAggregateTypesOptions,
  scrollIntoViewWithThreshold,
} from './FormAttributes.utils'

type FormAttributesProps = {
  /** The aggregate ID. */
  aggregateId?: GUID
  autoGenerateComponent?: ReactNode
  formLabel?: string
  formName?: string
  inputLabel?: string
  isDisabled?: boolean
}

export function FormAttributes(props: FormAttributesProps) {
  const {
    aggregateId,
    autoGenerateComponent,
    formLabel = 'Attributes',
    inputLabel = 'Attribute',
    formName,
    isDisabled,
  } = props

  // States.
  const [shouldScroll, setShouldScroll] = useState(false)

  // Ref.
  const lastAttributeRef = useRef<HTMLDivElement>(null)

  // Hooks.
  const dataAutoGeneratedAttributes = useActionData() as {
    formName: string
    attributes: TAttribute[]
  }

  const { control, setValue, getValues } =
    useFormContext<AttributesFormValues>()

  const { pasteAttributesFromClipboard } = useCopyAndPasteAttributes({
    copyingWholeForm: true,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${formName}.attributes` as 'events.0.attributes',
  })

  const params = useParams()
  const { aggregateId: paramAggregateId } = params || {}

  const { data: aggregateTypes } = useGetDomainReadModelTypes({
    aggregateId: aggregateId || paramAggregateId,
  })

  // Methods.
  function handleAddAttribute() {
    append({ name: '', type: 'string' } as TAttribute)

    // Scroll down when appending new attr fields.
    setShouldScroll(true)
  }

  function handleRemoveAttribute(event: React.MouseEvent<HTMLButtonElement>) {
    const index = event.currentTarget.dataset.index
    if (index) {
      remove(Number(index))
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Tab' || event.code === 'Tab') {
      const dataIndex = event.currentTarget.dataset.index
      if (dataIndex) {
        const index = Number(dataIndex)
        if (typeof fields[index + 1] === 'undefined') {
          handleAddAttribute()
        }
      }
    }
  }

  const handlePasteAttributes = async () => {
    const copiedAttributes = await pasteAttributesFromClipboard()
    if (!copiedAttributes) {
      return
    }

    append(
      copiedAttributes.map(
        (attr) =>
          ({
            ...attr,
            type: attr.type?.replace('[]', ''),
            name_is_array: attr.type?.includes('[]'),
          }) as unknown as TAttribute,
      ),
    )
  }

  const moveAttribute = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const currentAttributes = getValues(
        `${formName}.attributes` as 'events.0.attributes',
      ) as TAttribute[]

      const newAttributes = produce(currentAttributes, (draft) => {
        const [draggedAttribute] = draft.splice(dragIndex, 1) as [TAttribute]
        draft.splice(hoverIndex, 0, draggedAttribute)
      })

      /** @ts-ignore: This is causing an error not even fixable with `any`. */
      setValue(`${formName}.attributes`, newAttributes)
    },
    [getValues, setValue, formName],
  )

  // Lifecycle.
  useEffect(() => {
    if (shouldScroll && lastAttributeRef.current) {
      setTimeout(() => {
        scrollIntoViewWithThreshold(lastAttributeRef.current, 100)
        setShouldScroll(false)
      }, 100)
    }
  }, [fields, shouldScroll])

  useEffect(() => {
    if (
      dataAutoGeneratedAttributes &&
      dataAutoGeneratedAttributes?.formName === formName &&
      dataAutoGeneratedAttributes?.attributes.length > 0
    ) {
      setValue(
        `${formName}.attributes` as 'events.0.attributes',
        renamePropertiesToAttributes(dataAutoGeneratedAttributes?.attributes),
      )
    }
  }, [dataAutoGeneratedAttributes, setValue, formName])

  return (
    <DndProvider backend={HTML5Backend}>
      <Column className="gap-4">
        <Typography>{formLabel}</Typography>

        <Row className="items-center gap-2">
          <Button
            color="secondary"
            disabled={isDisabled}
            onClick={handleAddAttribute}
            startIcon={
              isDisabled ? (
                <Plus className="opacity-30" size={18} weight="bold" />
              ) : (
                <Plus size={18} weight="bold" />
              )
            }
            sx={{ width: 'fit-content' }}
            variant="outlined"
          >
            Add
          </Button>

          {FEATURE_TOGGLE.DEVELOPMENT.AUTOGENERATE_ATTRIBUTES &&
          autoGenerateComponent
            ? autoGenerateComponent
            : null}

          <Row>
            <Tooltip title="Copy attributes">
              <IconButton
                name={formName + '.copyButton'}
                type="submit"
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <Copy className="opacity-30" weight="duotone" />
                ) : (
                  <Copy className="text-secondary" weight="duotone" />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title="Paste attributes">
              <IconButton onClick={handlePasteAttributes} disabled={isDisabled}>
                {isDisabled ? (
                  <ClipboardText className="opacity-30" />
                ) : (
                  <ClipboardText className="text-secondary" />
                )}
              </IconButton>
            </Tooltip>
          </Row>
        </Row>

        {fields.map((item, index) => (
          <DraggableAttribute
            key={item.id}
            id={item.id}
            index={index}
            moveAttribute={moveAttribute}
          >
            <div ref={index === fields.length - 1 ? lastAttributeRef : null}>
              <Attribute
                attribute={item}
                formName={`${formName}.attributes` || ''}
                handleKeyDown={handleKeyDown}
                handleRemoveAttribute={handleRemoveAttribute}
                index={index}
                isDisabled={isDisabled}
                inputLabel={inputLabel}
                options={getAggregateTypesOptions(aggregateTypes)}
              />
            </div>
          </DraggableAttribute>
        ))}
      </Column>
    </DndProvider>
  )
}
