import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  DotsThreeVertical,
  PencilSimple,
  Plus,
  Trash,
} from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { twMerge } from '^/tailwind.config'
import { AxiosResponse } from 'axios'
import React from 'react'
import {
  Form,
  Link,
  Outlet,
  useLoaderData,
  useNavigate,
  useNavigation,
  useOutlet,
  useParams,
} from 'react-router-dom'
import { Column } from '~/components/Column'
import { LayoutConfiguration } from '~/components/LayoutConfiguration'
import { Text } from '~/components/Text'
import { queryStrategiesByOrganisationId } from '~/services/GenerationStrategy'
import { ResponseListGenerationStrategies } from '~/services/GenerationStrategy.types'

export function CodeGenerationStrategies() {
  const initialData = useLoaderData() as {
    organisationId: GUID
    strategies: Awaited<
      AxiosResponse<ResponseListGenerationStrategies[] | null, unknown>
    >
  }
  const { data: dataStrategies } = useQuery({
    ...queryStrategiesByOrganisationId(initialData.organisationId),
    initialData: initialData.strategies,
    select: (response) => response.data,
  })
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false)
  const [strategySelected, setStrategySelected] = React.useState<{
    identity?: GUID
    name?: string
  }>()
  const outlet = useOutlet()
  const { organisationId, platformId } = useParams()
  const navigation = useNavigation()
  const navigate = useNavigate()

  function handleSelectStrategyToDelete(event: EventFor<'li', 'onClick'>) {
    const { identity, name } = event.currentTarget.dataset
    setStrategySelected({ identity, name })
    setIsDeleteDialogOpen(true)
  }

  function handleSelectStrategyToEdit(event: EventFor<'li', 'onClick'>) {
    const { identity } = event.currentTarget.dataset
    if (identity) {
      return navigate(
        `/${organisationId}/${platformId}/configuration/code-generation-strategies/edit/${identity}`,
      )
    }

    return
  }

  function handleCloseDeleteDialog() {
    setIsDeleteDialogOpen(false)
  }

  return (
    <>
      {/* `Code Generation Strategies` is rendered as `AI Blueprints` in the UI. */}
      <LayoutConfiguration selected="Code Generation Strategies">
        <Text className="pl-2 text-2xl">AI Blueprints</Text>

        <Column className="mt-2 gap-2 rounded-lg bg-neutral-low-100 p-4 shadow-md">
          <div>
            <Text>Manage the custom AI blueprints.</Text>
          </div>

          <div>
            <Text className="text-xl">Application Blueprints</Text>
          </div>

          <div
            className={twMerge(
              outlet &&
                'rounded-md border border-solid border-highlight-200 bg-white p-4',
              'mt-4 transition-all',
            )}
          >
            <div>
              {outlet ? null : (
                <Button
                  color="secondary"
                  component={Link}
                  startIcon={<Plus size={15} />}
                  to={`/${organisationId}/${platformId}/configuration/code-generation-strategies/add`}
                  variant="contained"
                >
                  Add
                </Button>
              )}
            </div>

            <div>
              <Outlet />
            </div>
          </div>

          <div>
            <TableContainer component={Paper}>
              <Table size="small">
                <caption className="sr-only">
                  Table of application blueprints
                </caption>

                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '25%' }}>Name</TableCell>

                    <TableCell sx={{ width: '25%' }}>Type</TableCell>

                    <TableCell sx={{ width: '50%' }}>Description</TableCell>

                    <TableCell sx={{ width: 'fit-content' }}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dataStrategies?.length === 0 ? (
                    <>
                      <TableRow>
                        <TableCell colSpan={3}>No blueprints yet.</TableCell>
                      </TableRow>

                      {navigation.state === 'submitting' && (
                        <TableRow>
                          <TableCell>
                            <Skeleton width="100%" />
                          </TableCell>

                          <TableCell>
                            <Skeleton width="100%" />
                          </TableCell>

                          <TableCell>
                            <Skeleton width="100%" />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ) : null}

                  {dataStrategies?.map((strategy) => (
                    <React.Fragment key={strategy.identity}>
                      <TableRow
                        key={strategy.identity}
                        data-id={strategy.identity}
                      >
                        <TableCell>{strategy.name}</TableCell>

                        <TableCell>{strategy.type}</TableCell>

                        <TableCell>
                          {strategy.description ? strategy.description : '-'}
                        </TableCell>

                        <TableCell sx={{ textAlign: 'right' }}>
                          <MenuCodeGenerationStrategies
                            handleSelectStrategyToEdit={
                              handleSelectStrategyToEdit
                            }
                            handleSelectStrategyToDelete={
                              handleSelectStrategyToDelete
                            }
                            strategy={strategy}
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Column>

        {strategySelected ? (
          <Dialog
            open={isDeleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
          >
            <DialogTitle id="delete-dialog-title">Delete Blueprint</DialogTitle>

            <DialogContent>
              <div id="delete-dialog-description">
                Do you really want to delete this blueprint?
                <div className="mt-2">
                  <Text className="text-slate-700">
                    <strong>Name:</strong> {strategySelected.name}
                  </Text>
                </div>
              </div>
            </DialogContent>

            <Form
              method="POST"
              action="delete"
              onSubmit={(event) => {
                if (!strategySelected?.identity) {
                  event.preventDefault()
                }
              }}
            >
              <input
                type="hidden"
                value={strategySelected?.identity}
                name="generationStrategyId"
              />

              <input
                type="hidden"
                value={initialData.organisationId}
                name="organisationId"
              />

              <DialogActions>
                <Button
                  color="secondary"
                  onClick={handleCloseDeleteDialog}
                  variant="outlined"
                >
                  No
                </Button>

                <Button
                  autoFocus
                  color="secondary"
                  onClick={handleCloseDeleteDialog}
                  type="submit"
                  variant="contained"
                >
                  Yes
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        ) : null}
      </LayoutConfiguration>
    </>
  )
}

type MenuCodeGenerationStrategiesProps = {
  handleSelectStrategyToEdit: (event: EventFor<'li', 'onClick'>) => void
  handleSelectStrategyToDelete: (event: EventFor<'li', 'onClick'>) => void
  strategy: ResponseListGenerationStrategies
}

function MenuCodeGenerationStrategies({
  handleSelectStrategyToEdit,
  handleSelectStrategyToDelete,
  strategy,
}: MenuCodeGenerationStrategiesProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  function handleOpenMenu(event: EventFor<'button', 'onClick'>) {
    setAnchorEl(event.currentTarget)
  }

  function handleCloseMenu() {
    setAnchorEl(null)
  }

  if (strategy) {
    return (
      <>
        <IconButton aria-label="more" id="more-button" onClick={handleOpenMenu}>
          <DotsThreeVertical weight="bold" />
        </IconButton>

        <Menu
          id="menu"
          MenuListProps={{
            'aria-labelledby': 'more-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          sx={{
            '& .MuiMenu-list': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: 'primary.main',
              },
            },
          }}
        >
          <MenuItem
            onClick={handleSelectStrategyToEdit}
            data-identity={strategy.identity}
          >
            <ListItemIcon>
              <PencilSimple className="text-secondary" size={20} />
            </ListItemIcon>

            <ListItemText>Edit</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={handleSelectStrategyToDelete}
            data-identity={strategy.identity}
            data-name={strategy.name}
          >
            <ListItemIcon>
              <Trash className="text-secondary" size={20} />
            </ListItemIcon>

            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </>
    )
  }
}
