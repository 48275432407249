/**
 * Highlight with `strong` tag any of the `value` parts matching with
 * the provided `filteredValue`.
 * @param value The value to check for matches with the filtered value.
 * @param filteredValue The filtered value to check if it has a match with the `value`.
 */
export const highlightFilteredValue = (
  value: string,
  filteredValue?: string,
) => {
  let renderedName = <>{value}</>

  if (!filteredValue) return renderedName

  // Create a case-insensitive RegExp object from the `filteredValue` string.
  // The 'g' flag makes it match all occurrences, 'i' makes it case-insensitive.
  const filterRegex = new RegExp(`(${filteredValue})`, 'gi')

  // Split the `name` into parts.
  const parts = value.split(filterRegex)

  // Redefined the rendered name.
  if (!!filteredValue.trim() && !!parts.length) {
    renderedName = (
      <>
        {parts.map((part, i) =>
          filterRegex.test(part) ? (
            // If the part matches the regex, wrap it in a <strong> tag.
            <strong key={i}>{part}</strong>
          ) : (
            // If it doesn't match, just wrap it in a regular <span>.
            <span key={i}>{part}</span>
          ),
        )}
      </>
    )
  }

  return renderedName
}
