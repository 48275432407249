import { Box } from '@mui/material'
import { Handle, Position, type Node, type NodeProps } from '@xyflow/react'
import type { NodeStatement } from '~/services/Process.types'
import { nodeWidth } from '../../../utils'
import { DropAreas } from '../DropAreas/DropAreas'
import { SideMenu } from '../SideMenu/SideMenu'
import { step } from '../Step/Step.styles'
import { useStep } from '../Step/useStep'
import { Chip } from '../components/Chip'
import { Helpers } from '../components/Helpers'
import { Header } from './Header'

export type CollapsedStepProps = NodeProps<Node<NodeStatement>>

export function CollapsedStep(props: CollapsedStepProps) {
  const { data, id } = props

  const {
    hasChildren,
    hasError: hasStatementError,
    isEditingMode,
    isEditMutationError,
    isEmpty,
    isFetching,
    isGeneratingProcess,
    isLocal,
    isSelected,
    isSwapModeEnabled,
    onAddLocalChild,
    onAddLocalNextSibling,
    onAddLocalParent,
    onAddLocalPreviousSibling,
    onDropNodeIntoDropAreas,
    parentStatementId,
    type,
  } = data || {}

  // Hooks.
  const {
    dragRef,
    isAnyDragging,
    isCommand,
    isNodeDragging,
    isNodeLayerDragging,
    isReaction,
    isQuery,
  } = useStep({ id, isLocal, type })

  // Constants.
  const hasError = hasStatementError || isEditMutationError

  const shouldShowChip = !isQuery && !isEmpty && !hasError && !isFetching

  const shouldShowSideMenu =
    !isEmpty &&
    isSelected &&
    !isGeneratingProcess &&
    !isNodeDragging &&
    !isNodeLayerDragging

  const statePriority = ['isFetching', 'isEmpty', 'hasError']

  const status =
    statePriority.reduce((acc, state) => {
      if (!acc && data[state as keyof NodeStatement]) return state

      return acc
    }, '') || 'default'

  return (
    <Box
      className={step({
        isCommand,
        isEditingMode,
        isNodeLayerDragging,
        isReaction,
        isSelected,
        isSwapModeEnabled,
        status: status as 'default' | 'hasError' | 'isEmpty',
      })}
      data-statement-id={id}
      ref={dragRef}
      style={{
        minWidth: nodeWidth({ isExpanded: false }),
      }}
    >
      <Helpers
        hasChildren={hasChildren}
        isLocal={isLocal}
        isNodeDragging={isNodeDragging}
        isNodeLayerDragging={isNodeLayerDragging}
        isSelected={isSelected}
        onAddLocalChild={onAddLocalChild}
        onAddLocalNextSibling={onAddLocalNextSibling}
        onAddLocalParent={onAddLocalParent}
        onAddLocalPreviousSibling={onAddLocalPreviousSibling}
        parentStatementId={parentStatementId}
        statementId={id}
      />

      <DropAreas
        id={id}
        isAnyDragging={isAnyDragging}
        isLocal={isLocal}
        isNodeDragging={isNodeDragging}
        isNodeLayerDragging={isNodeLayerDragging}
        onDropNodeIntoDropAreas={onDropNodeIntoDropAreas}
      />

      {shouldShowChip ? <Chip data={props} /> : null}

      <Header data={props} />

      {shouldShowSideMenu ? <SideMenu {...props} /> : null}

      <Handle
        className="invisible left-[0.5px]"
        position={Position.Left}
        type="target"
      />

      <Handle
        className="invisible right-[2px]"
        position={Position.Right}
        type="source"
      />
    </Box>
  )
}
