/**
 * Options to preview files.
 */
export enum PreviewForOptions {
  AGGREGATE = 0,
  COMMAND = 1,
  REACTION = 2,
  TYPE = 3,
  QUERY = 4,
}
