import { debounce } from 'lodash'
import { useCallback, useMemo, useState, type ChangeEvent } from 'react'
import type { FolderOrFileStructure } from '~/services/GenerationStrategy.types'
import { filterDataDirectoryItemsByName } from '../utils/filterDataDirectoryItemsByName'

type UseFilterDataDirectoryItemsProps = {
  /** The file/folder data. */
  fileFolderData: FolderOrFileStructure | FolderOrFileStructure[]
}

/**
 * Provide utilities to filter files or folders
 * by their names.
 */
export const useFilterFileFolderByName = (
  props: UseFilterDataDirectoryItemsProps,
) => {
  const { fileFolderData } = props

  // This state is to allow the controlled state for the field text field.
  const [filterInputValue, setFilterInputValue] = useState<string>('')

  // This is the debounced value, that will actually trigger the filtering.
  const [debouncedFilterInputValue, setDebouncedFilterInputValue] =
    useState<string>('')

  // Debounce for filter input.
  const debouncedSetFilterInputValue = useCallback(
    debounce((value) => {
      setDebouncedFilterInputValue(value)
    }, 300),
    [],
  )

  // Handle filter input change.
  const handleFilterInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setFilterInputValue(value)
    debouncedSetFilterInputValue(value)
  }

  // Clear field value.
  const handleClearFilterInputClick = () => {
    setFilterInputValue('')
    debouncedSetFilterInputValue('')
  }

  // The filtered data directory items.
  const filteredData = useMemo(
    () =>
      filterDataDirectoryItemsByName(fileFolderData, debouncedFilterInputValue),
    [fileFolderData, debouncedFilterInputValue],
  )

  return {
    filteredData,
    filterInputValue,
    handleClearFilterInputClick,
    handleFilterInputChange,
  }
}
