import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react'
import {
  Box,
  Button,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import { SignOut } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import React from 'react'
import { Navigate, Outlet, useLoaderData } from 'react-router-dom'
import ArcPilotLogoDark from '~/assets/icons/arc-pilot-logo-dark.svg?react'
import ArcPilotLogo from '~/assets/icons/arc-pilot-logo.svg?react'
import { useGetUserEmail } from '~/hooks/useGetAccount'
import { queryOrganisation } from '~/services/Discovery'
import { ResponseDiscoveryOrganisation } from '~/services/Discovery.types'
import { useGlobalStore } from '~/store'
import { StackCentered } from '~/styled/StackCentered'
import { WizardStepIcon } from '~/styled/WizardBullet'
import { getUserIdByMsal, getUserName } from '~/utils/func'

type WizardStep = {
  id: number
  label: string
  content?: string | null
  required?: boolean
  helpText?: string
}

export function Root() {
  const { instance, accounts } = useMsal()
  const userName = getUserName(accounts)
  const { email: userEmail } = useGetUserEmail()
  const userId = getUserIdByMsal(accounts)
  const colorMode = useGlobalStore((state) => state.colorMode)

  const initialData = useLoaderData() as {
    organisation: Awaited<
      AxiosResponse<ResponseDiscoveryOrganisation | null, unknown>
    >
  }
  const { data: dataOrg } = useQuery({
    ...queryOrganisation(userId, userEmail),
    initialData: initialData.organisation,
  })

  const wizardSteps: WizardStep[] = React.useMemo(
    () => [
      {
        id: 1,
        label: 'Organisation Name',
        content: dataOrg?.data?.name,
        required: true,
        helpText: 'Enter the name of your organisation',
      },
      {
        id: 2,
        label: 'System Name',
        content: dataOrg?.data?.ecosystemName,
        required: true,
        helpText: 'Enter the name of your product or system',
      },
    ],
    [dataOrg],
  )

  const activeStep = wizardSteps.filter((step) => step.content).length
  const totalSteps = wizardSteps.length

  async function handleLogout() {
    await instance.logoutRedirect()
  }

  return (
    <StackCentered>
      <Paper
        elevation={7}
        sx={{ borderRadius: 4, minWidth: 980, overflow: 'hidden' }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '610px 370px' }}>
          <Box>
            {/* Header Section */}
            <Stack gap={2.75} sx={{ pt: 7, pl: 7, pr: 7 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {colorMode === 'dark' ? <ArcPilotLogoDark /> : <ArcPilotLogo />}
                <AuthenticatedTemplate>
                  <Button
                    color="secondary"
                    onClick={handleLogout}
                    startIcon={<SignOut weight="bold" />}
                    sx={{
                      fontWeight: 400,
                      '.MuiButton-startIcon > *:nth-of-type(1)': {
                        fontSize: '1rem',
                      },
                    }}
                    variant="text"
                  >
                    Logout
                  </Button>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                  <Navigate to="/" />
                </UnauthenticatedTemplate>
              </Stack>

              {/* Welcome Section */}
              <Stack gap={1.5}>
                <Typography
                  className="text-secondary"
                  component="h1"
                  variant="welcomeTitle"
                >
                  <>Welcome, {userName}</>
                </Typography>

                <Typography variant="body1" color="text.secondary">
                  Let&#39;s set up your workspace. Please complete the following
                  steps.
                </Typography>
              </Stack>
            </Stack>

            {/* Main Content Area */}
            <Box sx={{ position: 'relative' }}>
              <Outlet />
            </Box>
          </Box>

          {/* Progress Sidebar */}
          <Box
            sx={{
              bgcolor: 'background.bright',
              pl: 5,
              pr: 2,
              display: 'flex',
              alignItems: 'center',
              minHeight: '100%',
            }}
          >
            <Stepper
              orientation="vertical"
              activeStep={activeStep}
              sx={{
                width: '100%',
                py: 4,
                '.MuiStepLabel-iconContainer': {
                  pr: 3,
                },
                '.MuiStepLabel-label': {
                  fontWeight: 400,
                },
                '.MuiStepConnector-root': {
                  ml: 2,
                },
              }}
            >
              {wizardSteps.map((step) => (
                <Step key={step.id}>
                  <StepLabel StepIconComponent={WizardStepIcon}>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{ display: 'block' }}
                      >
                        {step.label}
                      </Typography>

                      {step.content && (
                        <Box sx={{ mt: 0.5 }}>
                          {step.content.length > 26 ? (
                            <Tooltip title={step.content}>
                              <Typography
                                className="text-feedback-success"
                                sx={{
                                  lineHeight: 1,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '19ch',
                                }}
                                variant="welcomeWizardStepper"
                              >
                                {step.content}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              className="text-feedback-success"
                              sx={{
                                lineHeight: 1,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                maxWidth: '19ch',
                              }}
                              variant="welcomeWizardStepper"
                            >
                              {step.content}
                            </Typography>
                          )}
                        </Box>
                      )}

                      {step.helpText && (
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ display: 'block', mt: 0.5 }}
                        >
                          {step.helpText}
                        </Typography>
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* Progress Indicator */}
            <Typography
              variant="caption"
              sx={{
                position: 'absolute',
                bottom: 16,
                right: 16,
                color: 'text.secondary',
              }}
            >
              Step {activeStep + 1} of {totalSteps}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </StackCentered>
  )
}
