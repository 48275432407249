import type {
  FileStructure,
  FolderOrFileStructure,
  FolderStructure,
} from '~/services/GenerationStrategy.types'
import { nameMatches } from './nameMatches'

/**
 * Helper function to filter a single node.
 * @param node The file/folder node.
 * @param name The name to be use for filter a node.
 * @returns
 */
export const filterNode = (
  node: FolderOrFileStructure,
  name: string,
): FolderOrFileStructure | null => {
  if (!node) return null

  // Check if the current node matches the provided name.
  if (nameMatches(node.name, name)) {
    // If it matches, return the node with its original children.
    return node
  }

  // If the current node doesn't match, check its children.
  if (node.children && node.children.length > 0) {
    const filteredChildren = node.children
      .map((child) => filterNode(child, name))
      .filter(Boolean) as (FolderStructure | FileStructure)[]

    // If any children remain after filtering, return the node with only those children.
    if (filteredChildren.length > 0) {
      return {
        ...node,
        children: filteredChildren,
      }
    }
  }

  // If no match is found in this branch, return null.
  return null
}
