import type { editor } from 'monaco-editor'

export const MONACO_EDITOR_CONSTRUCTION_OPTIONS = {
  theme: 'vs',
  automaticLayout: true,
  minimap: {
    enabled: true,
  },
  fontSize: 14,
  fontFamily: "'JetBrains Mono', monospace",
  scrollBeyondLastLine: false,
  renderLineHighlight: 'all',
  padding: {
    top: 10,
    bottom: 10,
  },
  quickSuggestions: {
    other: true,
    comments: true,
    strings: true,
  },
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: 'matchingDocuments',
  parameterHints: {
    enabled: true,
  },
  codeLens: true,
  lineNumbers: 'on',
  folding: true,
  scrollbar: {
    vertical: 'visible',
    horizontal: 'visible',
  },
} as editor.IStandaloneEditorConstructionOptions
