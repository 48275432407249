export const INCLUDE_EXTENSIONS = [
  // .NET
  '.cs',
  '.csproj',
  '.sln',
  '.config',
  '.props',
  '.targets',

  // Python
  '.py',
  '.pyc',
  '.pyd',
  '.pyo',
  '.pyw',
  '.pyz',

  // Java
  '.java',
  '.class',
  '.jar',
  '.jsp',
  '.properties',

  // JavaScript/Node.js
  '.js',
  '.jsx',
  '.ts',
  '.tsx',
  '.json',
  '.node',

  // C/C++
  '.c',
  '.cpp',
  '.h',
  '.hpp',
  '.cxx',
  '.hxx',

  // PHP
  '.php',
  '.phtml',
  '.php3',
  '.php4',
  '.php5',
  '.phps',

  // Ruby
  '.rb',
  '.erb',
  '.rake',

  // Go
  '.go',

  // Rust
  '.rs',
  '.toml',

  // Perl
  '.pl',
  '.pm',
  '.t',
  '.pod',

  // Swift
  '.swift',

  // Kotlin
  '.kt',
  '.kts',
  '.ktm',

  // Scala
  '.scala',
  '.sc',

  // Terraform
  '.tf',
  '.tfvars',

  // Other file types
  '.sql',
  '.md',
  '.txt',
  '.sh',
  '.bat',
  '.cmd',
  '.ps1',
  '.yml',
  '.yaml',
  '.xml',
  '.css',
  '.scss',
  '.sass',
  '.html',
  '.htm',
  '.xhtml',
  '.mdf',
  '.ldf',
  '.sqlite',
  '.db',
  '.dockerignore',
  '.gitignore',
  '.env',
  '.ini',
  '.lock',
  'LICENSE',

  //COBOL
  '.cbl',
  '.jcl',

  //GRPC
  '.proto',

  //Visual Basic
  '.vb',
]

// Allow image and doc files as include extensions for upload.
export const INCLUDE_EXTENSIONS_WITH_IMAGES_AND_DOCS =
  INCLUDE_EXTENSIONS.concat([
    // Images.
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',

    // Docs.
    '.pdf',
    '.doc',
    '.docx',
  ])

export const EXCLUDE_PATTERNS = [
  'bin/',
  'obj/',
  '.idea/',
  'packages/',
  'node_modules/',
  'build/',
  'dist/',
  'out/',
  'target/',
  '*.log',
  '*.user',
  '*.suo',
  '*.userprefs',
  'tmp*',
  '*.tmp',
  '*.swp',
  '*.cache',
  '*.bak',
  '*.tar',
  '*.gz',
  '*.zip',
  '*.rar',
  '*.7z',
  '*.dll',
  '*.exe',
  '*.pdb',
  '*.mdb',
  '*.class',
  '*.pyc',
  '*.o',
  '*.obj',
  '*.so',
  '*.dylib',
  '*.a',
  '*.lib',
  '*.dSYM',
  '__pycache__/',
  '*.swp',
  '*.swo',
  '*.old',
  '*.bak',
  '*.backup',
  '*.rbc',
  '*.sass-cache',
  '*.scssc',
  '.sass-cache',
  '.npm',
  '.bower',
  '.bundle',
  '.cargo',
  'vendor/',
  'logs/',
  '*.pid',
  '*.seed',
  '*.pid.lock',
  '*.sqlite3',
  '*.sqlite-journal',
  '*.log.lck',
  '*.log.lock',
  '*.log1',
  '*.log2',
  '*.iws',
  '*.orig',
  '*.rej',
  '*.swo',
  '*.swp',
  '*.vi',
  '*.vim',
  '*.db',
  '*.db-shm',
  '*.db-wal',
  '*.sublime-workspace',
  '*.sublime-project',
  '__pycache__/',
]

/**
 * These are file pattens that are going to be ignored
 * since they are temporary and/or hidden folders
 * not visible for some file system.
 */
export const IGNORED_PATTERNS = ['__MACOSX', '._', '.DS_Store']
